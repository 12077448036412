export const placeTypes = [
    { value: 'point', label: 'point', icon: 'radiobox-marked-icon'},
    { value: 'viapoint', label: 'viapoint', icon: 'circle-medium-icon'},
    { value: 'accommodation', label: 'accommodation', icon: 'home-outline-icon'},
    { value: 'hotel', label: 'hotel', icon: 'bed-outline-icon'},
    { value: 'airport', label: 'airport', icon: 'airport-icon'},
    { value: 'car', label: 'car', icon: 'car-hatchback-icon'},
    { value: 'ferry', label: 'ferry', icon: 'anchor-icon'},
    { value: 'train', label: 'train', icon: 'train-icon'},
    { value: 'parking', label: 'parking', icon: 'parking-icon'},
    { value: 'gas_station', label: 'gas_station', icon: 'gas-station-outline-icon'},
    { value: 'viewpoint', label: 'viewpoint', icon: 'binoculars-icon'},
    { value: 'artificial_attraction', label: 'artificial_attraction', icon: 'pillar-icon'},
    { value: 'natural_attraction', label: 'natural_attraction', icon: 'leaf-icon'},
    { value: 'sport', label: 'sport', icon: 'soccer-icon'},
    { value: 'food', label: 'food', icon: 'food-outline-icon'},
    { value: 'stadium', label: 'stadium', icon: 'stadium-icon'},
    { value: 'favorite', label: 'food', icon: 'heart-outline-icon'},
    { value: 'camping', label: 'camping', icon: 'tent-icon'},
    { value: 'city', label: 'city', icon: 'city-variant-outline-icon'},
    { value: 'waterfall', label: 'waterfall', icon: 'waterfall-icon'},
    { value: 'mountain', label: 'mountain', icon: 'image-filter-hdr-icon'},
    { value: 'beach', label: 'beach', icon: 'umbrella-beach-outline-icon'},
    { value: 'kayaking', label: 'kayaking', icon: 'kayaking-icon'},
    { value: 'paw', label: 'paw', icon: 'paw-icon'},
    { value: 'castle', label: 'castle', icon: 'castle-icon'},
    { value: 'lighthouse', label: 'lighthouse', icon: 'lighthouse-icon'},
    { value: 'fish', label: 'fish', icon: 'fish-icon'},
    { value: 'start', label: 'start', icon: 'flag-checkered-icon'},
]

export function getPlaceIcon(place, isChild) {
    if (isChild && (place.type === 'place' || place.type === 'coordinates' || place.type === 'country' || !place.type)) {
        return 'circle-medium-icon'
    }
    if (!isChild && (place.type === 'place' || place.type === 'coordinates' || place.type === 'country' || !place.type)) {
        return 'radiobox-marked-icon'
    }
    const findPlaceIcon = placeTypes.find(item => item.value === place.type)
    if (findPlaceIcon) {
        return findPlaceIcon.icon
    }
    return 'radiobox-marked-icon'
}