<template>
  <map-template>
    <template v-slot:content="{ map, mapPlaces, getUserTrips, tripsList, tripsListFilters, tripsListFilterOptions, handleUpdateFilterQuery, isTripsDataLoaded, addPlaceToList, user }">
      <trips-dashboard
        :mapPlaces="mapPlaces"
        :getUserTrips="getUserTrips"
        :tripsList="tripsList"
        :tripsListFilters="tripsListFilters"
        :tripsListFilterOptions="tripsListFilterOptions"
        :isTripsDataLoaded="isTripsDataLoaded"
        :user="user"
        :handleUpdateFilterQuery="handleUpdateFilterQuery"
      />
    </template>
  </map-template>
</template>

<script>
import MapTemplate from "@/components/MapTemplate";
import TripsDashboard from "@/components/TripsDashboard";

export default {
  name: 'TripsPage',
  components: {
    MapTemplate,
    TripsDashboard
  },
  props: ["map", "mapPlaces", "getUserTrips", "tripsList", "tripsListFilters", "tripsListFilterOptions", "isTripsDataLoaded", "handleUpdateFilterQuery", "addPlaceToList", "user"]
};
</script>
