<template>
  <div class="page-container">
    <div class="welcome-message">Been Journal</div>
    <a class="login-message" href="https://forms.gle/nkp6ufpnHtSA2Toa7" target="_blank">Join BETA testing</a>
  </div>
</template>

<style scoped>
.page-container {
  position: fixed;
  background-color: #ffffff;
  height: calc(100% - 40px);
  width: 100%;
  text-align: center;
}

.welcome-message {
  position: relative;
  top: 100px;
  font-size: 48px;
  color: #333333;
  font-weight: 900;
}
.login-message {
  position: relative;
  top: 120px;
  font-size: 18px;
  color: #333333;
  font-weight: 700;
}
</style>