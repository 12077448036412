<template>
  <div>
    <div aria-label="Trip attributes section" v-if="!appStore.isOwnTrip" >
      <el-page-header @back="$router.push(`/friends`)">
        <template #content>
        </template>
        <template #extra>
          <div style="min-width: 180px; line-height: 30px;">
            <user-info :user="user" size="big"/>
          </div>
        </template>
      </el-page-header>
    </div>
    <el-container v-loading="!isTripsDataLoaded" style="padding: 0">
      <empty-state v-if="tripsList.length == 0 && filterBadgeCounter === 0 && isTripsDataLoaded" :user="user"/>
      <el-container style="padding: 0" v-else>
        <el-header class="trips-dashboard-menu">
          <trips-dashboard-menu
              :setDashboardView="setDashboardView"
              :setTableView="setTableView"
              :tripsListFilters="tripsListFilters"
              :tripsListFilterOptions="tripsListFilterOptions"
              :isFilterRowDisplayed="isFilterRowDisplayed"
              :displayFilterRow="displayFilterRow"
              :filterBadgeCounter="filterBadgeCounter"
              @update-filter-query-event="handleUpdateFilterQuery"
          />
        </el-header>
        <el-main class="trips-dashboard-list-container">
          <!--        <dashboard-cards-view v-if="$store.state.dashboardView.dashboardView" :mapPlaces="mapPlaces" :tripsList="tripsList" :deleteTrip="deleteTrip" :previewTrip="previewTrip"/>-->
          <dashboard-table-view
              :mapPlaces="mapPlaces"
              :tripsList="tripsList"
              :deleteTrip="deleteTrip"
              :previewTrip="previewTrip"
              @update-trips-list-event="updateTripsList"
          />
        </el-main>
      </el-container>
    </el-container>

    <el-dialog
        v-model="deleteTripDialogVisible"
        title="Are you sure you want to delete trip?"
        width="400"
        height="60"
    >
      <template #footer>
        <div>
          <el-button @click="deleteTripDialogVisible = false; this.deleteTripId = null">Cancel</el-button>
          <el-button type="primary" @click="deleteTripConfirm()">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {useAppStore} from "@/stores";
const appStore = useAppStore()
</script>

<script>
import TripsDashboardMenu from "@/components/Dashboard/TripsDashboardMenu.vue";
import DashboardCardsView from "@/components/Dashboard/DashboardCardsView.vue";
import DashboardTableView from "@/components/Dashboard/DashboardTableView.vue";
import EmptyState from "@/components/Dashboard/EmptyState.vue";
import TripsDataService from "@/services/TripsDataService";
import {useAppStore} from "@/stores";
import UserInfo from "@/components/Core/User/UserInfo.vue";

export default {
  props: {
    mapPlaces: {type: [Array, Object]},
    tripsList: {type: [Array, Object]},
    tripsListFilters: {type: [Array, Object]},
    tripsListFilterOptions: {type: [Array, Object]},
    isTripsDataLoaded: {type: Boolean},
    user: Object,
    handleUpdateFilterQuery: Function,
    getUserTrips: Function
  },
  components: {
    TripsDashboardMenu,
    DashboardCardsView,
    DashboardTableView,
    EmptyState,
    UserInfo
  },
  data() {
    return {
      shadowed: false,
      deleteTripDialogVisible: false,
      deleteTripId: null,
      isFilterRowDisplayed: false
    }
  },
  methods: {
    updateTripsList() {
      console.log('update trips list')
      this.getUserTrips()
    },
    displayFilterRow() {
      this.isFilterRowDisplayed = !this.isFilterRowDisplayed
    },
    setDashboardView () {
      this.$store.dispatch('dashboardView/setDashboardView')
    },
    setTableView () {
      this.$store.dispatch('dashboardView/setTableView')
    },
    deleteTrip(trip_id) {
      this.deleteTripDialogVisible = true
      this.deleteTripId = trip_id
    },
    deleteTripConfirm() { // TODO: move to MapTemplate ???
      TripsDataService.delete(this.deleteTripId)
          .then(response => {
            // TODO: handle if not 200 OK
            this.tripsList.splice(0, this.tripsList.length, ...this.tripsList.filter(trip => trip.id !== this.deleteTripId))
            this.tripsList.forEach((trip) => {trip.focused = false; trip.shadowed = false}) // as the trip was clicked to be removed, all other become shadowed
            this.deleteTripDialogVisible = false
            this.deleteTripId = null
          })
          .catch(e => {
            console.log(e);
          });
    },
    previewTrip(trip) {
      if (!useAppStore().isEditMode) {
        if (trip.focused) {
          // make all not shadowed and not focused
          this.tripsList.forEach((trip) => {trip.focused = false; trip.shadowed = false})
        } else {
          // make all unfocused and shadowed
          this.tripsList.forEach((trip) => {trip.focused = false; trip.shadowed = true})
          trip.focused = true
          trip.shadowed = false
        }
        this.focused = !this.focused;
      }
    }
  },
  computed: {
    listContainerHeight() {
      return `calc(100vh - 140px${this.isFilterRowDisplayed ? " - 35px" : ""})`
    },
    filterBadgeCounter() {
      let counter = 0
      Object.keys(this.tripsListFilters).forEach(key => {
        if (this.tripsListFilters[key].length > 0) {
          counter++
        }
      })
      return counter
    }
  },
  watch: {
    'useAppStore().isEditMode': function() {
      this.tripsList.forEach((trip) => {trip.focused = false; trip.shadowed = false})
    }
  },
};
</script>

<style>
.el-dialog__body {
  display: none;
}

.el-header.trips-dashboard-menu {
  --el-header-height: unset;
  --el-header-padding: 0;
}

.el-main.trips-dashboard-list-container {
  padding: 0;
  margin-top: 5px;
}

.trips-dashboard-list-container {
  padding: 0 0 0 20px;
  height: v-bind(listContainerHeight);
}
</style>