import {defineStore} from 'pinia';
import {routeTypes} from "@/map/routeTypes";

export const useAppStore = defineStore({
    id: 'app',
    state: () => ({
        isEditMode: false,
        isOwnTrip: true,
        displayMapRouteTypes: routeTypes.map(route => route.label).filter(route => route !== 'empty'),
        userFilters: localStorage.getItem('userFilters') ? JSON.parse(localStorage.getItem('userFilters')) : {
            own_trips: {tags: [], country: [], mates: []},
            wishlist: {tags: [], country: [], mates: []},
            shared_trips: {tags: [], country: [], mates: []}
        },
    }),
    actions: {
        openEdit() {
            this.isEditMode = true
        },
        closeEdit() {
            this.isEditMode = false
        }
    },
});
