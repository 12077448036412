export function buildPlacesPairs(originalList) {
    const pairList = [];

    for (let i = 0; i < originalList.length - 1; i++) {
        const pair = [originalList[i], originalList[i + 1]];
        pairList.push(pair);
    }
    return pairList
}

export function getMaxDraggableId(draggableList) {
    let maxDraggableId = 0

    if (draggableList.length == 0) {
        return maxDraggableId
    }
    draggableList.forEach(item => {
        if (maxDraggableId < item.draggable_id) {
            maxDraggableId = item.draggable_id
        }
        item.children.forEach(child => {
            if (maxDraggableId < child.draggable_id) {
                maxDraggableId = child.draggable_id
            }
        })
    })
    return maxDraggableId + 1
}

export function findRoute(draggableList, find_origin_draggable_id, find_destination_draggable_id) {
    return draggableList.find(item =>
        item.draggable_type === "route" &&
        item.origin_draggable_id === find_origin_draggable_id &&
        item.destination_draggable_id === find_destination_draggable_id
    );
}

export function findParent(draggableList, childDraggableId) {
    for (const parent of draggableList) {
        if (parent.draggable_id === childDraggableId) {
            return null
        }

        for (const child of parent.children) {
            if (child.draggable_id === childDraggableId) {
                return parent
            }
        }
    }
    return null
}

export function findRouteCandidates(draggableList, find_origin_draggable_id, find_destination_draggable_id) {
    return draggableList.filter(item =>
        item.draggable_type === "route" &&
        (item.origin_draggable_id === find_origin_draggable_id ||
            item.destination_draggable_id === find_destination_draggable_id)
    );
}

export function findItem(draggableList, draggable_id) {
    for (const parent of draggableList) {
        if (parent.draggable_id === draggable_id) {
            return parent
        }

        for (const child of parent.children) {
            if (child.draggable_id === draggable_id) {
                return child
            }
        }
    }
    return null
}

export function findPairIndex(draggablePairsList, origin_draggable_id, destination_draggable_id) {
    return draggablePairsList.findIndex(pairProcessed => pairProcessed[0].draggable_id === origin_draggable_id && pairProcessed[1].draggable_id === destination_draggable_id)
}