<template>
  <el-table
      ref="matesTable"
      empty-text=" "
      :data="mates"
      stripe
      highlight-current-row
      size="small"
      height="560"
  >
    <el-table-column label="" width="70">
      <template #default="scope">
        <user-avatar :user="scope.row" size="big" />
      </template>
    </el-table-column>
    <el-table-column prop="alias" label="Alias">
      <template #default="scope">
        <el-input
            v-model="scope.row.alias"
            class="mate-alias-input"
            @change="onMateRenamed(scope.row)"
        />
      </template>
    </el-table-column>
    <el-table-column label="Name">
      <template #default="scope">
        <el-select
            v-model="scope.row.linked_user_id"
            value-key="id"
            filterable
            placeholder="link user"
            class="mate-select-input"
            :fit-input-width="false"
            :suffix-icon="null"
            @change="onAccountLinkedToMate(scope.row.id, scope.row.linked_user_id)"
        >
          <template #label="{ label, value }">
            <el-link
                @click.prevent="$router.push(`/user/${value}`)"
            >
              @{{ label }}
            </el-link>
          </template>

          <el-option v-for="(item, index) in usersOptions"
                     :key="index"
                     :value="item.id"
                     :label="item.name"
                     :disabled="item.linked_mate_id !== null && item.id !== scope.row.linked_user_id"
          >
            <template #default>
              <div class="mate-dropdown-option">
                <user-info :user="item" size="small"/>
                <div style="padding: 0 5px 0 5px;" v-if="item.linked_mate_id !== null && item.id !== scope.row.linked_user_id">
                  <el-text size="small" type="info">already linked</el-text>
                </div>
              </div>
            </template>
          </el-option>
          <el-option
             :value="-1"
          >
            <template #default>
              <div class="mate-dropdown-option">
                Remove linked account
              </div>
            </template>
          </el-option>
        </el-select>
      </template>
    </el-table-column>
    <el-table-column prop="trips_together" label="Trips together" width="100" align="center" />
<!--    <el-table-column fixed="right" label="" width="65">-->
<!--      <template #default="scope">-->
<!--        <arrow-expand-icon @click.prevent="$router.push(`/user/${scope.row.id}`)" class="table-row-icon" />-->
<!--      </template>-->
<!--    </el-table-column>-->
  </el-table>
</template>


<script>
import UserDataService from "@/services/UserDataService";
import {useAppStore} from "@/stores";
import UserAvatar from "@/components/Core/User/UserAvatar.vue";
import UserInfo from "@/components/Core/User/UserInfo.vue";
import {ElMessage} from "element-plus";

export default {
  name: "MatesList",
  components: {
    UserAvatar,
    UserInfo
  },
  data() {
    return {
      mates: [],
      usersOptions: [],
    }
  },
  methods: {
    onMateRenamed(mate) {
      if (mate.alias === "" && !mate.linked_user_id) {
        // restore prev value
        this.showMessage('warning', 'Not linked mate name cannot be empty')
        this.requestMatesList()
      } else {
        if (mate.alias === "") {
          mate.alias = null
        }
        UserDataService.updateMate(mate).then((response) => {
          this.requestMatesList()
        });
      }

    },
    onAccountLinkedToMate(mateId, linkedToUserId) {
      UserDataService.linkMatesToUsers(mateId, linkedToUserId).then((response) => {
        this.requestMatesList()
        this.requestUsersOptions()
      });
    },
    requestMatesList() {
      UserDataService.getUserTripMates(false).then((response) => {
        this.mates = response.data;
      });
    },
    requestUsersOptions() {
      UserDataService.getUsers().then((response) => {
        this.usersOptions = response.data;
      });
    },
    showMessage(messageType, messageText) {
      ElMessage({
        message: messageText,
        type: messageType,
        showClose: true
      });
    }
  },
  mounted() {
    this.requestMatesList()
    this.requestUsersOptions()
  },
}
</script>

<style>
.mate-alias-input .el-input__wrapper, .mate-alias-input .el-input__wrapper.is-focus, .mate-alias-input .el-input__wrapper:hover {
  box-shadow: none;
  background: transparent;
}

.mate-alias-input, .mate-alias-input .el-input__inner, .mate-alias-input .el-input__inner:hover, .mate-alias-input .el-input__inner:focus {
  cursor: text;
}

.mate-select-input .el-select__wrapper.is-focused, .mate-select-input .el-select__wrapper, .mate-select-input .el-select__wrapper.is-hovering:not(.is-focused) {
  box-shadow: none;
  padding-left: 0;
}

.mate-select-input .el-select__wrapper.is-disabled, .mate-select-input .el-select__wrapper {
  box-shadow: none;
  background:none;
}
</style>