<template>
  <div
      class="user-info"
      :class="[{ 'header-menu': size === 'big'}]"
      @click="navigateToRoute"
  >
    <user-avatar :user="user" :size="size" />
    <div class="user-name">{{ displayedName }}</div>
  </div>
</template>

<script>
import UserAvatar from "@/components/Core/User/UserAvatar.vue";
import router from "@/router/routes";

export default {
  props: {
    user: {type: Object},
    size: {type: String},
    clickable: {type: Boolean, default: false, required: false},
    nameSuffix: {type: String, default: '', required: false},
  },
  components: {
    UserAvatar
  },
  computed: {
    displayedName() {
      if (this.user && this.user !== undefined && (this.user.alias || this.user.name)) {
        return (this.user.alias ? this.user.alias : this.user.name) + this.nameSuffix
      }
      return ""
    },
    navigateToRoute() {
      if (this.clickable && this.user && this.user !== undefined && this.user.linked_user_id) {
        router.push(`/user/${this.user.linked_user_id}`)
      }
    },
    _cursor() {
      if (this.clickable && this.user && this.user !== undefined && this.user.linked_user_id) {
        return "pointer"
      }
      return "default"
    }
  }
};
</script>

<style>
.user-info {
  display: flex;
  flex-direction: row;
  gap: 7px;
  cursor: v-bind(_cursor);
}

.user-info.header-menu {
  cursor: pointer;
  flex-direction: row-reverse;
  gap: 10px;
}

.user-info:focus-visible, .user-menu:focus-visible, .el-dropdown-link:focus-visible {
  outline: none
}

.user-name {
  line-height: 16px;
  vertical-align: center;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%; /* Limit the maximum width */
}

.header-menu .user-name {
  font-size: 14px;
  font-weight: 700;

  height: 32px;
  line-height: 32px;
  vertical-align: center;
}
</style>
