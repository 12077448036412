<template>
  <el-card class="trip-card" shadow="hover"
           v-bind:style="{ 'background-image': 'url(' + trip.image + ')' }">
    <div>
      <div class="el-card-content">
        <div class="trip-card-control">
          <!--    TODO : maybe move to css      -->
          <FullScreen style="width: 20px; height: 20px; margin: 3px 10px 0 3px" @click="$router.push(`/trips/${trip.id}`)" />
          <Delete style="width: 20px; height: 20px; margin: 3px 0 0 3px" @click="$emit('deleteTrip', trip.id)" />
        </div>
        <div class="trip-card-title">{{ trip.title }}</div>
        <div class="trip-card-places">{{ trip.places_preview }}</div>
        <div class="trip-card-dates">{{ DatesRangeTransformer.formatRange(trip.start_date, trip.end_date) }}</div>
      </div>
    </div>
  </el-card>
</template>

<script setup>
import { FullScreen, Delete } from '@element-plus/icons-vue'
</script>

<script>
import DatesRangeTransformer from "@/core/DatesRangeTransformer";

export default {
  emits: ['deleteTrip'],
  props: ['trip'],
  data() {
    return {
      focused: false
    }
  }
}
</script>

<style>
.trip-card {
  width: 380px;
  height: 130px;
  /*border: 1px solid #c4c4c4;*/
  /*box-sizing: border-box;*/
  /*-moz-box-sizing: border-box;*/
  /*-webkit-box-sizing: border-box;*/
  background-size: 380px 130px;
  cursor: pointer;
  margin:0px 10px 10px 0px;
}

.trip-card.disabled {
  opacity: 0.4;
  cursor: unset;
}

.trip-card.shadowed {
  opacity: 0.7;
}

.trip-card.focused {
  opacity: 1;
}

.el-card__body {
  padding: 0;
  height: 100%;
}

.trip-card-control, .trip-card:hover.disabled .trip-card-control{
  display: none;
  position: absolute;
  top: 5px;
  left: 5px;

  height: 25px;

  /*border: 1px solid #c4c4c4;*/
  /*border-radius: 4px;*/
  color: rgba(255, 255, 255, 1);
  /*background-color:rgba(255, 255, 255, 0.3);*/
}

.trip-card:hover .trip-card-control {
  display: flex;
}

.trip-card-title {
  position: absolute;
  top: 80px;
  left: 10px;

  font-weight: 700;
  font-size: 24px;
}
.trip-card-places {
  position: absolute;
  top: 108px;
  left: 10px;

  font-size: 12px;
}
.trip-card-dates{
  position: absolute;
  top: 10px;
  right: 10px;

  font-size: 14px;
}
.el-card__body {
  position: relative;

  color: #FFFFFF;

  font-style: normal;
  font-weight: 400;

  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.1);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>