<template>
  <div style="overflow: visible;" :id="editorUniqueId">
    <quill-editor
        v-model:value="item.description"
        :disabled="!appStore.isOwnTrip"
        :options="editorOption"
        @blur="isEditorFocused = false; updateNow();"
        style="overflow: visible;"
        @change="update"
    />
  </div>
</template>


<script setup>
import Quill from 'quill';
import {useAppStore} from "@/stores";
const appStore = useAppStore()

const SnowTheme = Quill.import('themes/bubble');

class SnowThemeFix extends SnowTheme {
  extendToolbar(toolbar) {
    super.extendToolbar(toolbar);
    this.tooltip.textbox.dataset.link = "http://"; // you can set the placeholder to whatever you want here
  }
}

Quill.register('themes/bubble', SnowThemeFix, true);
</script>

<script>
import {useAppStore} from "@/stores";
import { quillEditor } from 'vue3-quill'

export default {
  props: ['item', 'itemDescriptionUpdateEvent'],
  components: {
    quillEditor
  },
  data() {
    return {
      editorUniqueId: 'quill-editor-' + Math.random().toString(36).substr(2, 9),
      isEditorFocused: false,
      editorOption: null,
      saveTimeout: null,
    }
  },
  methods: {
    update() {
      // save updates with debounce
      if (this.saveTimeout) {
        clearTimeout(this.saveTimeout);
      }
      // Wait for 5 seconds after user stops updating trip data
      this.saveTimeout = setTimeout(() => {
        this.itemDescriptionUpdateEvent(this.item.description)
      }, 3000);
    },
    updateNow() {
      // saves updates immediately and cancels debounce saving
      if (this.saveTimeout) {
        clearTimeout(this.saveTimeout); // Cancel the debounced function
        this.saveTimeout = null; // Optional: Reset the timeout variable
      }
      this.itemDescriptionUpdateEvent(this.item.description); // Execute immediately
    },
    makeLinksClickable() {
      // Add a handler to make links editable when the editor is focused
      document.querySelectorAll('.ql-editor a').forEach(link => {
        link.addEventListener('click', (event) => {
          event.preventDefault();
          console.log('link clicked')
          window.open(link.href, '_blank');
        });
      });
    }
  },
  beforeMount() {
    this.editorOption = {
      bounds: `#${this.editorUniqueId}`,
      placeholder: 'add your notes and memories here',
      theme: 'bubble',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote'],
          [{list: 'ordered'}, {list: 'bullet'}],
          [{indent: '-1'}, {indent: '+1'}],
          ['link'],
          ['clean']
        ]
      },
    }
  },
  mounted() {
    this.makeLinksClickable()
  }
};
</script>

<style>
.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
  border-bottom: 6px solid var(--el-bg-color-overlay);
}

.ql-bubble .ql-tooltip.ql-flip .ql-tooltip-arrow {
  border-top: 6px solid var(--el-bg-color-overlay);
}

.ql-bubble .ql-tooltip {
  border-radius: 6px;
  background: var(--el-bg-color-overlay);
  border: 1px solid var(--el-border-color-light);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}

.ql-bubble .ql-picker-options {
  border-radius: 6px;
  background: var(--el-bg-color-overlay);
  border: 1px solid var(--el-border-color-light);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}

.ql-bubble .ql-toolbar .ql-formats {
  margin: 4px 8px 4px 0;
}

.ql-bubble .ql-tooltip-editor input[type=text] {
  color: var(--el-text-color-regular);
}
.ql-bubble .ql-stroke {
  stroke: var(--el-text-color-regular);
}

.ql-bubble .ql-fill, .ql-bubble .ql-stroke.ql-fill {
  fill: var(--el-text-color-regular);
}

.ql-bubble.ql-toolbar button:hover .ql-stroke, .ql-bubble .ql-toolbar button:hover .ql-stroke, .ql-bubble.ql-toolbar button:focus .ql-stroke, .ql-bubble .ql-toolbar button:focus .ql-stroke, .ql-bubble.ql-toolbar button.ql-active .ql-stroke, .ql-bubble .ql-toolbar button.ql-active .ql-stroke, .ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-bubble.ql-toolbar button:hover .ql-stroke-miter, .ql-bubble .ql-toolbar button:hover .ql-stroke-miter, .ql-bubble.ql-toolbar button:focus .ql-stroke-miter, .ql-bubble .ql-toolbar button:focus .ql-stroke-miter, .ql-bubble.ql-toolbar button.ql-active .ql-stroke-miter, .ql-bubble .ql-toolbar button.ql-active .ql-stroke-miter, .ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--el-color-primary);
}

.ql-bubble.ql-toolbar button:hover .ql-fill, .ql-bubble .ql-toolbar button:hover .ql-fill, .ql-bubble.ql-toolbar button:focus .ql-fill, .ql-bubble .ql-toolbar button:focus .ql-fill, .ql-bubble.ql-toolbar button.ql-active .ql-fill, .ql-bubble .ql-toolbar button.ql-active .ql-fill, .ql-bubble.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-bubble .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-bubble.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-bubble .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-bubble.ql-toolbar button:hover .ql-fill-miter, .ql-bubble .ql-toolbar button:hover .ql-fill-miter, .ql-bubble.ql-toolbar button:focus .ql-fill-miter, .ql-bubble .ql-toolbar button:focus .ql-fill-miter, .ql-bubble.ql-toolbar button.ql-active .ql-fill-miter, .ql-bubble .ql-toolbar button.ql-active .ql-fill-miter, .ql-bubble.ql-toolbar .ql-picker-label:hover .ql-fill-miter, .ql-bubble .ql-toolbar .ql-picker-label:hover .ql-fill-miter, .ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-fill-miter, .ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-fill-miter, .ql-bubble.ql-toolbar .ql-picker-item:hover .ql-fill-miter, .ql-bubble .ql-toolbar .ql-picker-item:hover .ql-fill-miter, .ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-fill-miter, .ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-fill-miter {
  fill: var(--el-color-primary);
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-label {
  color: var(--el-color-primary)
}

.ql-container.ql-bubble:not(.ql-disabled) a::before, .ql-container.ql-bubble:not(.ql-disabled) a::after {
  display: none;
}

.ql-editor {
  color: var(--el-text-color-regular);
}
.ql-editor a {
  cursor: pointer;
  color: var(--el-color-primary);
}

.input-item-description .el-textarea__inner {
  padding: 5px;
}

.input-item-description .el-textarea__inner,
.input-item-description .el-textarea__inner.is-hovering,
.input-item-description .el-textarea__inner.is-hovering:not(.is-focused),
.input-item-description .el-textarea__inner.is-disabled,
.input-item-description.el-textarea.is-disabled .el-textarea__inner,
.input-item-description .el-textarea__inner:focus,
.input-item-description .el-textarea__inner:hover
{
  box-shadow: none;
  background:none;
  cursor: unset;
  color: var(--el-input-text-color);
}

</style>
