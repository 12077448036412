<template>
  <map-template>
    <template v-slot:content="{ getUserTrips }">
      <users-list-table-view :getUserTrips="getUserTrips"/>
    </template>
  </map-template>
</template>

<script>
import UsersListTableView from "@/components/UsersList/UsersListTableView.vue";
import MapTemplate from "@/components/MapTemplate.vue";

export default {
  name: 'FriendsPage',
  components: {
    MapTemplate,
    UsersListTableView
  },
  props: ["getUserTrips"]
};
</script>
