<template>
    <draggable
        class="list-group"
        :list="draggableList"
        group="people"
        item-key="draggable_id"
        v-bind="dragOptions"
        @start="drag = true"
        :component-data="{
            tag: 'div',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null
          }"
        filter=".ignore-elements"
        handle=".draggable-handle"
        swapThreshold="0.65"
        @end="listOrderUpdateHandler"
    >
      <template #item="{ element }">
        <div :class="[{ 'ignore-elements': element.draggable_type === 'route' || !appStore.isOwnTrip}]">
          <place-list-item
              v-if="element.draggable_type === 'place'"
              :isChild="false"
              :place="element"
              :isDragOver="null"
              :hovered="hoveredPlace != null && hoveredPlace.includes(element.draggable_id)"
              :draftTripsList="draftTripsList"
              @place-removed-event="placeRemovedHandler"
              @item-renamed-event="placeRenamedHandler"
              @item-description-updated-event="itemDescriptionUpdateEvent"
              @item-images-updated-event="itemImagesUpdatedHandler"
              @place-type-updated-event="placeTypeUpdateHandler"
              @list-item-media-uploading-state-event="handleUploadingState"
              @mouseover="listItemHoveredHandler(element, true)"
              @mouseleave="listItemHoveredHandler(element, false)"
          />
        </div>
      </template>
    </draggable>
</template>

<script setup>
import {useAppStore} from "@/stores";
const appStore = useAppStore()
</script>

<script>
import draggable from "vuedraggable";
import PlaceListItem from "@/components/List/PlaceListItem.vue";

export default {
  name: "DraggableList",
  components: {
    draggable,
    PlaceListItem,
  },
  props: ['draggableList', 'hoveredRoute', 'hoveredPlace', 'hoveredChild', 'draftTripsList'],
  data() {
    return {
      drag: false
    };
  },
  methods: {
    placeRemovedHandler(place) {
      this.$emit('place-removed-event', place);
    },
    placeRenamedHandler(newLabel, placeDraggableId) {
      this.$emit('item-renamed-event', newLabel, placeDraggableId);
    },
    itemDescriptionUpdateEvent(newDesc, placeDraggableId) {
      this.$emit('item-description-updated-event', newDesc, placeDraggableId);
    },
    itemImagesUpdatedHandler(placeImages, placeDraggableId) {
      this.$emit('item-images-updated-event', placeImages, placeDraggableId);
    },
    placeTypeUpdateHandler(place) {
      this.$emit('place-type-updated-event', place);
    },
    listOrderUpdateHandler() {
      this.drag = false
      this.$emit('list-order-updated-event', this.draggableList);
    },
    listItemHoveredHandler(item, hovered) {
      this.$emit('list-item-hovered-event', item, hovered);
    },
    handleUploadingState(isInProgress) {
      this.$emit('list-item-media-uploading-state-event', isInProgress);
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
};
</script>

<style>

.list-group {
  min-height: 28px;
  margin: 10px 15px 0;
}
</style>