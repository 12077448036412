<template>
  <div class="list-item-type-select-container">
    <el-select
        :style="{ 'margin-left': isChild ? '5px' : '30px' }"
        v-model="item.type"
        :reserve-keyword="false"
        suffix-icon=none
        default-first-option
        class="item-select"
        :class="[{ 'route': item.draggable_type === 'route' }]"
        placement="left"
        size="small"
        :disabled="item.type === 'country' || !appStore.isOwnTrip"
        @change="itemTypeChangedEvent(item)"
    >
      <template #default >
        <div :class="[{ 'dropdown-container': item.draggable_type === 'place' }]">
          <el-option
              :class="[{ 'place-dropdown-item': item.draggable_type === 'place' }]"
              v-for="el in types"
              :key="el.value"
              :value="el.value"
          >
            <component :is="el.icon"></component>
            <span v-if="item.draggable_type === 'route'" style="padding-left: 15px; font-size: var(--el-font-size-small)">{{ el.value }}</span>
          </el-option>
        </div>
      </template>
      <template #prefix >
        <component :is="getIconMethod(item, isChild)" class="icon" :class="[{ 'cropped': getIconMethod(item, isChild) === 'circle-medium-icon'}]"></component>
      </template>
    </el-select>
  </div>
</template>


<script setup>
import {useAppStore} from "@/stores";
const appStore = useAppStore()
</script>

<script>
import {useAppStore} from "@/stores";
import {routeTypes, getRouteIcon} from "@/map/routeTypes";
import {placeTypes, getPlaceIcon} from "@/map/placeTypes";

export default {
  props: ['item', 'isChild', 'itemTypeChangedEvent'],
  data() {
    return {
      types: this.item.draggable_type === 'route' ? routeTypes : placeTypes.filter(placeType => placeType.value !== (this.isChild ? 'point' : 'viapoint')),
      getIconMethod: this.item.draggable_type === 'route' ? getRouteIcon : getPlaceIcon
    }
  }
};
</script>

<style>
.item-select {
  z-index: 5;
}
.list-item-type-select-container .el-select {
  padding: 0;
  display: block;
  width: 24px;
}

.list-item-type-select-container .item-select .el-select__wrapper,
.list-item-type-select-container .item-select .el-select__wrapper.is-hovering,
.list-item-type-select-container .item-select .el-select__wrapper.is-hovering:not(.is-focused),
.list-item-type-select-container .item-select .el-select__wrapper.is-disabled,
.list-item-type-select-container .item-select .el-select__wrapper.is-focused
{
  box-shadow: none;
  background:none;
}

.list-item-type-select-container .item-select .el-select__wrapper.is-disabled,
.list-item-type-select-container .item-select .el-select__wrapper.is-disabled .el-select__caret,
.list-item-type-select-container .item-select .el-select__wrapper.is-disabled .el-tag {
  cursor: default;
}

.list-item-type-select-container .item-select .icon {
  color: var(--el-color-primary);
  left: -5px;
  top: 2px;
}
.list-item-type-select-container .item-select.route .icon {
  color: var(--el-text-color-secondary);
}

.list-item-type-select-container .el-select__selection, .list-item-type-select-container .el-select__suffix {
  display: none;
}

.list-item-type-select-container .item-select .icon.material-design-icon .material-design-icon__svg {
  width: 18px;
  height: 18px;
  background-color: #FFFFFF;
  border-radius: 9px;
  z-index: 3;
}

.list-item-type-select-container .item-select.route .icon.material-design-icon .material-design-icon__svg {
  background: none;
}

.list-item-type-select-container .item-select .icon.cropped.material-design-icon .material-design-icon__svg {
  border-radius: 9px;
  width: 22px;
  height: 22px;
  background: none;
  left: -2px;
  top:-7px;
}

.dropdown-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px; /* Adjust the gap as needed */
}

.place-dropdown-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  line-height: 26px;
}

.place-dropdown-item.el-select-dropdown__item {
  padding: 0 10px 0 10px;
  height: 30px;
}
</style>
