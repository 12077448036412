import {circlesLayer, countriesLayer, popUpOptionsLayer, routesLayer, viaPointsLayer} from "@/map/layers";

export function hoverMapFeature(mapInstance, layer, prevHoveredFeature, newHoveredFeature, collection, hover=true) {
    // reset previous hover route state
    if (prevHoveredFeature) {
        prevHoveredFeature.forEach((hoveredId) => {
            mapInstance.removeFeatureState(
                { source: layer, id: collection.findIndex(item => hoveredId === item.draggable_id) }
            );
        })
        prevHoveredFeature.splice(0);
    }

    if (hover) {
        if (newHoveredFeature.hasOwnProperty('properties')) {
            // if hovered at map
            prevHoveredFeature.splice(0, prevHoveredFeature.length, ...[collection[newHoveredFeature.id].draggable_id])
        } else {
            // if hovered at list => find route feature
            // find by attribute values as deep comparison is not supported for [[]] objects
            if (layer === routesLayer.id) {
                prevHoveredFeature.splice(0, prevHoveredFeature.length, ...[newHoveredFeature.draggable_id])
            } else if (layer === popUpOptionsLayer.id) {
                prevHoveredFeature.splice(0, prevHoveredFeature.length, ...[newHoveredFeature.draggable_id])
            } else if (layer === circlesLayer.id) {
                prevHoveredFeature.splice(0, prevHoveredFeature.length, ...[newHoveredFeature.draggable_id])
            } else if (layer === viaPointsLayer.id) {
                prevHoveredFeature.splice(0, prevHoveredFeature.length, ...[newHoveredFeature.draggable_id])
            }
        }

        // highlight hovered features
        prevHoveredFeature.forEach((hoveredId) => {
            mapInstance.setFeatureState(
                { source: layer, id: collection.findIndex(item => hoveredId === item.draggable_id) },
                { hover: true }
            );
        })
    }
}

export function hoverMapCountry(mapInstance, layer, prevHoveredFeature, newHoveredFeature, collection, hover=true) {
    // reset previous hover route state
    prevHoveredFeature.forEach((hoveredAlpha2) => {
        let feature = mapInstance.querySourceFeatures(layer, {sourceLayer: layer}).find(feature => feature.properties.iso_3166_1 === hoveredAlpha2.toUpperCase())
        mapInstance.removeFeatureState(
            {source: layer, sourceLayer: layer, id: feature.id}
        );
    })
    prevHoveredFeature.splice(0);

    if (hover) {
        if (newHoveredFeature.hasOwnProperty('properties')) {
            prevHoveredFeature.splice(0, prevHoveredFeature.length, ...[newHoveredFeature.properties.iso_3166_1.toLowerCase()])
        } else {
            console.log(newHoveredFeature.alpha_2)
            prevHoveredFeature.splice(0, prevHoveredFeature.length, ...[newHoveredFeature.alpha_2])
        }

        // highlight hovered features
        prevHoveredFeature.forEach((hoveredAlpha2) => {
            let feature = mapInstance.querySourceFeatures(layer, {sourceLayer: layer}).find(feature => feature.properties.iso_3166_1 === hoveredAlpha2.toUpperCase())
            mapInstance.setFeatureState(
                {source: layer, sourceLayer: layer, id: feature.id},
                {hover: true}
            );
        })
    }
}