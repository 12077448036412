import http from "../http-common";

class AuthService {
    login(token) {
        return http.post("/auth/login", null, {
            params: {'credential': token},
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }

    logout() {
        return http.get("/auth/logout");
    }

    refresh() {
        return http.post("/auth/refresh-token");
    }
}

export default new AuthService();