<template>
  <draggable
      class="list-group"
      :list="draggableList"
      group="people"
      item-key="draggable_id"
      v-bind="dragOptions"
      @start="drag = true"
      :component-data="{
            tag: 'div',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null
          }"
      filter=".ignore-elements"
      handle=".draggable-handle"
      swapThreshold="0.65"
      @end="listOrderUpdateHandler"
      ghostClass="sortable-ghost"
      chosenClass="sortable-chosen"
      dragClass="sortable-drag"
      :move="checkMove"
  >
    <template #item="{ element }">
      <div :class="[{ 'ignore-elements': !appStore.isOwnTrip}]">
        <place-list-item
            v-if="element.draggable_type === 'place'"
            :isChild="false"
            :place="element"
            :tripDatesInterval="tripDatesInterval"
            :isDragOver="isDragOver"
            :isLastItem="draggableList.findIndex(listItem => listItem.draggable_id === element.draggable_id) === (draggableList.length - 1)"
            :isFirstItem="draggableList.findIndex(listItem => listItem.draggable_id === element.draggable_id) === 0"
            :hovered="hoveredPlace != null && hoveredPlace.includes(element.draggable_id)"
            @place-removed-event="placeRemovedHandler"
            @place-duplicate-event="placeDuplicateHandler"
            @item-renamed-event="itemRenamedHandler"
            @item-date-updated-event="itemDateUpdateHandler"
            @item-added-to-wishlist="itemAddedToWishlistHandler"
            @item-images-updated-event="itemImagesUpdatedHandler"
            @place-type-updated-event="placeTypeUpdateHandler"
            @list-item-media-uploading-state-event="handleUploadingState"
            @mouseover="listItemHoveredHandler(element, true)"
            @mouseleave="listItemHoveredHandler(element, false)"
        >
          <template v-slot:children="{}">
            <draggable
                :list="element.children"
                group="people"
                item-key="draggable_id"
                v-bind="dragOptions"
                @start="drag = true"
                @end="listOrderUpdateHandler"
                :component-data="{
                    tag: 'div',
                    type: 'transition-group',
                    name: !drag ? 'flip-list' : null
                  }"
                handle=".draggable-handle"
                filter=".ignore-elements"
                ghostClass="sortable-ghost"
                chosenClass="sortable-chosen"
                dragClass="sortable-drag"
                :move="checkMove"
            >
              <template #item="{ element }">
                <place-list-item
                    :class="[{ 'ignore-elements': !appStore.isOwnTrip}]"
                    :place="element"
                    :isChild="true"
                    :isDragOver="isDragOver"
                    @place-removed-event="placeRemovedHandler"
                    @place-duplicate-event="placeDuplicateHandler"
                    @item-renamed-event="itemRenamedHandler"
                    @item-images-updated-event="itemImagesUpdatedHandler"
                    @item-added-to-wishlist="itemAddedToWishlistHandler"
                    @place-type-updated-event="placeTypeUpdateHandler"
                    @list-item-media-uploading-state-event="handleUploadingState"
                    :hovered="hoveredChild != null && hoveredChild.includes(element.draggable_id)"
                    @mouseover="listItemHoveredHandler(element, true)"
                    @mouseleave="listItemHoveredHandler(element, false)"
                />
              </template>
            </draggable>
          </template>
        </place-list-item>
        <route-list-item
            v-if="element.draggable_type === 'route'"
            :route="element"
            :isDragOver="isDragOver"
            :hovered="hoveredRoute != null && hoveredRoute.includes(element.draggable_id)"
            @route-type-updated-event="routeTypeUpdateHandler"
            @item-images-updated-event="itemImagesUpdatedHandler"
            @item-renamed-event="itemRenamedHandler"
            @list-item-media-uploading-state-event="handleUploadingState"
            @mouseover="listItemHoveredHandler(element, true)"
            @mouseleave="listItemHoveredHandler(element, false)"
        >
          <template v-slot:children="{}">
            <draggable
                :list="element.children"
                group="people"
                item-key="draggable_id"
                v-bind="dragOptions"
                @start="drag = true"
                @end="listOrderUpdateHandler"
                :component-data="{
                    tag: 'div',
                    type: 'transition-group',
                    name: !drag ? 'flip-list' : null
                  }"
                filter=".ignore-elements"
                ghostClass="sortable-ghost"
                chosenClass="sortable-chosen"
                dragClass="sortable-drag"
                :move="checkMove"
            >
              <template #item="{ element }">
                <place-list-item
                    :class="[{ 'ignore-elements': !appStore.isOwnTrip}]"
                    :place="element"
                    :isChild="true"
                    :isDragOver="isDragOver"
                    @place-removed-event="placeRemovedHandler"
                    @place-duplicate-event="placeDuplicateHandler"
                    @item-renamed-event="itemRenamedHandler"
                    @item-images-updated-event="itemImagesUpdatedHandler"
                    @item-added-to-wishlist="itemAddedToWishlistHandler"
                    @place-type-updated-event="placeTypeUpdateHandler"
                    @list-item-media-uploading-state-event="handleUploadingState"
                    :hovered="hoveredChild != null && hoveredChild.includes(element.draggable_id)"
                    @mouseover="listItemHoveredHandler(element, true)"
                    @mouseleave="listItemHoveredHandler(element, false)"
                />
              </template>
            </draggable>
          </template>
        </route-list-item>
      </div>
    </template>
  </draggable>
</template>

<script setup>
import {useAppStore} from "@/stores";
const appStore = useAppStore()
</script>

<script>
import draggable from "vuedraggable";
import PlaceListItem from "@/components/List/PlaceListItem.vue";
import RouteListItem from "@/components/List/RouteListItem.vue";

export default {
  name: "DraggableList",
  components: {
    draggable,
    PlaceListItem,
    RouteListItem,
  },
  props: ['draggableList', 'hoveredRoute', 'hoveredPlace', 'hoveredChild', 'tripDatesInterval'],
  data() {
    return {
      drag: false,
      isDragging: null,
      isDragOver: null
    };
  },
  methods: {
    checkMove(evt) {
      if (evt.relatedContext.hasOwnProperty('element')) {
        if (evt.relatedContext.element.draggable_parent_id === null) {
          this.isDragOver = evt.relatedContext.element.draggable_id
        }
        console.log("dragging: ", evt.draggedContext.element.draggable_id, "over: ", evt.relatedContext.element.draggable_id, " future index: ", evt.draggedContext.futureIndex, "current index:", evt.draggedContext.index)
      }
      // console.log(evt)
    },
    handleUploadingState(isInProgress) {
      this.$emit('list-item-media-uploading-state-event', isInProgress);
    },
    placeRemovedHandler(place) {
      this.$emit('place-removed-event', place);
    },
    placeDuplicateHandler(place) {
      this.$emit('place-duplicate-event', place);
    },
    itemDateUpdateHandler(itemDraggableId, newDate) {
      this.$emit('item-date-updated-event', itemDraggableId, newDate);
    },
    itemRenamedHandler(newLabel, itemDraggableId) {
      this.$emit('item-renamed-event', newLabel, itemDraggableId);
    },
    itemImagesUpdatedHandler(placeImages, placeDraggableId) {
      console.log('emited item-images-updated-event')
      this.$emit('item-images-updated-event', placeImages, placeDraggableId);
    },
    itemAddedToWishlistHandler(item) {
      console.log("item added to wishlist")
      this.$emit('item-added-to-wishlist', item);
    },
    placeTypeUpdateHandler(place, isChild) {
      this.$emit('place-type-updated-event', place, isChild);
    },
    routeTypeUpdateHandler(route) {
      this.$emit('route-type-updated-event', route);
    },
    listOrderUpdateHandler() {
      this.drag = false
      this.isDragOver = null
      this.$emit('list-order-updated-event', this.draggableList);
    },
    listItemHoveredHandler(item, hovered) {
      this.$emit('list-item-hovered-event', item, hovered);
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  }
};
</script>

<style>
:root {
  --active-list-item-bg-color: #e3f9ff;
  --text-color: darkblue;
}

.list-group {
  min-height: 28px;
  margin: 10px 15px 0;
}

/* .sortable-ghost - the class of ghost element before the drag finished */
/*TODO: draggable handle color on selected and in ghost class*/
.sortable-ghost .list-item .list-item-container {
  background-color: var(--active-list-item-bg-color);
}
.sortable-ghost .list-item .draggable-handle {
  display: inline-block;
}

.sortable-chosen.list-item .list-item-container {
  background-color: var(--active-list-item-bg-color);
  /*border: 2px dashed var(--el-color-primary);*/
  /*adjust timeline palce line*/
}
.sortable-chosen.list-item .draggable-handle {
  display: inline-block;
}
.sortable-chosen.list-item .list-item-context-menu {
  background-color: var(--active-list-item-bg-color);
}

.sortable-chosen.list-item .list-item-container .item-children-container {
  display: none;
}

.sortable-drag .list-item .list-item-container {
  background-color: var(--active-list-item-bg-color);
}

.sortable-drag .list-item .list-item-container .timeline-place::before {
  display: none;
}

</style>