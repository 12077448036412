<template>
  <map-template>
    <template v-slot:content="{ map, addPlaceToList, handlePlaceRemoved, handleItemImagesUpdated, handleItemRenamed, tripItemsList, isTripsDataLoaded, hoveredRoute, hoveredPlace, hoveredChild, hoveredCountry, handleRouteTypeUpdate, handleListOrderUpdate, handleListItemHovered, updateUserWishlist, tripsListFilters, tripsListFilterOptions, handleUpdateFilterQuery}">
      <wishlist
          :map="map"
          :addPlaceToList="addPlaceToList"
          :handlePlaceRemoved="handlePlaceRemoved"
          :handleItemImagesUpdated="handleItemImagesUpdated"
          :handleItemRenamed="handleItemRenamed"
          :tripItemsList="tripItemsList"
          :isTripsDataLoaded="isTripsDataLoaded"
          :hoveredRoute="hoveredRoute"
          :hoveredPlace="hoveredPlace"
          :hoveredCountry="hoveredCountry"
          :hoveredChild="hoveredChild"
          :handleRouteTypeUpdate="handleRouteTypeUpdate"
          :handleListOrderUpdate="handleListOrderUpdate"
          :handleListItemHovered="handleListItemHovered"
          :updateUserWishlist="updateUserWishlist"
          :tripsListFilters="tripsListFilters"
          :tripsListFilterOptions="tripsListFilterOptions"
          :handleUpdateFilterQuery="handleUpdateFilterQuery"
      />
    </template>
  </map-template>
</template>

<script>
import MapTemplate from "@/components/MapTemplate";
import Wishlist from "@/components/Wishlist/Wishlist.vue";

export default {
  name: 'TripsPage',
  components: {
    MapTemplate,
    Wishlist
  },
  props: ["map", "addPlaceToList", "tripItemsList", "isTripsDataLoaded", "handlePlaceRemoved", "handleItemImagesUpdated", "handleItemRenamed", "hoveredRoute", "hoveredPlace", "hoveredChild", "hoveredCountry", "handleRouteTypeUpdate", "handleListOrderUpdate", "handleListItemHovered", "updateUserWishlist", "tripsListFilters", "tripsListFilterOptions", "handleUpdateFilterQuery"]
};
</script>
