<template>
  <div class="info-container">
    <span class="info-message">Page not found.</span>
    <span class="info-message">We're sorry, we couldn't find the page you requested</span>
    <el-button class="info-button" type="primary" round @click="$router.push('/trips')">Back to trips list</el-button>
  </div>
</template>

<style>
.info-container {
  width: 100%;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.info-message {
  color: #999999;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 20px;
  display: inline-block;
}
</style>