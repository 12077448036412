<template>
  <div style="padding-left: 20px;">
    <div aria-label="Trip attributes section">
      <el-page-header @back="$router.push(`/`)">
        <template #content>
          Wishlist
        </template>
        <template #extra>
          <last-saved-indicator v-loading="isSavingInProgress" :lastSavedDate="lastSavedDate" class="saved-indicator"/>
        </template>
      </el-page-header>
    </div>

    <div v-loading="!isTripsDataLoaded" style="padding: 10px 0px 0px 100px;" >
      <div class="wishlist-header">
        <div class="trip-attributes-row" v-if="appStore.isOwnTrip">
          <div class="trip-attributes-label">
            <map-marker-plus-outline-icon class="icon"/>
            Locations
          </div>
          <geocoder
              :map="map"
              :addPlaceToList="addPlaceToList"
          />
        </div>
        <div class="wishlist-filter">
          <el-badge is-dot :hidden="filterBadgeCounter === 0" class="filter-badge" :show-zero="false" type="primary" :offset="[1, 2]">
            <filter-variant-icon
                @click="displayFilterRow"
                class="menu-controls-icon"
                :class="[{'active': filterBadgeCounter > 0}]"
            />
          </el-badge>
        </div>
      </div>
      <el-scrollbar>
        <div class="trip-menu-filters-row" v-if="isFilterRowDisplayed">
          <filter-select
              v-if="tripsListFilterOptions.tags.length > 0"
              filterBy="tags"
              :selectedList="tripsListFilters.tags"
              :optionsList="tripsListFilterOptions.tags"
              :handleSelectionUpdate="handleFilterUpdate"
          >
            <template v-slot:dropdown-option="{ item }">
              <div>{{ item }}</div>
            </template>
            <template v-slot:selected-option="{ item }">
              <div>{{ item }}</div>
            </template>
          </filter-select>

          <filter-select
              v-if="tripsListFilterOptions.country.length > 0"
              filterBy="country"
              :selectedList="tripsListFilters.country"
              :optionsList="tripsListFilterOptions.country"
              :handleSelectionUpdate="handleFilterUpdate"
          >
            <template v-slot:dropdown-option="{ item }">
              <div>{{ item.label }}</div>
            </template>
            <template v-slot:selected-option="{ item }">
              <div>{{ item.label }}</div>
            </template>
          </filter-select>

          <filter-select
              v-if="tripsListFilterOptions.mates.length > 0"
              filterBy="mates"
              :selectedList="tripsListFilters.mates"
              :optionsList="tripsListFilterOptions.mates"
              :handleSelectionUpdate="handleFilterUpdate"
          >
            <template v-slot:dropdown-option="{ item }">
              <div>{{ item.name ? item.name : item.alias }}</div>
            </template>
            <template v-slot:selected-option="{ item }">
              <user-info :user="item" size="small"/>
            </template>
          </filter-select>
        </div>
      </el-scrollbar>
      <el-divider style="margin: 5px 0;"/>
      <div class="wishlist-content-container" style="height: unset;">
        <el-scrollbar height="500px">
          <draggable-wishlist
              :draggableList="filteredItemsList"
              :hoveredRoute="hoveredRoute"
              :hoveredPlace="hoveredPlace"
              :hoveredChild="hoveredChild"
              :draftTripsList="draftTripsList"
              @place-type-updated-event="handleWishlistItemTypeUpdated"
              @place-removed-event="handleWishlistItemRemoved"
              @item-renamed-event="handleWishlistItemRenamed"
              @item-description-updated-event="handleWishlistItemDescriptionUpdated"
              @item-images-updated-event="handleWishlistImageUpdated"
              @list-order-updated-event="handleWishlistOrderUpdated"
              @list-item-hovered-event="handleListItemHovered"
              @list-item-media-uploading-state-event="handleUploadingState"
          />
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script setup>
import 'vue-material-design-icons/styles.css';
import {useAppStore} from "@/stores";
const appStore = useAppStore()
</script>

<script>
import Geocoder from "@/components/Geocoder";
import {useAppStore} from "@/stores";
import DraggableWishlist from "@/components/Wishlist/DraggableWishlist.vue";
import TripsDataService from "@/services/TripsDataService";
import FilterSelect from "@/components/Core/Filter/FilterSelect.vue";
import UserInfo from "@/components/Core/User/UserInfo.vue";
import {findItem} from "@/core/ListHelper";
import LastSavedIndicator from "@/components/Core/Form/LastSavedIndicator.vue";

export default {
  name: "Sample",
  components: {
    Geocoder,
    DraggableWishlist,
    FilterSelect,
    UserInfo,
    LastSavedIndicator
  },
  props: ['map', 'tripItemsList', 'addPlaceToList', 'handlePlaceRemoved', 'handleItemRenamed', 'handleItemImagesUpdated', 'isTripsDataLoaded', 'hoveredRoute', 'hoveredPlace', 'hoveredChild', 'hoveredCountry', 'handleRouteTypeUpdate', 'handleListOrderUpdate', 'handleListItemHovered', 'updateUserWishlist', 'tripsListFilters', 'tripsListFilterOptions', 'handleUpdateFilterQuery'],
  data() {
    return {
      saveTimeout: null,
      lastSaved: null,
      filteredItemsList: [],
      draftTripsList: [],
      isUploadingInProgress: false,
      isFilterRowDisplayed: false,
      lastSavedDate: null,
      isSavingInProgress: false,
    }
  },
  methods: {
    handleWishlistOrderUpdated() {
      this.handleListOrderUpdate(this.filteredItemsList)
      this.autosave("reorder")
    },
    handleWishlistImageUpdated(placeImages, placeDraggableId) {
      this.handleItemImagesUpdated(placeImages, placeDraggableId).then((place) => {
        this.autosave("update", place)
      })
    },
    handleWishlistItemRenamed(newLabel, placeDraggableId) {
      this.handleItemRenamed(newLabel, placeDraggableId).then((place) => {
        this.autosave("update", place)
      })
    },
    handleWishlistItemDescriptionUpdated(newDesc, placeDraggableId) {
      const place = findItem(this.filteredItemsList, placeDraggableId)
      this.autosave("update", place)
    },
    handleWishlistItemTypeUpdated(place) {
      this.autosave("update", place)
    },
    handleWishlistItemRemoved(place) {
      this.handlePlaceRemoved(place)
      this.autosave("delete", place)
    },

    displayFilterRow() {
      this.isFilterRowDisplayed = !this.isFilterRowDisplayed
    },
    handleFilterUpdate(filterBy, filterValues) {
      this.handleUpdateFilterQuery(filterBy, filterValues);
    },
    handleUploadingState(isInProgress) {
      console.log(`=====> image loading state: ${isInProgress}`)
      this.isUploadingInProgress = isInProgress
    },
    autosave(handleAction, placeDraggableId) {
      // if (this.saveTimeout) {
      //   clearTimeout(this.saveTimeout);  // debounce
      // }
      // // Wait for 5 seconds after user stops updating trip data
      // this.saveTimeout = setTimeout(() => {
      //   this.updateUserWishlist(handleAction, placeDraggableId)
      // }, 5000);
      this.isSavingInProgress = true
      this.updateUserWishlist(handleAction, placeDraggableId).then((updateDate) => {
        this.lastSavedDate = updateDate
        this.isSavingInProgress = false
      })
    }
  },
  mounted() {
    useAppStore().openEdit()
    TripsDataService.getAll(["draft"])
        .then(response => {
          this.draftTripsList.splice(0, this.draftTripsList.length, ...response.data)
        })
  },
  unmounted() {
    useAppStore().closeEdit()
  },
  watch: {
    tripItemsList: {
      handler() {
        this.filteredItemsList.splice(0, this.filteredItemsList.length, ...this.tripItemsList)
      },
      deep: true
    },
  },
  computed: {
    filterBadgeCounter() {
      let counter = 0
      Object.keys(this.tripsListFilters).forEach(key => {
        if (this.tripsListFilters[key].length > 0) {
          counter++
        }
      })
      return counter
    }
  },
};
</script>

<style scope>
.el-page-header__left, .el-page-header__content {
  width: 100%;
}

.trip-attributes-label {
  width: 120px;
}
.trip-attributes-row {
  display: flex;
  line-height: 32px;
}

.trip-attributes-label .icon.material-design-icon .material-design-icon__svg {
  width: 18px;
  height: 18px;
}

.trip-attributes-label .icon {
  padding-right: 5px;
}

.title-form {
  height: 32px;
}

.title-form .el-form-item__error {
  right: 0;
  left: unset;
}

.wishlist-header {
  display: flex;
  width: 100%;
}

.wishlist-content-container {
  width: calc(100% + 80px);
  margin-left: -80px;
}

.wishlist-country-select-filter.el-select {
  width: 300px;
}

.wishlist-country-select-filter .el-select__wrapper {
  border-radius: 15px;
}

.wishlist-filter {
  margin-left: auto;
  padding-right: 10px;
}

.wishlist-filter .icon.active {
  color: var(--el-color-primary);
}

.filter-badge, .wishlist-filter .icon {
  cursor: pointer;
}

.saved-indicator .el-loading-spinner .circular {
  height: calc(var(--el-loading-spinner-size) / 2);
  width: calc(var(--el-loading-spinner-size) / 2);
}

.saved-indicator .el-loading-spinner {
  margin-top: calc((0px - var(--el-loading-spinner-size)) / 4);
}

</style>
