<template>
  <div style="display: grid; place-items: center; width: 100%; line-height: 15px;">
    <el-text
        v-if="savedTimeAgo.message"
        size="small"
        :type="savedTimeAgo.status"
        style="text-align: center; white-space: nowrap;"
    >
      {{ savedTimeAgo.message }}
    </el-text>
  </div>
</template>

<script setup>
import 'vue-material-design-icons/styles.css';
import {useAppStore} from "@/stores";
const appStore = useAppStore()
</script>

<script>
import {useAppStore} from "@/stores";
import {ElMessage} from "element-plus";

export default {
  name: "Sample",
  props: ['lastSavedDate'],
  data() {
    return {
      savedTimeAgo: {status: "info", message: null},
      savedIntervalId: null,
    }
  },
  methods: {
    startUpdating() {
      this.updateSavedTimeAgo(); // Initial calculation
      this.savedIntervalId = setInterval(this.updateSavedTimeAgo, 30000); // Update every 30 seconds
    },
    updateSavedTimeAgo() {
      console.log('updating save interval ...')
      if (this.lastSavedDate === null) {
        this.savedTimeAgo = {status: "info", message: "not saved yet"}
      }
      if (this.lastSavedDate === false) {
        this.savedTimeAgo = {status: "error", message: "failed to save"}
      }
      if (this.lastSavedDate) {
        const now = new Date();
        const diffInMs = now - this.lastSavedDate; // Difference in milliseconds
        const diffInMinutes = Math.floor(diffInMs / (1000 * 60)); // Convert to minutes

        if (diffInMinutes < 1) {
          this.savedTimeAgo = {status: "primary", message: "saved a moment ago"}
        } else if (diffInMinutes === 1) {
          this.savedTimeAgo = {status: "primary", message: "saved 1 min ago"};
        } else {
          this.savedTimeAgo = {status: "primary", message: `saved ${diffInMinutes} mins ago`};
        }
      }
    },
  },
  mounted() {
    this.startUpdating();
  },
  unmounted() {
    clearInterval(this.savedIntervalId);
  },
  watch: {
    lastSavedDate: {
      handler() {
        this.updateSavedTimeAgo()
      },
      deep: true
    }
  }
};
</script>

<style scope>
.input-trip-tags .el-select__wrapper.is-focused, .input-trip-tags .el-select__wrapper, .input-trip-tags .el-select__wrapper.is-hovering:not(.is-focused) {
  box-shadow: none;
  padding-left: 0;
}

.input-trip-tags .el-select__wrapper.is-disabled
{
  box-shadow: none;
  background:none;
}

.input-trip-tags .el-select__wrapper.is-disabled .el-select__caret,
.input-trip-tags .el-select__wrapper.is-disabled .el-tag,
.input-trip-tags .el-select__wrapper.is-disabled {
  cursor: default;
}

.el-select.input-trip-tags .el-select__selection.is-near {
  margin: 0;
}

.input-trip-tags.el-select {
  min-width: 280px;
  max-width: 470px;
}
</style>
