<template>
  <div>
    <el-tag
        v-for="(tag) in displayedTagsList"
        disable-transitions
        class="collapsable-tag-item"
        round
        size="small"
    >
      {{ tag }}
    </el-tag>
    <el-popover
        v-if="isCollapsed"
        placement="top-start"
        :width="200"
        trigger="hover"
    >
      <template #reference>
<!--        <el-tag-->
<!--            disable-transitions-->
<!--            class="collapsable-tag-item"-->
<!--            round-->
<!--            size="small"-->
<!--        >-->
          {{ collapsedItemsNumber }}
<!--        </el-tag>-->
      </template>
      <template #default>
        <el-tag
            v-for="(tag) in collapsedTagsList"
            disable-transitions
            class="collapsable-tag-item"
            round
            size="small"
            style="margin-top: 3px;"
        >
          {{ tag }}
        </el-tag>
      </template>
    </el-popover>
  </div>
</template>

<script>
export default {
  props: ["itemsList"],
  components: {},
  data() {
    return {
      maxDisplayedTags: 2,
      originList: this.itemsList
    }
  },
  methods: {

  },
  computed: {
    collapsedItemsNumber() {
      return `+${this.itemsList.length - this.maxDisplayedTags}`
    },
    displayedTagsList() {
      return this.originList === undefined ? [] : this.originList.slice(0, this.maxDisplayedTags)
    },
    collapsedTagsList() {
      return this.originList === undefined ? [] : this.originList.slice(this.maxDisplayedTags, this.originList.length)
    },
    isCollapsed() {
      return this.originList === undefined ? false : this.originList.length > this.maxDisplayedTags
    }
  },
  watch: {
    itemsList: {
      handler() {
        this.originList.splice(0, this.originList.length, ...this.itemsList)
      },
      deep: true
    }
  }
};
</script>

<style>
.collapsable-tag-item {
  margin-right: 5px;
}
</style>