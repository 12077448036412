<template>
  <div class="popup-container">
    <el-scrollbar max-height="400px" ref="popupScrollbar">
    <ul class="popup-places">
      <li
          v-for="placeOption in places"
          class="popup-places-list-item"
          :class="[{ 'hovered': hoveredGeocoderPlace.includes(placeOption.draggable_id) }]"
          @click="placeOption.in_wishlist && isWishlist ? handleOptionAlreadyInWishlist(placeOption) : onOptionSelected(placeOption)"
          @mouseover="hoverMapFeature(mapInstance, popUpOptionsLayer.id, hoveredGeocoderPlace, placeOption, places)"
          @mouseleave="hoverMapFeature(mapInstance, popUpOptionsLayer.id, hoveredGeocoderPlace, null, places, false)"
      >
        <geocoder-item :item="placeOption" :isSelected="placeOption.selected" :isInWishlist="placeOption.in_wishlist && isWishlist"/>
      </li>
      <li v-if="currentPlace" class="popup-places-list-item" @click="onDuplicate()">
        <div style="padding-left: 3px;">
          <content-copy-icon />
          <span style="padding-left: 10px; font-weight: 700;">Duplicate</span>
        </div>
      </li>
      <li v-if="currentPlace" class="popup-places-list-item" @click="remove(currentPlace)">
        <div style="padding-left: 3px;">
          <trash-can-outline-icon />
          <span style="padding-left: 10px; font-weight: 700;">Delete</span>
        </div>
      </li>
    </ul>
  </el-scrollbar>
  </div>
</template>

<script setup>
import {hoverMapFeature} from "@/map/hoverMapFeature";
</script>

<script>
import {popUpOptionsLayer} from "@/map/layers";
import GeocoderItem from "@/components/GeocoderItem.vue";
import TrashCanOutlineIcon from "vue-material-design-icons/TrashCanOutline.vue";
import ContentCopyIcon from "vue-material-design-icons/ContentCopy.vue";
import {ElMessage, ElScrollbar} from "element-plus";

export default {
  components: {
    ElScrollbar,
    GeocoderItem,
    TrashCanOutlineIcon,
    ContentCopyIcon,
  },
  computed: {
    popUpOptionsLayer() {
      return popUpOptionsLayer
    }
  },
  props: ['mapInstance', 'places', 'coordinates', 'hoveredGeocoderPlace', 'currentPlace', 'remove', 'update', 'isWishlist'],
  data() {
    return {
      popUpPlaceList: this.places
    }
  },
  methods: {
    displayOptionLabel(placeOption) {
      if (placeOption.label) {
        return placeOption.label
      }
      if (placeOption.source_label) {
        return placeOption.source_label
      }
      return `${placeOption.lat.toFixed(5)}, ${placeOption.lon.toFixed(5)}`
    },
    onOptionSelected(placeOption) {
      // if (!place.selected) { // TODO: just close pop-up if nothing changed
        this.places.forEach(option => option.selected = false)
        placeOption.selected = true
        this.update(
            placeOption,
            this.currentPlace ? this.currentPlace.draggable_id : null,
            this.currentPlace ? this.currentPlace.draggable_parent_id : null
        )
      // }
    },
    handleOptionAlreadyInWishlist(placeOption) {
      console.log("this option is already in your wishlist")
      ElMessage({
        message: "This place is already in your wishlist",
        type: "warning",
        showClose: true
      });
    },
    onDuplicate() {
      this.update(this.currentPlace, null, null)
    }
  },
  mounted() {
    this.$nextTick(() => {
      const hoveredIndex = this.places.findIndex(item => this.hoveredGeocoderPlace.includes(item.draggable_id))
      if (hoveredIndex > 3 && this.$refs.popupScrollbar) {
        console.log(`scroll to index: ${hoveredIndex}`)
        this.$refs.popupScrollbar.setScrollTop(hoveredIndex * 64)
      }
    });
  }
}
</script>

<style>
.popup-places-list-item {
  padding: 2px 10px 2px 10px;
}
.popup-places {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 13px;
  color: rgb(64, 64, 64)
}
.popup-places li {
  list-style: none;
}

.popup-places li:hover, .popup-places-list-item.hovered {
  background-color: #e7e6e6;
  cursor: pointer;
}

.popup-container {
  max-height: 400px;
}

</style>