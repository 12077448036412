
export class Place {
    label = null
    description = null
    lat = null
    lon = null
    alpha_2 = null
    country_id = null
    country = null
    type = null
    draggable_id = null
    draggable_type = "place"
    draggable_parent_id = null
    children = []
    images = []
    source = null
    in_wishlist = false
    gpid = null
    date = null

    constructor(label, type, lat, lon, alpha_2, country_id, source, source_context, source_label, source_id, description, country, in_wishlist, gpid, date) {
        this.label = label
        this.type = type
        this.lat = lat
        this.lon = lon
        this.alpha_2 = alpha_2
        this.country_id = country_id
        this.source = source
        this.source_context = source_context
        this.source_label = source_label
        this.source_id = source_id
        this.description = description
        this.country = country
        this.in_wishlist = in_wishlist
        this.gpid = gpid
        this.date = date
    }
}