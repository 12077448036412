<template>
  <el-dropdown
      trigger="click"
      @visible-change="handleItemMenuActivated"
      :teleported="false"
      ref="parentDropdown"
  >
    <context-menu class="draggable-handle" :isChild="isChild" :isSelected="isSelected"/>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item v-if="item.draggable_type === 'place' && appStore.isOwnTrip" @click="deleteItem">
          <div>
            <trash-can-outline-icon/>
            <span style="padding-left: 10px;">Delete</span>
          </div>
        </el-dropdown-item>
        <el-dropdown-item v-if="item.draggable_type === 'place' && $route.name !== 'Wishlist' && appStore.isOwnTrip" @click="duplicate(item)">
          <div>
            <content-copy-icon/>
            <span style="padding-left: 10px;">Duplicate</span>
          </div>
        </el-dropdown-item>
        <el-dropdown-item v-if="item.draggable_type === 'place' && $route.name !== 'Wishlist'" @click="addToWishlist()" :disabled="item.in_wishlist">
          <div>
            <bookmark-check-outline-icon v-if="item.in_wishlist"/>
            <bookmark-outline-icon v-else />
            <span style="padding-left: 10px;">{{ `${item.in_wishlist ? 'In wishlist' : 'Add to wishlist'}`}}</span>
          </div>
        </el-dropdown-item>
        <el-dropdown-item
            v-if="$route.name === 'Wishlist'"
        >

          <el-dropdown trigger="click" @visible-change="onChildShow" placement="bottom-end">
            <div>
              <map-clock-outline-icon />
              <span style="padding-left: 10px;">Move to draft trip</span>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="draftTrip in draftTripsList" @click="addItemToDraftTrip(draftTrip)">{{ draftTrip.title }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>


        </el-dropdown-item>
        <el-dropdown-item v-if="item.draggable_type === 'route'" :disabled="true">
          <div>
            <refresh-icon />
            <span style="padding-left: 10px;">Rebuild geometry</span>
          </div>
        </el-dropdown-item>
        <el-dropdown-item v-if="item.draggable_type === 'route'" :disabled="true">
          <div>
            <eye-outline-icon v-if="!item.show_coordinates_viapoints" @click="item.show_coordinates_viapoints = true"/>
            <eye-off-outline-icon v-else @click="item.show_coordinates_viapoints = false"/>
            <span style="padding-left: 10px;">{{ item.show_coordinates_viapoints ? 'Hide' : 'Show' }} waypoints</span>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
import {useAppStore} from "@/stores";
import ContextMenu from "@/components/List/ContextMenu.vue";
const appStore = useAppStore()
</script>

<script>
import ContextMenu from "@/components/List/ContextMenu.vue";
import WishlistService from "@/services/WishlistService";
import TripsDataService from "@/services/TripsDataService";
import {findRoute, getMaxDraggableId} from "@/core/ListHelper";
import {useAppStore} from "@/stores";

export default {
  components: {
    ContextMenu
  },
  props: ['hovered', 'isChild', 'isSelected', 'item', 'delete', 'duplicate', 'draftTripsList', 'addToWishlist'],
  data() {
    return {
      isChildOpen: false,
    }
  },
  methods: {
    onChildShow(state) {
      this.isChildOpen = state;
      console.log('---> show child: ', state)
    },
    handleItemMenuActivated(state) {
      console.log('---> show parent: ', state)
      if (this.isChildOpen) {
        this.$refs.parentDropdown.handleOpen(); // Keep parent open if child is open
        console.log('---> leave parent')
      }
      this.$emit('item-row-select-event');
    },
    deleteItem() {
      this.delete(this.item, this.isChild)
    },
    addItemToDraftTrip(draftTrip) {
      const newDraggableId = draftTrip.items.length
      const route = {
        "draggable_type": "route",
        "draggable_id": newDraggableId,
        "draggable_parent_id": null,
        "origin_draggable_id": newDraggableId - 1,
        "destination_draggable_id": newDraggableId + 1,
        "type": "empty",
        "geometry": [],
        "children": [],
        "updated": false,
        "show_coordinates_viapoints": true
      }
      draftTrip.items.push(route)

      const itemToAdd = JSON.parse(JSON.stringify(this.item))
      itemToAdd.draggable_id = newDraggableId + 1
      draftTrip.items.push(itemToAdd)

      TripsDataService.update(draftTrip.id, draftTrip)
        .then(response => {
          this.delete(this.item, this.isChild)
        })
    }
  },
  computed: {
    _contextMenuCursor() {
      if (useAppStore().isOwnTrip && this.item.draggable_type === 'place') {
        return "grab"
      }
      return "pointer"
    },
  }
};
</script>

<style>
.draggable-handle {
  cursor: v-bind(_contextMenuCursor);
  z-index: 2;
}
</style>
