import {defineStore} from 'pinia';
import router from "@/router/routes";
import AuthService from "@/services/AuthService";
import UserDataService from "@/services/UserDataService";

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        token: JSON.parse(localStorage.getItem('token')),
        userId: JSON.parse(localStorage.getItem('userId')),
    }),
    actions: {
        login(google_token) {
            return AuthService.login(google_token).then((response) => {
                this._setToken(response.data);
                UserDataService.getCurrentData().then((response) => {
                    this.userId = response.data.id;
                    localStorage.setItem('userId', response.data.id);
                });
            })
        },
        refreshToken() {
            if (this.token) {
                return AuthService.refresh().then((response) => {
                    this._setToken(response.data);
                })
            }
        },
        logout() {
            this.token = null;
            this.userId = null
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            router.push('/welcome');
        },
        _setToken(token) {
            if (token) {
                // update pinia state
                this.token = token;
                console.log(`set token: ${token}`)

                // store user details and jwt in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', JSON.stringify(token));
            }
        }
    },
});
