<template>
  <div id="app">
    <el-container>
      <el-header>
        <header-main :handleAuthLoading="handleAuthLoading"/>
      </el-header>
      <el-main>
        <router-view v-loading="authInProgress"/>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import HeaderMain from '@/components/HeaderMain.vue'
import { useFavicon } from '@vueuse/core'
export default {
  name: 'app',
  components: {
    HeaderMain,
  },
  data() {
    return {
      authInProgress: false
    }
  },
  methods: {
    handleAuthLoading(status) {
      this.authInProgress = status
    }
  },
  mounted() {
    document.title = 'Been Journal';
    const icon = useFavicon()
    icon.value = 'been-to-favicon.png'
  }
}
</script>

<style >
body {
  margin: 0 !important;
  font-family: Helvetica, sans-serif;
  color: #333;
}
</style>