<template>
  <el-container>
    <el-aside width="50%" class="content-container">
      <slot name="content"
            :map="map"
            :mapPlaces="mapPlaces"
            :mapRoutes="mapRoutes"
            :tripsList="tripsList"
            :tripsListFilters="tripsListFilters"
            :tripsListFilterOptions="tripsListFilterOptions"
            :handleUpdateFilterQuery="handleUpdateFilterQuery"
            :isTripsDataLoaded="isTripsDataLoaded"
            :getTripPlaces="getTripPlaces"
            :getUserTrips="getUserTrips"
            :addPlaceToList="handlePlaceAddedOrMoved"
            :handlePlaceRemoved="handlePlaceRemoved"
            :handleItemImagesUpdated="handleItemImagesUpdated"
            :handleItemRenamed="handleItemRenamed"
            :handleItemDateUpdate="handleItemDateUpdate"
            :tripData="tripData"
            :tripItemsList="tripItemsList"
            :hoveredPlace="hoveredPlace"
            :hoveredCountry="hoveredCountry"
            :hoveredRoute="hoveredRoute"
            :hoveredChild="hoveredPoint"
            :handleRouteTypeUpdate="handleRouteTypeUpdate"
            :handlePlaceTypeUpdate="handlePlaceTypeUpdate"
            :handleListOrderUpdate="handleListOrderUpdate"
            :handleListItemHovered="handleListItemHovered"
            :user="user"
            :updateUserWishlist="updateUserWishlist"
      ></slot>
    </el-aside>
    <el-main width="50%" style="padding: 0 0 20px 20px">
      <div id="trips-map" class="map-container" v-loading="!isTripsDataLoaded">
        <div class="trips-map-custom-controls" @click="resetZoom"><magnify-scan-icon class="icon"/></div>
        <div class="trips-map-custom-controls config" :class="[{ 'displayed': mapShowConfig }]" @click="showMapConfig">
          <el-badge is-dot :hidden="mapRouteTypesSelected.length === 8" class="filter-badge" :show-zero="false" type="primary" :offset="[16, 0]">
            <tune-variant-icon class="icon"/>
          </el-badge>
        </div>
        <map-layers-control v-if="mapShowConfig" :filterMapLayers="filterMapLayers" :routeTypesSelected="mapRouteTypesSelected"/>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import mapboxgl from "mapbox-gl";
import { mount } from 'mount-vue-component'

import TripsDataService from "@/services/TripsDataService";
import RoutesService from "@/services/RoutesService";
import PlacesDataService from "@/services/PlacesDataService";

import MapPlacePopUp from "@/components/MapPopUps/MapPlacePopUp.vue";

import 'mapbox-gl/dist/mapbox-gl.css';

import {useAppStore, useAuthStore} from "@/stores";
import {countriesLayer, circlesLayer, routesLayer, viaPointsLayer, popUpOptionsLayer} from '@/map/layers'
import {Place} from "@/map/place";
import {
  buildPlacesPairs,
  getMaxDraggableId,
  findRoute,
  findItem,
  findRouteCandidates,
  findParent, findPairIndex
} from "@/core/ListHelper"
import {drawFlightRoute, insertViaPointAtIndex, setMapCenterAndZoom} from "@/map/calculations";
import {updateCountriesLayer, updateLayer} from "@/map/updateMapLayer";
import {hoverMapFeature} from "@/map/hoverMapFeature";
import {hoverLayerEvent} from "@/map/eventHandlers";
import router from "@/router/routes";
import UserDataService from "@/services/UserDataService";
import WishlistService from "@/services/WishlistService";
import MapLayersControl from "@/components/Map/MapLayersControl.vue";
import {routeTypes} from "@/map/routeTypes";

export default {
  components: {
    MapLayersControl
  },
  data() {
    return {
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
      map: null,
      mapCursor: null,
      mapZoomTriggered: false,
      mapTimer: setTimeout(() => {}, 0),
      mapCoordinates: null,
      adjustMapCenterAndZoom: true,
      adjustMapCenterAndZoomPlaces: true,

      user: null,

      hoveredRoute: [],
      hoveredCountry: [],
      hoveredCountryId: null,
      hoveredGeocoderPlace: [],
      hoveredPlace: [],
      hoveredPoint: [],

      popUpCoordinates: null,
      popUpIsViapoint: null,
      popUpPlace: null,
      pointDragged: null,

      routeViaPointIsRebuilt: false,
      pointDraggedMouseUp: true,

      tripData: {
        items: [],
        mates: [],
        tags: []
      },
      tripItemsList: [],
      mapPlaces: {
        value: [],
      },
      mapShowConfig: false,
      mapRouteTypesSelected: useAppStore().displayMapRouteTypes,
      mapRoutes: { // contains actual mapRoutesFeatureCollection
        value: [],
      },
      mapChildren:[], // used to hover route & place children. used to move child feature on map

      geocoderPlaces: {
        value: []
      },
      tripsList: [],
      tripsListFilters: {tags: [], country: [], mates: []},
      tripsListFilterOptions: {tags: [], country: [], mates: []},
      isTripsDataLoaded: false,
      popup: null,
    };
  },
  methods: {
    _getFiltersKey() {
      if (this.$route.name === 'Wishlist') {
        return "wishlist"
      } else if (!useAppStore().isOwnTrip) {
        return "shared_trips"
      } else {
        return "own_trips"
      }
    },
    getStoredFilters() {
      // get filters values from cache
      this.tripsListFilters = useAppStore().userFilters[this._getFiltersKey()]
    },
    setStoredFilters(filterBy, filterValues) {
      useAppStore().userFilters[this._getFiltersKey()][filterBy].splice(0, useAppStore().userFilters[this._getFiltersKey()][filterBy].length, ...filterValues)
      localStorage.setItem('userFilters', JSON.stringify(useAppStore().userFilters))
      return useAppStore().userFilters[this._getFiltersKey()][filterBy]
    },
    getRequestedUserData(user_id) {
      // TODO: maybe remove and return owner data in the trip ?
      return UserDataService.getUserData(user_id).then((response) => {
        this.user = response.data
        return this.user
      })
    },
    getTripPlaces(trip_id) {
      TripsDataService.get(trip_id).then(response => {
        useAppStore().isOwnTrip = response.data.user_id === useAuthStore().userId;
        this.getStoredFilters()
        if (!useAppStore().isOwnTrip) {
          useAppStore().closeEdit()
          this.getRequestedUserData(response.data.user_id)
        }

        this.tripData = response.data
        this.tripItemsList.splice(0, this.tripItemsList.length, ...response.data.items)
        this.splitList() // TODO: test if it's required there, as executed in watched

        this.tripsList.splice(0, this.tripsList.length, ...[response.data])
        this.isTripsDataLoaded = true
      }).catch(() => {
        router.push('/not-found');
      });
    },
    getUserWishlist() {
      useAppStore().isOwnTrip = true
      this.getStoredFilters()
      this.adjustMapCenterAndZoomPlaces = true

      WishlistService.get(this.tripsListFilters).then(response => {
        this.tripData = null
        this.tripItemsList.splice(0, this.tripItemsList.length, ...response.data)
        this.tripsList.splice(0, this.tripsList.length, ...[])
        this.isTripsDataLoaded = true
      })
      WishlistService.getFilters().then(response => {
        this.tripsListFilterOptions = response.data
      })
    },
    handleUpdateFilterQuery(filterBy, filterValues) {
      this.isTripsDataLoaded = false
      this.tripsListFilters[filterBy] = this.setStoredFilters(filterBy, filterValues)
      this.fetchData()
    },
    updateUserWishlist(handleAction, item=null) {
      return new Promise((resolve) => {
        if (handleAction === "add") {
          console.log("update wishlist: create new place")
          WishlistService.add([item]).then(response => {
            this.getUserWishlist()
            resolve(new Date())
          })
        } else if (handleAction === "delete") {
          console.log("update wishlist: delete place")
          WishlistService.delete(item).then(response => {
            this.getUserWishlist()
            resolve(new Date())
          })
        } else if (handleAction === "update") {
          console.log("update wishlist: update place")
          WishlistService.update(item).then(response => {
            this.getUserWishlist()
            resolve(new Date())
          })
        } else if (handleAction === "reorder" && !item) {
          console.log("update wishlist: reorder list")
          WishlistService.reorder(this.tripItemsList).then(response => {
            this.getUserWishlist()
            resolve(new Date())
          })
        }
      })
    },
    getUserFilters(user_id=null) {
      UserDataService.getFilters(user_id)
          .then(response => {
            this.tripsListFilterOptions = response.data
          })
    },
    getUserTrips(user_id=null) {
      console.log(`----------> starting request to fetch all trips`)
      useAppStore().isOwnTrip = (user_id === null || (user_id.toString() === useAuthStore().userId.toString()));
      this.getStoredFilters()
      if (user_id === -1) {
        console.log('clear map')
        // clear map for users list page
        updateLayer(this.map, routesLayer.id, [])
        updateCountriesLayer(this.map, [], [])
        updateLayer(this.map, viaPointsLayer.id, [])
        updateLayer(this.map, circlesLayer.id, [])
      }
      else if (user_id) {
        // retrieve data for certain user
        console.log(`get user trips for user: ${user_id} - ${JSON.stringify(this.tripsListFilters)}`)
        this.getRequestedUserData(user_id)
        UserDataService.getUserTrips(user_id, this.tripsListFilters).then(response => {
          console.log(`----------> finished request to fetch all trips`)
          // display only completed trips
          this.tripsList.splice(0, this.tripsList.length, ...response.data)
          this.isTripsDataLoaded = true
        })
        this.getUserFilters(user_id)
      } else {
        // retrieve data for current user
        console.log(`get user trips for current user - ${JSON.stringify(this.tripsListFilters)}`)
        TripsDataService.getAll(null, this.tripsListFilters)
          .then(response => {
            console.log(`----------> finished request to fetch all trips`)
            this.tripsList.splice(0, this.tripsList.length, ...response.data)
            this.isTripsDataLoaded = true
          })
        this.getUserFilters()
      }
    },
    _defineNewRouteType(currentRoutesList, origin_draggable_id, destination_draggable_id) {
      // TODO: this logic is not so advanced. to be refactored
      // TODO: cover cases for the new route
      // TODO: cover cases for merging two routes
      let routeType = "direct"
      if (currentRoutesList.length > 0 && currentRoutesList[currentRoutesList.length - 1].type !== "empty") {
        routeType = currentRoutesList[currentRoutesList.length - 1].type
      }
      const origin = findItem(this.tripItemsList, origin_draggable_id)
      const destination = findItem(this.tripItemsList, destination_draggable_id)
      if (origin.type === "country" || destination.type === "country" || this.isWishlist) {
        routeType = "empty"
      }
      return routeType
    },

    buildRouteGeometry(route) {
      return new Promise((resolve) => {
        // route.dashed = false
        if (route.updated || (route.geometry.length === 0 && route.type !== "empty")) {
          // console.log(`====> rebuilding geometry for ${route.origin_draggable_id} - ${route.destination_draggable_id}`)
          const FEATURE_FLAG_BUILD_ROUTES_FROM_API = true
          const originPlace = findItem(this.tripItemsList, route.origin_draggable_id)
          const destinationPlace = findItem(this.tripItemsList, route.destination_draggable_id)

          if (route.type === "flight") {
            route.geometry = drawFlightRoute([[originPlace.lon, originPlace.lat], ...route.children.map(point => [point.lon, point.lat]), [destinationPlace.lon, destinationPlace.lat]])
            resolve(route)
          } else if (["driving", "walking", "bus", "hitchhiking"].includes(route.type) && FEATURE_FLAG_BUILD_ROUTES_FROM_API) {
            try {
              RoutesService.getDrivingRoute(
                  this.accessToken,
                  [[originPlace.lon, originPlace.lat], ...route.children.map(point => [point.lon, point.lat]), [destinationPlace.lon, destinationPlace.lat]],
                  route.type === "walking" ? "walking" : "driving"
              ).then(response => {
                console.log('MAPBOX REQUEST: retrieved route from Mapbox')
                if (response.data.routes.length > 0) {
                  route.geometry = response.data.routes[0].geometry.coordinates
                } else {
                  console.log("[WARNING] Not possible to build route")
                }
                resolve(route)
              })
            } catch (e) {
              console.log(`failed to build driving route with Mapbox API: ${e}`)
            }
          } else if (route.type === "train") {
            try {
              RoutesService.getTrainRoute(
                  // ATTENTION: lat,lon order
                  [[originPlace.lat, originPlace.lon], ...route.children.map(point => [point.lat, point.lon]), [destinationPlace.lat, destinationPlace.lon]],
              ).then(response => {
                console.log('GOOGLE DIRECTIONS: retrieved route from Google Direction API')
                if (response.data.length > 0) {
                  route.geometry = response.data
                } else {
                  console.log("[WARNING] Not possible to build route")
                }
                resolve(route)
              })
            } catch (e) {
              console.log(`failed to build driving route with Mapbox API: ${e}`)
            }
          } else if (route.type === "direct" || route.type === "ferry") {
            route.geometry = [[originPlace.lon, originPlace.lat], ...route.children.map(point => [point.lon, point.lat]), [destinationPlace.lon, destinationPlace.lat]]

            // if (originPlace.type === "country" || destinationPlace.type === "country") {
            //   route.dashed = true
            // }
            resolve(route)
          } else {
            route.geometry = []
            resolve(route)
          }
        } else {
          // console.log(`====> using prev geometry for ${route.origin_draggable_id} - ${route.destination_draggable_id}`)
          resolve(route)
        }
      })
    },
    async buildAllRoutesGeometry(routes) {
      return new Promise((resolve) => {
        let buildRoutes = []
        if (useAppStore().isEditMode) {
          const promisesArray = [];
          // for every pair by position in the list
          routes.forEach(route => {
            const promise = this.buildRouteGeometry(JSON.parse(JSON.stringify(route)));
            promisesArray.push(promise);
          })

          Promise.all(promisesArray)
              .then((results) => {
                // console.log("Map Template: all routes are added with geometry");
                results.forEach(route => {
                  buildRoutes.push(route)
                })
                resolve(buildRoutes)
              })
              .catch((error) => {
                console.error("Map Template: route creation error", error);
              });
        }
      })
    },

    addPlaceFromPopup(newPlace, placeToUpdate=null) {
      this.handlePlaceAddedOrMoved(newPlace, placeToUpdate).then(() => {
        this.closePopUp()
      })
    },
    
    closePopUp(cleanVariables = true) {
      // console.log('close pop up')
      updateLayer(this.map, popUpOptionsLayer.id, [])

      if (cleanVariables) {
        this.popUpCoordinates = null
        this.pointDragged = null
        this.geocoderPlaces.value.splice(0)
        this.popUpPlace = null
        this.popUpIsViapoint = null
      }

      if (this.popup && this.popup.isOpen()) {
        // console.log('removing prev pop-up')
        this.popup.remove();
      }
    },
    
    // map events
    filterMapLayers(selectedRouteTypes) {
      this.mapRouteTypesSelected = selectedRouteTypes
      useAppStore().displayMapRouteTypes = selectedRouteTypes
      const filteredRoutes = this.mapRoutes.value.filter(route => selectedRouteTypes.includes(route.type))
      updateLayer(this.map, routesLayer.id, filteredRoutes)
      if (filteredRoutes.length > 0) {
        this.mapChildren.splice(
            0,
            this.mapChildren.length,
            ...filteredRoutes.flatMap(route => route.type !== "empty" ? route.children : []).concat(this.mapPlaces.value.flatMap(place => place.children))
        )
        updateLayer(this.map, viaPointsLayer.id, this.mapChildren)
      }
    },
    onMouseStopped() {
      if (this.pointDragged) {
        if (this.pointDragged.layer.id === viaPointsLayer.id) {
          this.handleRouteChildAddedOrMoved(
              {lat: this.mapCoordinates.lat, lon: this.mapCoordinates.lon, source: "coordinates"},
              this.pointDragged.properties.draggable_id, // TODO: this causes an error on mouse up
              this.pointDragged.properties.draggable_parent_id
          ).then((viapointUpdated) => {
            // console.log(`===> stopped: child updated: ${JSON.stringify(viapointUpdated)}`)
            // clean dragged via point only if rebuild request is finished and user dropped the point
            if (this.pointDraggedMouseUp) {
              console.log(`++ display pop up on mouse stop`)
              this._displayPlacePopup(viapointUpdated, viapointUpdated)
              this.pointDragged = null
            }
            this.routeViaPointIsRebuilt = true
          })
        } else if (this.pointDragged.layer.id === circlesLayer.id) {
          console.log("processing icons / circles layer")
          this.handlePlaceAddedOrMoved(
              {lat: this.mapCoordinates.lat, lon: this.mapCoordinates.lon, source: "coordinates"},
              this.pointDragged.properties.draggable_id
          ).then((placeUpdated) => {
            // clean dragged via point only if rebuild request is finished and user dropped the point
            if (this.pointDraggedMouseUp) {
              this._displayPlacePopup(placeUpdated, placeUpdated, false)
              this.pointDragged = null
            }
            this.routeViaPointIsRebuilt = true
          })
        }
      }
    },
    onMove(e) {
      // console.log('moving')
      // Set a UI indicator for dragging.
      this.mapCursor.style.cursor = 'grabbing';

      if (this.pointDragged) {
        let placesOnMove = JSON.parse(JSON.stringify(this.pointDragged.layer.id === circlesLayer.id ? this.mapPlaces.value.filter((place) => place.type !== "country") : this.mapChildren))
        let draggingItem = findItem(placesOnMove, this.pointDragged.properties.draggable_id)
        draggingItem.lat = e.lngLat.lat
        draggingItem.lon = e.lngLat.lng
        updateLayer(this.map, this.pointDragged.layer.id, placesOnMove)
      }

      clearTimeout(this.mapTimer);
      this.mapCoordinates = {lat: e.lngLat.lat, lon: e.lngLat.lng}
      this.routeViaPointIsRebuilt = false
      this.mapTimer = setTimeout(this.onMouseStopped,200);
    },
    onUp() {
      console.log('mouse up')
      this.mapCursor.style.cursor = '';
      this.pointDraggedMouseUp = true;

      // clean dragged via point only if rebuild request is finished
      if (this.routeViaPointIsRebuilt && this.pointDragged) {
        // console.log("display pop up on mouse up")
        let popUpPlace = findItem(this.tripItemsList, this.pointDragged.properties.draggable_id)
        this._displayPlacePopup(popUpPlace, popUpPlace, this.pointDragged.layer.id !== viaPointsLayer.id)
        this.pointDragged = null
      }

      // Unbind mouse/touch events
      this.map.off('mousemove', this.onMove);
      this.map.off('touchmove', this.onMove);
    },
    _displayPlacePopup(coordinates, place, isViapoint = true) {
      this.closePopUp(false)
      // console.log(`pop up: ${JSON.stringify(coordinates)}\n${JSON.stringify(place)}`)
      this.popUpCoordinates = {lat: coordinates.lat, lon: coordinates.lon};
      this.popUpPlace = place
      this.popUpIsViapoint = isViapoint

      const placesTypesToRequest = isViapoint || this.isWishlist ? ["place", "coordinates"] : ["place", "coordinates", "country"]
      PlacesDataService.find(this.popUpCoordinates.lat, this.popUpCoordinates.lon, placesTypesToRequest).then((response) => {
        this.geocoderPlaces.value.splice(0, this.geocoderPlaces.value.length, ...response.data)

        // highlight already selected place if any
        if (place) {
          if (place.source === "user") {
            // update current place context if moved
            const nearestContextPlace = this.geocoderPlaces.value.find(item => item.source === "coordinates")
            if (nearestContextPlace) {
              place.source_context = nearestContextPlace.source_context
            }
            this.geocoderPlaces.value.push(place)
          }

          let selectedPlace = this.geocoderPlaces.value.find(option =>
              option.lat === place.lat
              && option.lon === place.lon
              && option.label === place.label
              && option.source_label === place.source_label
          )
          if (selectedPlace) {
            selectedPlace.selected = true
          }
        }

        if (this.geocoderPlaces.value.length > 0) {
          // filter current point for via points only
          updateLayer(this.map, popUpOptionsLayer.id, isViapoint ? this.geocoderPlaces.value.filter(option => option.lat !== place.lat && option.lon !== place.lon) : this.geocoderPlaces.value)

          // add geocoder places to pop-up container
          this.popup.setLngLat(this.popUpCoordinates)
          this.popup.setDOMContent(this.generatePlacePopUpContent())
          this.popup.addTo(this.map);
        }
      })
    },
    generatePlacePopUpContent() {
      const popupContainer = document.createElement("div")
      const {vNode, destroy, el} = mount(MapPlacePopUp, {
        props: {
          mapInstance: this.map,
          places: this.geocoderPlaces.value,
          coordinates: this.popUpCoordinates,
          hoveredGeocoderPlace: this.hoveredGeocoderPlace,
          currentPlace: this.popUpPlace,
          remove: this.handlePlaceRemoved,
          update: this.popUpIsViapoint ? this.handleRouteChildAddedOrMoved : this.addPlaceFromPopup,
          isWishlist: this.isWishlist
        }, element: popupContainer
      })
      return popupContainer
    },

    _updateSingleRouteGeometry(routeToUpdate) {
      try {
        this.buildRouteGeometry(routeToUpdate).then(updatedRoute => {
          // TODO: is it correct to use position here as an index?
          const routeIndexToReplace = this.mapRoutes.value.findIndex(route => route.draggable_id === routeToUpdate.draggable_id)
          this.mapRoutes.value.splice(routeIndexToReplace, 1, updatedRoute)
        })
      } catch (error) {
        console.log("failed to update map routes after route child removed")
        console.log(error)
      }
    },

    // route type is updated => rebuild single route geometry => update map for routes
    handleRouteTypeUpdate(route) {
      this._updateSingleRouteGeometry(route)
    },

    handleItemDateUpdate(itemDraggableId, updatedDate) {
      return new Promise((resolve) => {
        let itemToUpdate = findItem(this.tripItemsList, itemDraggableId)
        itemToUpdate.date = updatedDate
        resolve(itemToUpdate)
      })
    },
    
    // route child coordinates are updated
    // new child added to route
    //     => rebuild single route geometry => update map for routes => update map for route children
    handleRouteChildAddedOrMoved(newPlace, placeToUpdateDraggableId=null, routeDraggableId) {
      return new Promise((resolve) => {
        // insert viaPoint to the route feature and rebuild it
        // console.log(`find route with id: ${parentDraggableId}`)
        const route = findItem(this.tripItemsList, routeDraggableId)
        let viaPointResult = null
        if (route) {
          route.updated = true

          // in case of moving: replace if via point is found by id
          if (placeToUpdateDraggableId != null) {
            console.log("viapoint: updating as viapoint dragged is not empty")
            let itemToUpdate = findItem(this.tripItemsList, placeToUpdateDraggableId);
            if (itemToUpdate) {
              this._resetPlaceLabelOnMove(itemToUpdate, newPlace)
              viaPointResult = itemToUpdate
              this.hoveredPoint.splice(0);
              this.pointDragged = null
              this.closePopUp()
            }
          } else { // otherwise add a new via point
            // console.log("viapoint: adding new viapoint")
            const insertIndex = insertViaPointAtIndex(route, [newPlace.lon, newPlace.lat])
            const source_label = null // `${coordinates.lat.toFixed(5)}, ${coordinates.lo.toFixed(5)}`
            const via_point = new Place(
                null,
                "coordinates",
                newPlace.lat,
                newPlace.lon,
                newPlace.alpha_2,
                newPlace.country_id,
                "coordinates",
                null,
                null,
                null,
                newPlace.country,
                newPlace.in_wishlist,
                newPlace.gpid,
                null
            )

            via_point.route_origin_draggable_id = route.origin_draggable_id
            via_point.route_destination_draggable_id = route.destination_draggable_id
            via_point.draggable_id = getMaxDraggableId(this.tripItemsList)
            via_point.draggable_parent_id = route.draggable_id
            route.children.splice(insertIndex, 0, via_point);
            viaPointResult = via_point
          }

          this._updateSingleRouteGeometry(route)

          PlacesDataService.get_country(viaPointResult.lat, viaPointResult.lon).then((response) => {
            viaPointResult.alpha_2 = response.data
            // console.log(`----> via point modified/created: ${JSON.stringify(viaPointResult)}`)
            // Added here to update route immediately, and countries layer when the code for coords is received
            updateCountriesLayer(this.map, !this.isWishlist ? this.mapPlaces.value : [], !this.isWishlist ? this.mapRoutes.value : [])
            resolve(viaPointResult)
          })
        }
      })
    },

    // child type updated = no need in handler
    // place type is updated = no need in handler
    handlePlaceTypeUpdate(place, isChild) {
      if (isChild) {
        updateLayer(this.map, viaPointsLayer.id, this.mapChildren)
      } else {
        updateLayer(this.map, circlesLayer.id, this.mapPlaces.value.filter((place) => place.type !== "country"))
      }
    },

    // child renamed = no need in handler
    // place renamed = no need in handler
    handleItemRenamed(newLabel, itemDraggableId) {
      // place = coords. source_label = null, label = null => coords
      //        on rename: label = "qwerty" => label
      // place = mapbox. source_label = "Kyiv", label = null => source_label
      //        on rename: label = "qwerty" => label
      // place = coords. source_label = null, label = null => coords
      //        on rename: label = "qwerty" => label
      return new Promise((resolve) => {
        let itemToUpdate = findItem(this.tripItemsList, itemDraggableId)

        if (newLabel === null || newLabel === "") {
          itemToUpdate.label = null
          if (itemToUpdate.draggable_type === "place") {
            itemToUpdate.source = "coordinates"
          }
        } else {
          itemToUpdate.label = newLabel
          if (itemToUpdate.draggable_type === "place") {
            itemToUpdate.source = "user"
          }
        }
        resolve(itemToUpdate)
      })
    },

    handleItemImagesUpdated(placeImages, placeDraggableId) {
      // TODO: merge with handleUploadingState and imageProgressUploading state
      return new Promise((resolve) => {
        let itemToUpdate = findItem(this.tripItemsList, placeDraggableId)
        itemToUpdate.images.splice(0, itemToUpdate.images.length, ...placeImages)
        resolve(itemToUpdate)
      })
    },

    _resetPlaceLabelOnMove(itemToUpdate, newPlace) {
      // coords - coords = label: null
      // coords - mapbox = label: new
      // mapbox - coords = label: null
      // mapbox - mapbox = label: new
      // user - coords = label: label
      // user - mapbox = label: new
      if (newPlace.source === "coordinates" && newPlace.lat === itemToUpdate.lat && newPlace.lon === itemToUpdate.lon) {
        console.log("rename flow: drop all labels")
        itemToUpdate.source_label = null
        itemToUpdate.label = null
        itemToUpdate.source = newPlace.source
      } else if (newPlace.source === "coordinates" && itemToUpdate.source !== "user") {
        console.log("rename flow: drop source label")
        itemToUpdate.source_label = null
      }
      if (newPlace.source === "mapbox" || newPlace.source === "google") {
        console.log("rename flow: mapbox")
        itemToUpdate.source_label = newPlace.source_label
        itemToUpdate.label = null
        itemToUpdate.source = newPlace.source
      }

      itemToUpdate.lat = newPlace.lat
      itemToUpdate.lon = newPlace.lon
    },

    // place removed
    // child removed
    handlePlaceRemoved(placeToRemove) {
      this.adjustMapCenterAndZoomPlaces = true

      const placeParent = findParent(this.tripItemsList, placeToRemove.draggable_id)

      if (placeParent) {
        placeParent.children.splice(0, placeParent.children.length, ...placeParent.children.filter(child => child.draggable_id !== placeToRemove.draggable_id));

        if (placeParent.draggable_type === "route") {
          placeParent.updated = true // required to force route geometry update

          this._updateSingleRouteGeometry(placeParent)
        } else {
          // TODO: update place children layer
        }
      } else {
        let indexToRemove = this.tripItemsList.findIndex(item => item.draggable_type === 'place' && item.draggable_id === placeToRemove.draggable_id)
        if (indexToRemove >= 0) {
          this.tripItemsList.splice(indexToRemove, 1)
          // TODO: replace with removing only connected routes [PERFORMANCE]
          this.handleListOrderUpdate(this.tripItemsList)
        }
      }

      this.hoveredPoint.splice(0);
      this.pointDragged = null
      this.closePopUp()
    },

    // place added or place position updated
    handlePlaceAddedOrMoved(newPlace, placeToUpdateDraggableId=null) {
      // newPlace: feature data from geocoder (search) / reverse geocoder (pop up)
      // place to update: place from map features to be updated
      //
      // if place to update - do nothing
      // if no place to update
      //    if place from geocoder (search)
      //      enrich with country context
      //      insert into list as country / place
      //    if from reverse geocoder (pop up)
      //      insert into list
      // watch list
      //   => new place detected => list refreshed
      //   => new route detected => routes rebuild

      // add country to the list
      return new Promise((resolve) => {
        this.adjustMapCenterAndZoomPlaces = true
        // update place if received
        console.log(`handlePlaceAddedOrMoved: ${placeToUpdateDraggableId}`)
        if (placeToUpdateDraggableId != null) {
          let itemToUpdate = findItem(this.tripItemsList, placeToUpdateDraggableId)
          if (itemToUpdate) {
            console.log(`updating place with id: ${placeToUpdateDraggableId}`)
            this._resetPlaceLabelOnMove(itemToUpdate, newPlace)

            let listRoutesToRebuild = findRouteCandidates(this.tripItemsList, itemToUpdate.draggable_id, itemToUpdate.draggable_id)
            // console.log(`routes to be updated: ${listRoutesToRebuild.length}`)

            // TODO: replace with updating only routes from the list [PERFORMANCE]
            listRoutesToRebuild.forEach(route => {
              route.updated = true
            })
            // TODO: replace with adding single route to the list [PERFORMANCE]
            this.handleListOrderUpdate(this.tripItemsList)

            PlacesDataService.get_country(itemToUpdate.lat, itemToUpdate.lon).then((response) => {
              itemToUpdate.alpha_2 = response.data

              updateCountriesLayer(this.map, !this.isWishlist ? this.mapPlaces.value : [], !this.isWishlist ? this.mapRoutes.value : [])

              if (this.isWishlist) {
                this.updateUserWishlist("update", itemToUpdate)
              }

              resolve(itemToUpdate)
            })
          }
        } else {
          let placeToInsert = new Place(  // label, type, lat, lon, alpha_2, country_id, source, source_context, source_label
              newPlace.source === 'user' ? newPlace.label : null,
                  newPlace.type,
                  newPlace.lat,
                  newPlace.lon,
                  newPlace.alpha_2,
                  newPlace.country_id,
                  newPlace.source,
                  newPlace.source_context,
                  newPlace.source_label,
                  newPlace.source_id,
                  newPlace.description,
                  newPlace.country,
                  newPlace.in_wishlist,
                  newPlace.gpid,
                  newPlace.date
              )
          placeToInsert.draggable_id = getMaxDraggableId(this.tripItemsList)
          // TODO: replace with adding single route to the list [PERFORMANCE]
          if (this.isWishlist) {
            this.tripItemsList.unshift(placeToInsert) // insert to the top of the list
            this.updateUserWishlist("add", placeToInsert)
          } else {
            this.tripItemsList.push(placeToInsert)
            this.handleListOrderUpdate(this.tripItemsList)
          }
          resolve(placeToInsert)
        }
      })
    },


    // TODO: country is hovered
    // place is hovered
    // route is hovered
    // route child is hovered
    // place child is hovered
    handleListItemHovered(item, hovered){
      if (item.draggable_type === "route") {
        hoverMapFeature(this.map, routesLayer.id, this.hoveredRoute, item, this.mapRoutes.value, hovered)
      }
      if (item.draggable_type === "place" && !item.draggable_parent_id) {
        hoverMapFeature(this.map, circlesLayer.id, this.hoveredPlace, item, this.mapPlaces.value, hovered)
      }
      if (item.draggable_type === "place" && item.draggable_parent_id !== null) {
        hoverMapFeature(this.map, viaPointsLayer.id, this.hoveredPoint, item, this.mapChildren, hovered)
      }
    },

    // places are reordered
    // new child added to place = places are reordered
    // child position updated = places are reordered
    handleListOrderUpdate(draggableList) {
      if (!this.isWishlist) {
        const rebuiltRoutes = this._rebuildRoutesOnListUpdate(draggableList)

        this.buildAllRoutesGeometry(rebuiltRoutes).then((routesWithGeometry) => {
          const listPlaces = draggableList.filter(item => item.draggable_type === "place")

          for (let i = 0; i < listPlaces.length - 1; i++) {
            const route = findRoute(routesWithGeometry, listPlaces[i].draggable_id, listPlaces[i + 1].draggable_id)
            if (route) {
              listPlaces.splice(i + 1, 0, route)
            } else {
              // console.log(`[ERROR] No route found between ${listPlaces[i].draggable_id} and ${listPlaces[i + 1].draggable_id}`)
            }
            i++;
          }

          this.tripItemsList.splice(0, this.tripItemsList.length, ...listPlaces)
        })
      } else {
        // do not rebuild routes for the wishlist
        this.tripItemsList.splice(0, this.tripItemsList.length, ...draggableList)
      }
    },
    _rebuildRoutesOnListUpdate(draggableList) {
      let matchedRoutes = []
      const updatedPlacesPairs = buildPlacesPairs(draggableList.filter(item => item.draggable_type === "place"))
      // console.log(`new pairs generated: ${JSON.stringify(updatedPlacesPairs.map(pair => {return [pair[0].draggable_id, pair[1].draggable_id]}))}`)
      for(let i = 0; i < updatedPlacesPairs.length; i++) {
        const pair = updatedPlacesPairs[i]
        // console.log(`--- processing pair: ${pair[0].draggable_id} - ${pair[1].draggable_id}`)
        // find matched routes
        let route = findRoute(this.tripItemsList, pair[0].draggable_id, pair[1].draggable_id)
        if (route) {
          // console.log(`+ found matched route: ${pair[0].draggable_id} - ${pair[1].draggable_id}`)
          route.updated = true // rebuild route if child moved from one to another. TODO: could be detected more precisely
          matchedRoutes.push(route)
          updatedPlacesPairs.splice(findPairIndex(updatedPlacesPairs, route.origin_draggable_id, route.destination_draggable_id), 1)
          i--;
        }

        // find reverted routes
        route = findRoute(this.tripItemsList, pair[1].draggable_id, pair[0].draggable_id)
        if (route) {
          const swap_positions = route.origin_draggable_id
          route.origin_draggable_id = route.destination_draggable_id
          route.destination_draggable_id = swap_positions
          route.updated = true
          // TODO: revert children order

          // console.log(`+ found reverted route: ${pair[0].draggable_id} - ${pair[1].draggable_id}`)
          matchedRoutes.push(route)
          updatedPlacesPairs.splice(findPairIndex(updatedPlacesPairs, route.origin_draggable_id, route.destination_draggable_id), 1)
          i--;
        }

        // console.log(`left pairs: ${JSON.stringify(updatedPlacesPairs.map(pair => {return [pair[0].draggable_id, pair[1].draggable_id]}))}`)
      }

      // find connected routes with children
      for(let i = 0; i < draggableList.length; i++) {
        if (draggableList[i].draggable_type === "place") {
          // console.log(`--- inspecting place as prev child: ${draggableList[i].draggable_id}`)
          if (i < draggableList.length - 1
              && draggableList[i+1].draggable_type === "route"
              && draggableList[i].draggable_parent_id === draggableList[i+1].draggable_id
              // && findPairIndex(updatedPlacesPairs, draggableList[i+1].origin_draggable_id, draggableList[i+1].destination_draggable_id) > 0
          ) {
            const route = JSON.parse(JSON.stringify(draggableList[i+1]))
            route.origin_draggable_id = draggableList[i].draggable_id
            route.updated = true
            draggableList[i].draggable_parent_id = null
            // console.log(`+ found connected route for place ${draggableList[i].draggable_id} : ${draggableList[i+1].origin_draggable_id} - ${draggableList[i+1].destination_draggable_id}. stored as ${route.origin_draggable_id} - ${route.destination_draggable_id}`)

            // TODO: update other attrs
            matchedRoutes.push(route)
            updatedPlacesPairs.splice(findPairIndex(updatedPlacesPairs, route.origin_draggable_id, route.destination_draggable_id), 1)
          }

          if (i > 0
              && draggableList[i-1].draggable_type === "route"
              && draggableList[i].draggable_parent_id === draggableList[i-1].draggable_id
              // && findPairIndex(updatedPlacesPairs, draggableList[i-1].origin_draggable_id, draggableList[i-1].destination_draggable_id) > 0
          ) {
            const route = JSON.parse(JSON.stringify(draggableList[i-1]))
            draggableList[i].draggable_parent_id = null
            route.destination_draggable_id = draggableList[i].draggable_id
            route.updated = true
            // console.log(`+ found connected route for place ${draggableList[i].draggable_id} : ${draggableList[i-1].origin_draggable_id} - ${draggableList[i-1].destination_draggable_id}. stored as ${route.origin_draggable_id} - ${route.destination_draggable_id}`)

            // TODO: update other attrs
            matchedRoutes.push(route)
            updatedPlacesPairs.splice(findPairIndex(updatedPlacesPairs, route.origin_draggable_id, route.destination_draggable_id), 1)
          }
        }
      }

      // console.log(`left pairs: ${JSON.stringify(updatedPlacesPairs.map(pair => {return [pair[0].draggable_id, pair[1].draggable_id]}))}`)

      let newRoutesCounter = 0 // this var is used to modify maxDraggableId, as it does not count new ids from the loop

      // merge connected routes
      const previousRoutesPairs = this.mapRoutes.value.map(route => {return [route.origin_draggable_id, route.destination_draggable_id]})
      // console.log(`previous route pairs: ${JSON.stringify(previousRoutesPairs)}`)
      for(let i = 0; i < previousRoutesPairs.length - 1; i++) {
        // console.log(`---- inspection pairs: [${previousRoutesPairs[i][0]}, ${previousRoutesPairs[i][1]}] & [${previousRoutesPairs[i+1][0]}, ${previousRoutesPairs[i+1][1]}]`)
        // console.log(`---- equal o/d: ${previousRoutesPairs[i][1] === previousRoutesPairs[i+1][0]}`)
        // console.log(`---- not used: ${findPairIndex(updatedPlacesPairs, previousRoutesPairs[i][0], previousRoutesPairs[i+1][1])}`)
        if (previousRoutesPairs[i][1] === previousRoutesPairs[i+1][0]
            && findPairIndex(updatedPlacesPairs, previousRoutesPairs[i][0], previousRoutesPairs[i+1][1]) >= 0
        ){
          // console.log(`+ found two connected routes to merge into ${previousRoutesPairs[i][0]} - ${previousRoutesPairs[i+1][1]}`)

          const newDraggableId = getMaxDraggableId(draggableList) + newRoutesCounter
          const route = {
            "draggable_type": "route",
            "draggable_id": newDraggableId,
            "draggable_parent_id": null,
            "origin_draggable_id": previousRoutesPairs[i][0],
            "destination_draggable_id": previousRoutesPairs[i+1][1],
            "type": this._defineNewRouteType(matchedRoutes, previousRoutesPairs[i][0], previousRoutesPairs[i+1][1]),
            "geometry": [],
            "images": [],
            "children": [...findRoute(draggableList, previousRoutesPairs[i][0], previousRoutesPairs[i][1]).children, ...findRoute(draggableList, previousRoutesPairs[i+1][0], previousRoutesPairs[i+1][1]).children],
            "updated": false,
            "show_coordinates_viapoints": true
          }
          route.children.forEach((child) => {
            child.draggable_parent_id = newDraggableId
          })

          matchedRoutes.push(route)
          newRoutesCounter++
          updatedPlacesPairs.splice(findPairIndex(updatedPlacesPairs, route.origin_draggable_id, route.destination_draggable_id), 1)
        }
      }

      // insert new routes
      updatedPlacesPairs.forEach((pair) => {
        const route = {
          "draggable_type": "route",
          "draggable_id": getMaxDraggableId(draggableList) + newRoutesCounter,
          "draggable_parent_id": null,
          "origin_draggable_id": pair[0].draggable_id,
          "destination_draggable_id": pair[1].draggable_id,
          "type": this._defineNewRouteType(matchedRoutes, pair[0].draggable_id, pair[1].draggable_id),
          "geometry": [],
          "children": [],
          "images": [],
          "updated": false,
          "show_coordinates_viapoints": true
        }
        matchedRoutes.push(route)
        newRoutesCounter++
      })

      // TODO: merge nested children for place / route
      // if (i < draggableList.length - 1) {
      //
      //   if (draggableList[i].draggable_type === "route" && draggableList[i + 1].draggable_type  === "route") {
      //     console.log(`two routes in a row: merging children`)
      //     // merge routes children
      //     updatedList[updatedList.length - 1].children.push(...draggableList[i + 1].children)
      //     i++;
      //   }
      // }

      return matchedRoutes
    },


    createMap() {
      mapboxgl.accessToken = this.accessToken
      return new Promise(resolve => {
        this.map = new mapboxgl.Map({
          container: 'trips-map', // container id,
          style: this.mapStyle,
          center: [15, 46],
          zoom: 3,
          pitchWithRotate: false
        });
        this.mapCursor = this.map.getCanvasContainer();
        this.popup = new mapboxgl.Popup()
        this.popup.on('close', () => {
          this.closePopUp(false)
        });

        // set the trigger to true is the user changed zoom level manually
        this.map.on('zoomstart', (e) => {
          this.mapZoomTriggered = true
        })

        // hoverLayerEvent(this.map, this.mapCursor, countriesLayer.id, this.hoveredCountry, this.mapPlaces.value)
        hoverLayerEvent(this.map, this.mapCursor, routesLayer.id, this.hoveredRoute, this.mapRoutes.value)
        hoverLayerEvent(this.map, this.mapCursor, circlesLayer.id, this.hoveredPlace, this.mapPlaces.value)
        hoverLayerEvent(this.map, this.mapCursor, popUpOptionsLayer.id, this.hoveredGeocoderPlace, this.geocoderPlaces.value, this.popup, this.generatePlacePopUpContent)
        hoverLayerEvent(this.map, this.mapCursor, viaPointsLayer.id, this.hoveredPoint, this.mapChildren)

        // add via point to the route
        this.map.on('click', routesLayer.id, (e) => {
          // enabled only in edit mode
          if (useAppStore().isEditMode) {
            e.clickOnLayer = true;
            this.handleRouteChildAddedOrMoved(
                {lat: e.lngLat.lat, lon: e.lngLat.lng, source: "coordinates", alpha_2: null},
                null,
                e.features[0].properties.draggable_id
            ).then((viapointUpdated) => {
              this.hoveredPoint = [viapointUpdated.draggable_id]
            })
          }
        })

        // select place option by click on the map
        this.map.on('click', popUpOptionsLayer.id, (e) => {
          // enabled only in edit mode
          if (useAppStore().isEditMode) {
            e.clickOnLayer = true;
            const newPlace = findItem(this.geocoderPlaces.value, e.features[0].properties.draggable_id)
            if (this.popUpIsViapoint) {
              // update route waypoint
              // console.log(`on click options layer: waypoint = ${JSON.stringify(e.features[0])}`)
              this.handleRouteChildAddedOrMoved(
                  newPlace,
                  this.popUpPlace.draggable_id,
                  this.popUpPlace.draggable_parent_id
              )
            } else {
              // add or update place
              // console.log(`on click options layer: place = ${JSON.stringify(e.features[0])}`)
              this.addPlaceFromPopup(newPlace, this.popUpPlace)
            }
          }
        })


        // drag and drop places
        this.map.on('mousedown', circlesLayer.id, (e) => {
          // interactive map is enabled only in edit mode
          if (useAppStore().isEditMode) {
            // this.closePopUp() // close pop up in case via point is moved and the prev pop up is still displayed
            this.pointDragged = e.features[0]
            this.pointDraggedMouseUp = false

            // Prevent the default map drag behavior.
            e.preventDefault();
            this.mapCursor.style.cursor = 'grab';

            this.map.on('mousemove', this.onMove);
            this.map.once('mouseup', this.onUp);
          }
        });

        // drag and drop via points
        this.map.on('mousedown', viaPointsLayer.id, (e) => {
          // interactive map is enabled only in edit mode
          if (useAppStore().isEditMode) {
            // this.closePopUp() // close pop up in case via point is moved and the prev pop up is still displayed
            this.pointDragged = e.features[0]
            this.pointDraggedMouseUp = false

            // Prevent the default map drag behavior.
            e.preventDefault();
            this.mapCursor.style.cursor = 'grab';

            this.map.on('mousemove', this.onMove);
            this.map.once('mouseup', this.onUp);
          }
        });

        // edit via points
        this.map.on('contextmenu', viaPointsLayer.id, (e) => {
          // enabled only in edit mode
          if (useAppStore().isEditMode) {
            this.hoveredPoint.splice(0, 1, ...[e.features[0].properties.draggable_id])
            this.pointDragged = null
              if (this.hoveredPoint[0]) {
                const place = findItem(this.tripItemsList, this.hoveredPoint[0])
                this._displayPlacePopup(place, place)
              }
          }
        });

        this.map.on('contextmenu', circlesLayer.id, (e) => {
          // enabled only in edit mode
          if (useAppStore().isEditMode) {
            this.hoveredPoint.splice(0, 1, ...[e.features[0].properties.draggable_id])
            this.pointDragged = null // this prevents duplicated pop up on mouse up
            if (this.hoveredPoint) {
              const place = findItem(this.tripItemsList, this.hoveredPoint[0])
              this._displayPlacePopup(place, place, false)
            }
          }
        });

        // add place
        this.map.on('click', (e) => {
          // TODO: identify the best approach to limit clicks on layer
          // let features = this.map.queryRenderedFeatures(e.point);
          // if (features.length > 0) {
          //   let clickedLayer = features[0].layer;
          //   if ([routesLayer.id, viaPointsLayer.id].includes(clickedLayer.id)) {
          //     return;
          //   }
          // }
          // disable if clicked on routes layer to add via-point
          if (e.clickOnLayer) {
            return;
          }

          // interactive map is enabled only in edit mode
          if (useAppStore().isEditMode) {
            this._displayPlacePopup({lat: e.lngLat.lat, lon: e.lngLat.lng}, null, false)
          }
        });

        // load map with all set up
        this.map.on('load', () =>  {
          this.map.addLayer(countriesLayer);
          this.map.addLayer(routesLayer);
          this.map.addLayer(viaPointsLayer);
          this.map.addLayer(popUpOptionsLayer);
          this.map.addLayer(circlesLayer);

          this.map.addControl(new mapboxgl.NavigationControl());
          resolve()
        });
      })
    },
    splitList() {
      this.mapRoutes.value.splice(0, this.mapRoutes.value.length, ...this.tripItemsList.filter(item => item.draggable_type === "route"))
      this.mapPlaces.value.splice(0, this.mapPlaces.value.length, ...this.tripItemsList.filter(item => item.draggable_type === "place"))
    },
    fetchData() {
      if (this.$route.name === 'TripCreate' || this.$route.name === 'Friends') {
        this.isTripsDataLoaded = true
        this.getRequestedUserData(useAuthStore().userId).then(user => {
          this.tripData.mates = [{
            id: -1,
            index: -1,
            alias: null,
            linked_user_id: user.id,
            name: user.name,
            avatar: user.avatar
          }]
        })
      }
      if (this.$route.name === 'TripDetails') {
        this.getTripPlaces(this.$route.params.trip_id)
      }
      if (this.$route.name === 'TripsDashboard') {
        this.getUserTrips()
        // MediaService.clean().then((response) => {
        //   const removedItems = response.data
        //   if (removedItems > 0) {
        //     console.log(`[INFO] Removed not linked media files from storage: ${removedItems}`)
        //   }
        // })
      }
      if (this.isWishlist) {
        this.getUserWishlist()
      }
      if (this.$route.name === 'UserTrips') {
        this.getUserTrips(this.$route.params.user_id)
      }
    },
    resetZoom() {
      setMapCenterAndZoom(this.map, updateLayer(this.map, circlesLayer.id, this.mapPlaces.value.filter((place) => place.type !== "country"), false))
      this.adjustMapCenterAndZoom = false
      this.mapZoomTriggered = false
    },
    showMapConfig() {
      this.mapShowConfig = !this.mapShowConfig
    }
  },
  computed: {
    isWishlist() {
      return this.$route.name === 'Wishlist'
    }
  },
  created: function () {
    this.$nextTick(function () {
      this.createMap().then(() => {
        this.fetchData()
        // TODO: do we need to execute it here?
        try { // TODO: this is workaround to avoid failures of map update while data is not loaded. need to avoid this cases
          updateCountriesLayer(this.map, !this.isWishlist ? this.mapPlaces.value : [], !this.isWishlist ? this.mapRoutes.value : [])
          updateLayer(this.map, circlesLayer.id, this.mapPlaces.value.filter((place) => place.type !== "country"))
        } catch (error) {
          console.log(`failed to update map layers: ${error}`)
        }
      })
    })
  },
  watch: {
    $route(to, from) {
      this.isTripsDataLoaded = false
      this.adjustMapCenterAndZoom = true
      this.fetchData();
    },
    tripItemsList: {
      handler() {
        // console.log("==== tripItemsList updated =====")
        // console.log(JSON.stringify(this.tripItemsList))
        this.splitList()
      },
      deep: true
    },
    mapPlaces: {
      handler() {
        // console.log(`Map Template: places watch handler`)
        if (useAppStore().isEditMode) {
          if (this.tripsList.length == 0) {
            this.tripsList[0] = {"locations": [], routes: []} // for the case when the new trip is created
            this.isTripsDataLoaded = true
          }
          this.tripsList[0].locations = this.mapPlaces.value
        }
        try { // TODO: this is workaround to avoid failures of map update while data is not loaded. need to avoid this cases
          updateCountriesLayer(this.map, !this.isWishlist ? this.mapPlaces.value : [], !this.isWishlist ? this.mapRoutes.value : [])
          const placesFeatures = updateLayer(this.map, circlesLayer.id, this.mapPlaces.value.filter((place) => place.type !== "country"))

          if (this.adjustMapCenterAndZoomPlaces && !this.mapZoomTriggered) {
            console.log("set map center on places updated")
            setMapCenterAndZoom(this.map, placesFeatures)
            this.adjustMapCenterAndZoomPlaces = false
            this.mapZoomTriggered = false
          }
        } catch (error) {
          console.log(`Map Places Watcher: failed to update map layers: ${error}`)
        }
      },
      deep: true
    },
    mapRoutes: {
      handler() {
        // console.log(`Map Template: list of routes was changed`)
        const filteredRoutes = this.mapRoutes.value.filter(route => this.mapRouteTypesSelected.includes(route.type))
        const routesFeatures = updateLayer(this.map, routesLayer.id, filteredRoutes)
        if (filteredRoutes.length > 0) { // TODO: investigate why routes updated twice on page reload
          this.mapChildren.splice(
              0,
              this.mapChildren.length,
              ...filteredRoutes.flatMap(route => route.type !== "empty" ? route.children : []).concat(this.mapPlaces.value.flatMap(place => place.children))
          )
          updateCountriesLayer(this.map, !this.isWishlist ? this.mapPlaces.value : [], !this.isWishlist ? this.mapRoutes.value : [])
          updateLayer(this.map, viaPointsLayer.id, this.mapChildren)

          if (this.adjustMapCenterAndZoom && !this.mapZoomTriggered) {
            console.log("set map center on routes updated")
            setMapCenterAndZoom(this.map, routesFeatures)
            this.adjustMapCenterAndZoom = false
            this.mapZoomTriggered = false
          }
        }
      },
      deep: true
    },
    tripsList: {
      handler() {
        // console.log(`Trips List: list of trips was changed`)
        const focusedTripPresent = this.tripsList.some(trip => trip.focused)

        console.log(`----------> trips list processing started`)
        if (!useAppStore().isEditMode) {
          let routesToDisplay = []
          let placesToDisplay = []
          this.tripsList.map(trip => {
            if ((!focusedTripPresent && trip.completed) || trip.focused) {
              routesToDisplay.push(trip.items.filter(item => item.draggable_type === "route").map(item => {return {...item, trip_id: trip.id};}))
              placesToDisplay.push(trip.items.filter(item => item.draggable_type === "place").map(item => {return {...item, trip_id: trip.id};}))
            }
          })
          this.mapRoutes.value.splice(0, this.mapRoutes.value.length, ...[].concat(...routesToDisplay))
          this.mapPlaces.value.splice(0, this.mapPlaces.value.length, ...[].concat(...placesToDisplay))
          console.log(`----------> trips list processing finished`)
          updateLayer(this.map, viaPointsLayer.id, [])
        }
      },
      deep: true
    }
  }
};
</script>

<style>
.map-container {
  height: calc(100% + 20px);
  width: 100%;
  min-height: 480px;
  min-width: 640px;
  position: relative;
}

.trips-map-custom-controls {
  background-color: #ffffff;
  width: 29px;
  height: 29px;
  position: absolute;
  top: 110px;
  right: 0;
  margin-right: 10px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
  border-radius: 4px;
  z-index: 999;
  cursor:pointer;
}

.trips-map-custom-controls.config {
  top: 149px;
}

.trips-map-custom-controls.config.displayed {
  color: var(--el-color-primary)
}

.trips-map-custom-controls .filter-badge {
  vertical-align: unset;
}


.trips-map-custom-controls:hover {
  background-color: rgba(242, 242, 242);
}

.trips-map-custom-controls .icon.material-design-icon .material-design-icon__svg {
  width: 21px;
  height: 21px;
  top: 2px;
  left: 4px;
}

.content-container {
  min-width: 640px;
}
.mapboxgl-popup-content {
  padding: 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 12px 0px;
}
.mapboxgl-ctrl-logo{
  display: none !important;
}
</style>
