export const routeTypes = [
    { value: 'empty', label: 'empty', icon: 'ufo-outline-icon'},
    { value: 'direct', label: 'direct', icon: 'transit-connection-variant-icon'},
    { value: 'flight', label: 'flight', icon: 'airplane-icon'},
    { value: 'driving', label: 'driving', icon: 'car-hatchback-icon'},
    { value: 'walking', label: 'walking', icon: 'hiking-icon'},
    { value: 'train', label: 'train', icon: 'train-icon'},
    { value: 'bus', label: 'bus', icon: 'bus-icon'},
    { value: 'ferry', label: 'ferry', icon: 'ferry-icon'},
    { value: 'hitchhiking', label: 'hitchhiking', icon: 'hail-icon'},
]

export function getRouteIcon(route) {
    const findRouteIcon = routeTypes.find(item => item.value === route.type)
    if (findRouteIcon) {
        return findRouteIcon.icon
    }
    return 'ufo-outline-icon'
}