<template>
  <map-template>
    <template v-slot:content="{ map, mapPlaces, mapRoutes, addPlaceToList, handlePlaceRemoved, handleItemDateUpdate, handleItemImagesUpdated, handleItemRenamed, tripData, tripItemsList, isTripsDataLoaded, hoveredRoute, hoveredPlace, hoveredChild, hoveredCountry, handleRouteTypeUpdate, handlePlaceTypeUpdate, handleListOrderUpdate, handleListItemHovered, user }">
      <trip-details
          :map="map"
          :mapPlaces="mapPlaces"
          :mapRoutes="mapRoutes"
          :addPlaceToList="addPlaceToList"
          :handlePlaceRemoved="handlePlaceRemoved"
          :handleItemImagesUpdated="handleItemImagesUpdated"
          :handleItemRenamed="handleItemRenamed"
          :tripData="tripData"
          :tripItemsList="tripItemsList"
          :isTripsDataLoaded="isTripsDataLoaded"
          :hoveredRoute="hoveredRoute"
          :hoveredPlace="hoveredPlace"
          :hoveredCountry="hoveredCountry"
          :hoveredChild="hoveredChild"
          :handleRouteTypeUpdate="handleRouteTypeUpdate"
          :handlePlaceTypeUpdate="handlePlaceTypeUpdate"
          :handleListOrderUpdate="handleListOrderUpdate"
          :handleListItemHovered="handleListItemHovered"
          :handleItemDateUpdate="handleItemDateUpdate"
          :user="user"
      />
    </template>
  </map-template>
</template>

<script>
import MapTemplate from "@/components/MapTemplate";
import TripDetails from "@/components/TripDetails";

export default {
  name: 'TripsPage',
  components: {
    MapTemplate,
    TripDetails
  },
  props: ["map", "mapPlaces", "mapRoutes", "addPlaceToList", "tripData", "tripItemsList", "isTripsDataLoaded", "handleItemDateUpdate", "handlePlaceRemoved", "handleItemImagesUpdated", "handleItemRenamed", "hoveredRoute", "hoveredPlace", "hoveredChild", "hoveredCountry", "handleRouteTypeUpdate", "handleListOrderUpdate", "handlePlaceTypeUpdate", "handleListItemHovered", "user"]
};
</script>
