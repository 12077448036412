<template>
  <el-select
      v-model="selectedOptions"
      value-key="index"
      multiple
      filterable
      allow-create
      :reserve-keyword="false"
      :placeholder="placeholder"
      class="input-trip-tags"
      :fit-input-width="false"
      :suffix-icon="null"
      :disabled="!appStore.isOwnTrip"
      @change="handleUpdates"
  >
    <el-option v-for="(item, index) in currentOptionsList"
               :key="index"
               :value="item">
      <template #default>
        <slot name="dropdown-option" :item="item"></slot>
      </template>
    </el-option>

    <!--              TODO: decide whether use built-in tags & collapsing, or custom with prettier UI-->
    <!--              collapse-tags-->
    <!--              :max-collapse-tags="3"-->
    <template #tag>
      <el-tag
          v-for="(item, index) in selectedOptions"
          :key="index"
          :round="true"
          effect="light"
          :closable="appStore.isOwnTrip && !constItems.includes(item)"
          @close="() => {selectedOptions.splice(index, 1); handleUpdates()}"
      >
        <slot name="selected-option" :item="item"></slot>
      </el-tag>
    </template>
  </el-select>
</template>

<script setup>
import 'vue-material-design-icons/styles.css';
import {useAppStore} from "@/stores";
const appStore = useAppStore()
</script>

<script>
import {useAppStore} from "@/stores";
import {ElMessage} from "element-plus";

export default {
  name: "Sample",
  props: {
    originList: {type: Array},
    requestUserOptionsFunction: {type: Function},
    placeholder: {type: String},
    constItems: {type: Array, required: false, default: []}
  },
  data() {
    return {
      selectedOptions: this.originList,
      userStoredOptions: [],
      currentOptionsList: []
    }
  },
  methods: {
    requestUserOptions() {
      if (this.requestUserOptionsFunction) {
        this.requestUserOptionsFunction()
            .then(response => {
              this.userStoredOptions = response.data
              this.currentOptionsList = response.data
            })
            .catch(e => {
              console.log(e);
            });
      }
    },
    handleUpdates(updatedOptionsList) {
      const listOptionType = typeof this.currentOptionsList[0]

      let newOption
      if (listOptionType === 'object') {
        newOption = this.selectedOptions.filter(item => !this.currentOptionsList.some(obj2 => obj2.id === item.id) && !this.constItems.includes(item))[0];
      } else {
        newOption = this.selectedOptions.filter(item => !this.currentOptionsList.includes(item) && !this.constItems.includes(item))[0];
      }

      if (newOption !== undefined && !this.constItems.includes(newOption)) {
        // validate new option text
        if (typeof newOption === 'string' && newOption.replaceAll(" ", "") === "") {
          ElMessage({
            message: "New option cannot be empty",
            type: "error",
            showClose: true
          });
          this.selectedOptions.pop();
          return
        }

        // transform in object
        // TODO: possibly should be a prop function
        let transformedOption = newOption
        if (listOptionType === 'object') {
          const maxObjectId = this.currentOptionsList.length > 0
              ? this.currentOptionsList.reduce((max, obj) => (obj.id > max.id ? obj : max), this.currentOptionsList[0]).id + 1
              : 1;
          transformedOption = {index: maxObjectId, id: null, user_id: null, name: newOption, avatar: null, trips_together: 0}
        }

        // replace selected option with the transformed object
        this.selectedOptions.splice(0, this.selectedOptions.length, ...this.selectedOptions.filter(item  => item !== newOption))
        this.selectedOptions.push(transformedOption)

        // update the list of options
        this.currentOptionsList.push(transformedOption)
      }

      this.$emit('selected-options-updated', this.selectedOptions);
    }
  },
  created() {
    this.requestUserOptions()
  },
  watch: {
    originList() {
      this.selectedOptions.splice(0, this.selectedOptions.length, ...this.originList)
    }
  }
};
</script>

<style scope>
.input-trip-tags .el-select__wrapper.is-focused, .input-trip-tags .el-select__wrapper, .input-trip-tags .el-select__wrapper.is-hovering:not(.is-focused) {
  box-shadow: none;
  padding-left: 0;
}

.input-trip-tags .el-select__wrapper.is-disabled
{
  box-shadow: none;
  background:none;
}

.input-trip-tags .el-select__wrapper.is-disabled .el-select__caret,
.input-trip-tags .el-select__wrapper.is-disabled .el-tag,
.input-trip-tags .el-select__wrapper.is-disabled {
  cursor: default;
}

.el-select.input-trip-tags .el-select__selection.is-near {
  margin: 0;
}

.input-trip-tags.el-select {
  min-width: 280px;
  max-width: 470px;
}
</style>
