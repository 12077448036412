<template>
  <div class="trips-map-layers-controls-container">
    <el-checkbox-group
        v-model="routeTypesSelectedLocal"
        @change="filterMapLayers(routeTypesSelectedLocal)"
        class="trips-map-layers-controls"
    >
      <el-checkbox v-for="route in routeTypesList" :key="route" :label="route" :value="route">
        {{ route }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
import {routeTypes} from "@/map/routeTypes";

export default {
  name: "MapLayersControl",
  props: ['filterMapLayers', 'routeTypesSelected'],
  data() {
    return {
      routeTypesList: routeTypes.map(route => route.label).filter(route => route !== 'empty'),
      routeTypesSelectedLocal: this.routeTypesSelected
    }
  },
  watch: {
    routeTypesSelected : {
      handler() {
        this.routeTypesSelectedLocal = this.routeTypesSelected
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.trips-map-layers-controls-container {
  background-color: #ffffff;
  width: 500px;
  padding: 0 10px 0;
  position: absolute;
  bottom: 10px;
  right: 0;
  margin-right: 10px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
  border-radius: 4px;
  z-index: 999;
  cursor:pointer;
}

.trips-map-layers-controls {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Replace N with the number of columns */
}
</style>

