<template>
  <div class="avatar-container" :class="[{ 'border': border }]">
    <el-image v-if="avatar" class="avatar" :class="[{ 'border': border }]" :src="avatar">
      <template #error>
        <div class="avatar generated"  :class="[{ 'border': border }]">{{ generatedLetter }}</div>
      </template>
    </el-image>
    <div v-else class="avatar generated"  :class="[{ 'border': border }]">{{ generatedLetter }}</div>
  </div>
</template>

<script>
export default {
  props: ['user', 'size', 'border'],
  data() {
    return {
      avatar: this.user ? this.user.avatar : null
    }
  },
  computed: {
    generatedLetter() {
      if (this.user && this.user !== undefined && (this.user.alias || this.user.name)) {
        const letter = (this.user.alias ? this.user.alias : this.user.name).charAt(0).toUpperCase()
        return letter
      }
      return ""
    },
    containerHeight() {
      if (this.border) {
        return "20px" // +4px
      }
      return this.avatarHeight
    },
    avatarHeight() {
      if (this.size === "small") {
        return "16px"
      }
      return "32px"
    },
    generatedLetterSize() {
      if (this.size === "small") {
        return "12px"
      }
      return "18px"
    },
    generatedBackground() {
      if (this.user && this.user.avatar_color) {
        return this.user.avatar_color
      } else {
        const avatarBackgroundColorOptions = ["#8cb369", "#f4e285", "#f4a259", "#5b8e7d", "#bc4b51", "#5dd39e", "#348aa7", "#525174", "#513b56"]
        const randomIndex = Math.floor(Math.random() * avatarBackgroundColorOptions.length);
        return avatarBackgroundColorOptions[randomIndex];
      }
    },
  },
  watch: {
    user() {
      this.avatar = this.user.avatar
    }
  }
};
</script>

<style>

.avatar-container {
  height: v-bind(containerHeight);
}

.avatar-container.border {
  padding-top: 2px;
}

.avatar {
  width: v-bind(avatarHeight);
  height:v-bind(avatarHeight);
  border-radius: 50%;
  /*margin-left: 10px;*/
  min-width: v-bind(avatarHeight);
}
.avatar.border {
  outline: 2px white solid;
}

.generated {
  background-color: v-bind(generatedBackground);
  line-height: v-bind(avatarHeight);
  text-align: center;
  color: white;
  font-size: v-bind(generatedLetterSize);
}
</style>
