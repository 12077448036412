import {hoverMapCountry, hoverMapFeature} from "@/map/hoverMapFeature";
import {countriesLayer, viaPointsLayer} from "@/map/layers";
import {useAppStore} from "@/stores";

export function hoverLayerEvent(mapInstance, mapCursorInstance, layer, hoveredIds, collection, popUp = null, generatePlacePopUpContent) {
    if (useAppStore().isEditMode) {
        mapInstance.on('mouseenter', layer, (e) => {
            if (e.features.length > 0) {
                mapCursorInstance.style.cursor = 'pointer';
                if (layer === countriesLayer.id) {
                    hoverMapCountry(mapInstance, layer, hoveredIds, e.features[0], collection)
                } else {
                    hoverMapFeature(mapInstance, layer, hoveredIds, e.features[0], collection)
                }
                // update non-reactive mapbox pop up content
                if (popUp && popUp.isOpen()) {
                    console.log(`updating DOM: ${hoveredIds}`)
                    popUp.setDOMContent(generatePlacePopUpContent())
                }
            }
        })
    }

    // mapInstance.on('mousemove', function(e) {
    //     const features = mapInstance.queryRenderedFeatures(e.point);
    //     // Check if there are any features under the mouse cursor
    //     if (features.length > 0) {
    //         // Loop through each feature
    //         console.log(`=== hovered layer: ${features[0].layer.id}`)
    //         features.forEach(feature => {
    //             // Check if the feature is the one you're interested in
    //             if (feature.layer.id === countriesLayer.id) {
    //                 // Your logic for when the mouse enters the feature
    //
    //                 if (hoveredIds.id !== feature.id) {
    //                     // replace hovered feature
    //                     console.log("replace")
    //                     const prevHoveredId = hoveredIds
    //                     hoveredIds = {id: feature.id, alpha_2: feature.properties.iso_3166_1.toLowerCase()}
    //                     // hoverMapCountry(mapInstance, layer, hoveredIds, feature, collection)
    //                 }
    //             }
    //         });
    //     } else {
    //         hoveredIds = null
    //     }
    // });

    // unhover previously hovered route feature
    mapInstance.on('mouseleave', layer, () => {
        mapCursorInstance.style.cursor = '';
        if (layer === countriesLayer.id) {
            hoverMapCountry(mapInstance, layer, hoveredIds, null, collection, false)
        } else {
            hoverMapFeature(mapInstance, layer, hoveredIds, null, collection, false)
        }

        // TODO: this depends on point / viapoint
        // if (popUp && popUp.isOpen()) {
        //     popUp.setDOMContent(generatePopUpContent())
        // }
    });
}
//
// const sendEvent = debounce(function(event) {
//     console.log('Event sent:', event);
// }, 100); // Cooldown period of 100 millisecond
//
// function debounce(func, delay) {
//     let timeoutId;
//
//     return function() {
//         clearTimeout(timeoutId);
//         const context = this;
//         const args = arguments;
//         timeoutId = setTimeout(() => {
//             func.apply(context, args);
//         }, delay);
//     };
// }