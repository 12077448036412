<template>
  <div
      class="item-date-picker-container"
      :class="[{ 'date-present': !_isDateEmpty, 'is-focused': isFocused, 'shared': !appStore.isOwnTrip }]"
  >
    <el-date-picker
        :modelValue="date"
        @update:modelValue="updateItemDate($event)"
        @focus="isFocused = true"
        @blur="isFocused = false"
        type="date"
        start-placeholder="select trip dates"
        end-placeholder=""
        value-format="YYYY-MM-DD"
        class="item-input-datepicker"
        :class="[{ 'has-value': date }]"
        format="MMM D"
        clear-icon="none"
        :readonly="!appStore.isOwnTrip"
        :prefix-icon="CalendarMonthOutlineIcon"
        :teleported="false"
        :disabled-date="disabledDatesInterval"
        :default-value="defaultDate"
    />
  </div>
</template>

<script setup>
import {useAppStore} from "@/stores";
import CalendarMonthOutlineIcon from "vue-material-design-icons/CalendarMonthOutline.vue";
const appStore = useAppStore()
</script>

<script scoped>
import {Close} from "@element-plus/icons-vue";

export default {
  name: "ItemDatePicker",
  computed: {
    Close() {
      return Close
    },
    _isDateEmpty() {
      return !this.date || this.date === ''
    },
    _tripDatesIntervalPresent() {
      return this.tripDatesInterval && this.tripDatesInterval[1]
    },
    defaultDate() {
      if (this._tripDatesIntervalPresent) {
        return new Date(this.tripDatesInterval[0]);
      }
      return new Date();
    },
  },
  props: ['date', 'tripDatesInterval'],
  data() {
    return {
      isFocused: false,
    }
  },
  methods: {
    updateItemDate(newDate) {
      this.$emit('item-date-updated-event', newDate);
    },
    disabledDatesInterval(calendarDate) {
      if (this._tripDatesIntervalPresent) {
        const startDate = new Date(this.tripDatesInterval[0]);
        const finishDate = new Date(this.tripDatesInterval[1]);

        // Set the time of both dates to midnight to only compare the date part
        startDate.setHours(0, 0, 0, 0);
        finishDate.setHours(0, 0, 0, 0);
        return !(startDate.getTime() <= calendarDate.getTime() && calendarDate.getTime() <= finishDate.getTime());
      }
      // console.log('===> default disabled date')
      return false
    },
  }
};
</script>

<style>
.item-input-datepicker .el-input__wrapper,
.item-input-datepicker .el-input__wrapper:hover,
.item-input-datepicker .el-date-editor.el-input__wrapper,
.item-input-datepicker .el-date-editor.is-active:hover,
.item-input-datepicker .el-date-editor.el-input__wrapper:hover,
.item-input-datepicker .el-input__wrapper.is-focus
{
  box-shadow: none;
  padding: 0;
  background: none;
}

.item-input-datepicker.el-date-editor.el-input, .item-input-datepicker.el-date-editor.el-input__wrapper {
  height: 26px;
  width: 64px;
}

.item-date-picker-container.el-input, .item-date-picker-container .el-input__inner {
  width: 42px;
  padding: 3px 0 0 0;
  line-height: 22px;
  color: #999999;
  font-weight: bolder;
  font-size: 12px;
  height: 26px;
}

.el-icon.el-input__icon.el-range__icon {
  width: 0px;
}

.item-input-datepicker.el-date-editor .el-range-input, .item-input-datepicker.el-date-editor .el-range-separator {
  text-align: left;
  justify-content: left;
}


.item-date-picker-container:not(.date-present):not(.is-focused) .material-design-icon .material-design-icon__svg {
  display: none;
}

.item-date-picker-container.date-present:not(.shared) .material-design-icon .material-design-icon__svg,
.item-date-picker-container.is-focused:not(.shared) .material-design-icon .material-design-icon__svg
{
  display: block;
}

.item-date-picker-container.shared .el-input__wrapper, .item-date-picker-container.shared .el-input__inner {
  cursor: default;
}
</style>