import http from "../http-common";
import TripsDataService from "@/services/TripsDataService";

class UserDataService {
    getCurrentData() {
        return http.get("/user");
    }
    updateUserData(user_data) {
        return http.put(`/user`, user_data);
    }
    getUserData(user_id) {
        return http.get(`/user/${user_id}`);
    }
    getUserTrips(user_id, filters=null) {
        let query = ""

        if (filters) {
            query = TripsDataService._transformFiltersQuery(query, filters)
        }

        return http.get(`/user/${user_id}/trips${query === "" ? "" : `?${query}`}`);
    }
    getUsers() {
        return http.get("/user/list");
    }
    getUserTripMates(enriched=true) {
        return http.get(`/user/mates${enriched ? '?enriched=true' : ''}`);
    }
    linkMatesToUsers(mateId, linkedToUserId) {
        return http.post(`/user/link-mate`, {mate_id: mateId, linked_user_id: linkedToUserId == -1 ? null : linkedToUserId});
    }
    updateMate(mate) {
        return http.put(`/user/mates`, mate);
    }
    getFilters(user_id=null) {
        const url = `/user/${user_id ? `${user_id}/` : ""}filters`
        return http.get(url);
    }
}

export default new UserDataService();