<template>
  <el-dropdown
      trigger="click"
      class="table-row-menu"
  >
    <context-menu :isChild="false"/>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click="acceptTrip" v-if="isSharedTrip">
          <div>
            <plus-icon/>
            <span style="padding-left: 10px;">Accept and copy to my trips</span>
          </div>
        </el-dropdown-item>
        <el-dropdown-item @click="rejectItem" v-if="isSharedTrip">
          <div>
            <cancel-icon/>
            <span style="padding-left: 10px;">Reject and remove shared trip</span>
          </div>
        </el-dropdown-item>
        <el-dropdown-item @click="deleteItem">
          <div>
            <trash-can-outline-icon/>
            <span style="padding-left: 10px;">Delete</span>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
import {useAppStore} from "@/stores";
import ContextMenu from "@/components/List/ContextMenu.vue";
const appStore = useAppStore()
</script>

<script>
import ContextMenu from "@/components/List/ContextMenu.vue";
import WishlistService from "@/services/WishlistService";
import TripsDataService from "@/services/TripsDataService";
import {findRoute, getMaxDraggableId} from "@/core/ListHelper";
import {useAppStore, useAuthStore} from "@/stores";

export default {
  components: {
    ContextMenu
  },
  props: ['item', 'delete', 'isSharedTrip'],
  data() {
    return {
    }
  },
  methods: {
    deleteItem() {
      this.delete(this.item.id)
    },
    acceptTrip() {
      TripsDataService.accept(this.item.id).then(() => {
        this.$emit('shared-trip-accepted-event');
      })
    },
    rejectItem() {
      TripsDataService.reject(this.item.id).then(() => {
        this.$emit('shared-trip-rejected-event');
      })
    }
  }
};
</script>

<style scoped>
.table-row-menu.el-dropdown:focus-visible {
  outline: none
}
.table-row-menu .list-item-context-menu {
  background-color: #e7d6ff;
  background: none;
  display: inline-block;
}

.table-row-menu .list-item-context-menu:hover {
  background: none;
}

.table-row-menu .list-item-context-menu >>> .material-design-icon .material-design-icon__svg {
  height: 18px;
  width: 18px;
  left: -5px;
  color: var(--el-text-color-regular);
}
</style>
