<template>
  <div style="padding-left: 20px;">
    <div aria-label="Trip attributes section">

      <el-page-header @back="discardTrip()">
        <template #content>
          <el-form
              ref="formRef"
              :model="tripData"
              class="title-form"
          >
            <el-form-item
                v-if="appStore.isOwnTrip"
                prop="title"
                :rules="[
                    { required: true, message: 'trip title is required' }
                  ]"
            >
              <el-input
                  v-model="tripData.title"
                  class="input-trip-title"
                  style="width: 100%"
                  placeholder="Name your trip"
                  maxlength="50"
                  show-word-limit
              />
            </el-form-item>
            <div v-else class="input-trip-title" style="cursor: default; line-height: 32px;">{{ tripData.title }}</div>
          </el-form>
        </template>
        <template #extra>
          <div v-if="appStore.isOwnTrip" style="width: max-content;">
            <el-dropdown
                class="save-split-button"
                split-button
                type="primary"
                trigger="click"
                @click="submitForm()"
                v-model="tripData.completed"
                :disabled="isUploadingInProgress"
                v-loading="isSavingInProgress"
            >
              {{ tripData.completed ? 'Completed' : 'Draft'}}
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="tripData.completed = true; isDraftSelectedByUser = true; save()">
                    <template #default>
                      <div style="width: 30px;"><check-bold-icon v-if="tripData.completed" style="color: var(--el-color-primary)"/></div>
                      <div>
                        <el-text tag="b">Save as a completed trip</el-text>
                        <br>
                        <el-text size="small">The trip that is already completed and you've recorded<br> all details you want to remember or share</el-text>
                      </div>
                    </template>
                  </el-dropdown-item>
                  <el-dropdown-item @click="tripData.completed = false; isDraftSelectedByUser = true; save()">
                    <template #default>
                      <div style="width: 30px;"><check-bold-icon v-if="!tripData.completed" style="color: var(--el-color-primary)"/></div>
                      <div>
                        <el-text tag="b">Save as a draft trip</el-text>
                        <br>
                        <el-text size="small">Save the draft of the planned trip, or not completely filled trip</el-text>
                      </div>
                    </template>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <last-saved-indicator :lastSavedDate="lastSavedDate"/>
          </div>
          <div v-else style="min-width: 180px; line-height: 30px;">
            <user-info :user="user" size="big"/>
          </div>
        </template>
      </el-page-header>
    </div>

    <div v-loading="!isTripsDataLoaded" style="padding: 10px 0px 0px 100px" >
      <div class="trip-attributes-row" v-if="appStore.isOwnTrip || tripDates.filter(item => item !== null).length > 0">
        <div class="trip-attributes-label">
          <calendar-month-outline-icon class="icon"/>
          Dates
        </div>
        <div>
          <range-date-picker :datesInterval="tripDates" :setTripDates="setTripDates"/>
        </div>
        <div v-if="_routesPresent" style="margin-left: auto;">
          <el-text style="padding-right: 10px;" type="info" size="small">show routes:</el-text>
          <el-switch
              v-model="showRoutesToggle"
              size="small"
          />
        </div>
      </div>
      <div class="trip-attributes-row" v-if="appStore.isOwnTrip || (tripData.tags && tripData.tags.length > 0)">
        <div class="trip-attributes-label">
          <label-multiple-outline-icon class="icon"/>
          Tags
        </div>
        <div>
          <select-multiple-tags
              :originList="tripData.tags"
              :requestUserOptionsFunction="appStore.isOwnTrip ? TagsService.get : null"
              placeholder="choose or create tags for your trip"
              @selected-options-updated="handleTripTagsUpdated"
          >
            <template v-slot:dropdown-option="{ item }">
              <div>{{ item }}</div>
            </template>
            <template v-slot:selected-option="{ item }">
              <div>{{ item }}</div>
            </template>
          </select-multiple-tags>
        </div>
      </div>

      <div class="trip-attributes-row" v-if="appStore.isOwnTrip || (tripData.mates && tripData.mates.length > 0)">
        <div class="trip-attributes-label">
          <account-group-outline-icon class="icon"/>
          Group
        </div>
        <div>
          <select-multiple-tags
              :originList="tripData.mates"
              :requestUserOptionsFunction="appStore.isOwnTrip ? UserDataService.getUserTripMates : null"
              :constItems="tripData.mates.filter(mate => mate.linked_user_id === useAuthStore().userId)"
              placeholder="mention who travelled with you"
              @selected-options-updated="handleTripMatesUpdated"
          >
            <template v-slot:dropdown-option="{ item }">
              <div class="mate-dropdown-option">
                <user-info :user="item" size="small" :clickable="false"/>
                <div style="padding: 0 5px 0 5px;">
                  <el-text size="small" type="info">together: {{ item.trips_together }}</el-text>
                </div>
              </div>
            </template>
            <template v-slot:selected-option="{ item }">
              <user-info :user="item" size="small" :nameSuffix="item.linked_user_id === useAuthStore().userId ? ' (you)' : ''"/>
            </template>
          </select-multiple-tags>
        </div>
      </div>

      <div class="trip-attributes-row" v-if="tripData.source_trip_id">
        <div class="trip-attributes-label">
          <source-branch-icon class="icon"/>
          Source trip
        </div>
        <div style="line-height: 32px;">
          <el-tag
              @click="$router.push(`/trips/${tripData.source_trip_id}`)"
              effect="plain"
              type="info"
              round
              style="cursor: pointer;"
          >
            <div style="display: flex">
              <user-info :user="tripData.source_trip_owner" size="small"/>
              <span style="line-height: 16px; vertical-align: center;">: {{ tripData.source_trip_title }}</span>
            </div>
          </el-tag>
        </div>
      </div>

      <div class="trip-attributes-row" v-if="appStore.isOwnTrip">
        <div class="trip-attributes-label">
          <map-marker-plus-outline-icon class="icon"/>
          Locations
        </div>
        <geocoder
            :map="map"
            :addPlaceToList="addPlaceToList"
        />
      </div>
      <el-divider style="margin: 5px 0;"/>
      <div class="trip-content-row" style="height: unset;">
        <el-scrollbar height="500px" ref="timelineScrollbar">
          <draggable-list
              :draggableList="tripItemsList.filter(item => showRoutesToggle || (item.draggable_type !== 'route' && !showRoutesToggle))"
              :tripDatesInterval="tripDates"
              :hoveredRoute="hoveredRoute"
              :hoveredPlace="hoveredPlace"
              :hoveredChild="hoveredChild"
              @route-type-updated-event="routeTypeUpdateHandler"
              @place-type-updated-event="placeTypeUpdateHandler"
              @place-removed-event="handlePlaceRemoved"
              @place-duplicate-event="handlePlaceDuplicate"
              @item-renamed-event="handleItemRenamed"
              @item-date-updated-event="handleItemDateUpdated"
              @item-added-to-wishlist="addItemToWishlist"
              @item-images-updated-event="handleItemImagesUpdated"
              @list-order-updated-event="handleListOrderUpdate"
              @list-item-hovered-event="handleListItemHovered"
              @list-item-media-uploading-state-event="handleUploadingState"
          />
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script setup>
import 'vue-material-design-icons/styles.css';
import {useAppStore, useAuthStore} from "@/stores";
import TagsService from "@/services/TagsService";
import UserDataService from "@/services/UserDataService";
const appStore = useAppStore()
</script>

<script>
import TripsDataService from "@/services/TripsDataService";
import Geocoder from "@/components/Geocoder";
import DraggableList from "@/components/List/DraggableList"
import RangeDatePicker from "@/components/Core/Form/RangeDatePicker"
import UserInfo from "@/components/Core/User/UserInfo.vue";
import {useAppStore} from "@/stores";
import WishlistService from "@/services/WishlistService";
import SelectMultipleTags from "@/components/Core/Form/SelectMultipleTags.vue";
import UserAvatar from "@/components/Core/User/UserAvatar.vue";
import LastSavedIndicator from "@/components/Core/Form/LastSavedIndicator.vue";

export default {
  name: "Sample",
  components: {
    Geocoder,
    RangeDatePicker,
    DraggableList,
    UserInfo,
    SelectMultipleTags,
    UserAvatar,
    LastSavedIndicator
  },
  props: ['map', 'mapPlaces', 'mapRoutes', 'addPlaceToList', 'handlePlaceRemoved', 'handleItemDateUpdate', 'handleItemRenamed', 'handleItemImagesUpdated', 'tripData', 'tripItemsList', 'isTripsDataLoaded', 'hoveredRoute', 'hoveredPlace', 'hoveredChild', 'hoveredCountry', 'handleRouteTypeUpdate', 'handlePlaceTypeUpdate', 'handleListOrderUpdate', 'handleListItemHovered', 'user'],
  data() {
    return {
      showRoutesToggle: true,
      saveTimeout: null,
      lastSavedDate: null,
      trip_id: this.$route.params.trip_id,
      tripDates: [null, null],
      userStoredTags: [],
      currentTripTags: [],
      currentTripMates: [],
      isUploadingInProgress: false,
      isDraftSelectedByUser: false,
      isSavingInProgress: false,
      isWatcherEnabled: false
    }
  },
  computed: {
    _routesPresent() {
      console.log('routes present in trip:', this.tripItemsList.some(item => item.draggable_type === 'route'))
      return this.tripItemsList.some(item => item.draggable_type === 'route')
    },
  },
  methods: {
    handleUploadingState(isInProgress) {
      console.log(`=====> image loading state: ${isInProgress}`)
      this.isUploadingInProgress = isInProgress
    },
    handlePlaceDuplicate(place) {
      this.addPlaceToList(place)
    },
    placeTypeUpdateHandler(place, isChild) {
      this.handlePlaceTypeUpdate(place, isChild)
    },
    handleItemDateUpdated(itemDraggableId, newDate){
      this.handleItemDateUpdate(itemDraggableId, newDate)
    },
    routeTypeUpdateHandler(route) {
      // console.log(`===> route type is updated for: ${JSON.stringify(route)}`)
      this.handleRouteTypeUpdate(route)
    },
    save() {
      console.log('saving ...')
      this.isSavingInProgress = true
      const requestData = JSON.parse(JSON.stringify(this.tripData))
      requestData.items.splice(0, requestData.items.length, ...this.tripItemsList)
      requestData.start_date = this.tripDates[0]
      requestData.end_date = this.tripDates[1]

      if (this.trip_id) {
        TripsDataService.update(this.trip_id, requestData)
            .then(response => {
              this.isSavingInProgress = false
              if (response.status !== 200) {
                this.lastSavedDate = false
              } else {
                this.lastSavedDate = new Date();
              }
            })
      } else {
        TripsDataService.create(requestData)
            .then(response => {
              this.trip_id = response.data
              this.isSavingInProgress = false
              if (response.status !== 200) {
                this.lastSavedDate = false
              } else {
                this.lastSavedDate = new Date();
              }
            })
      }
    },
    setTripDates(datesInterval) {
      this.tripDates.splice(0, this.tripDates.length, ...(datesInterval ? datesInterval : [null, null]));

      if (datesInterval && datesInterval[1]) {
        console.log(`selected end date: ${datesInterval[1]}`)

        const inputDate = new Date(datesInterval[1]);
        const currentDate = new Date();

        // Set the time of both dates to midnight to only compare the date part
        inputDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        // Return true if the input date is before the current date
        if (inputDate <= currentDate && !this.isDraftSelectedByUser) {
          this.tripData.completed = true
        }
      }
    },
    handleTripMatesUpdated(updatedMates) {
      this.tripData.mates.splice(0, this.tripData.mates.length, ...updatedMates)
    },
    handleTripTagsUpdated(updatedTags) {
      this.tripData.tags.splice(0, this.tripData.tags.length, ...updatedTags)
    },
    discardTrip() {
      if (useAppStore().isOwnTrip) {
        this.$router.push('/');
      } else {
        this.$router.push(`/user/${this.tripData.user_id}`);
      }
    },
    addItemToWishlist(items) {
      items.forEach(item => item.source_trip_id = this.tripData.id)
      WishlistService.add(items).then((response) => {
        if (response !== undefined && response.status === 200) {
          items.forEach(item => item.in_wishlist = true)
          console.log(`item is added to wishlist`)
        } else {
          console.log(`[ERROR] Failed adding to wishlist`)
        }
      })
    },
    submitForm(){
      if (!this.$refs.formRef) return
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          console.log('submit!')
          this.save()
        } else {
          console.log('error submit!')
          return false
        }
      })
    },
    autosave() {
      if (this.saveTimeout) {
        clearTimeout(this.saveTimeout);  // debounce
      }
      // Wait for 5 seconds after user stops updating trip data
      this.saveTimeout = setTimeout(() => {
        if (this.tripItemsList.length === 0
            && !this.tripDates[0]
            && (this.tripData.title === undefined || this.tripData.title === "")
            || !useAppStore().isOwnTrip
        ) {
          // console.log('empty trip or other member's trip. not saving')
        } else {
          this.save()
        }
      }, 5000);
    }
  },
  mounted() {
    useAppStore().openEdit()
  },
  unmounted() {
    useAppStore().closeEdit()
  },
  updated() {
    // ensure the timeline scroll is at the top after the data has been updated
    this.$nextTick(() => {
      this.$refs.timelineScrollbar.setScrollTop(0);
    });
  },
  watch: {
    tripData : {
      handler() {
        if (this.isWatcherEnabled) {
          this.autosave()
        } else {
          this.isWatcherEnabled = true

        }
      },
      deep: true
    },
    'tripData.start_date': {
      handler() {
        this.setTripDates([this.tripData.start_date, this.tripData.end_date])
      },
      deep: true
    }
  }
};
</script>

<style scope>
.el-page-header__left, .el-page-header__content {
  width: 100%;
}
.input-trip-title .el-input__wrapper, .input-trip-title .el-input__wrapper.is-focus, .input-trip-title .el-input__wrapper:hover {
  box-shadow: none;
}

.input-trip-title, .input-trip-title .el-input__inner, .input-trip-title .el-input__inner:hover, .input-trip-title .el-input__inner:focus {
  font-weight: 700;
  font-size: 18px;
  background-color: #ffffff;
  cursor: text;
}

.input-trip-title .el-input__count-inner {
  font-weight: normal;
}

.input-trip-tags .el-select__wrapper.is-focused, .input-trip-tags .el-select__wrapper, .input-trip-tags .el-select__wrapper.is-hovering:not(.is-focused) {
  box-shadow: none;
  padding-left: 0;
}

.input-trip-tags .el-select__wrapper.is-disabled
{
  box-shadow: none;
  background:none;
}

.input-trip-tags .el-select__wrapper.is-disabled .el-select__caret,
.input-trip-tags .el-select__wrapper.is-disabled .el-tag,
.input-trip-tags .el-select__wrapper.is-disabled {
  cursor: default;
}

.el-select.input-trip-tags .el-select__selection.is-near {
  margin: 0;
}

.input-trip-tags.el-select {
  min-width: 280px;
}

.trip-attributes-label {
  width: 120px;
}
.trip-attributes-row {
  display: flex;
  line-height: 32px;
  width: 100%;
}

.trip-attributes-label .icon.material-design-icon .material-design-icon__svg {
  width: 18px;
  height: 18px;
}

.trip-attributes-label .icon {
  padding-right: 5px;
}

.title-form {
  height: 32px;
}

.title-form .el-form-item__error {
  right: 0;
  left: unset;
}

.trip-content-row {
  width: calc(100% + 80px);
  margin-left: -80px;
}

.save-split-button .el-button-group>.el-button:first-child {
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  width: 100px;
}

.save-split-button .el-button-group>.el-button:last-child {
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
}

.save-split-button .el-loading-spinner .circular {
  height: calc(var(--el-loading-spinner-size) / 2);
  width: calc(var(--el-loading-spinner-size) / 2);
}

.save-split-button .el-loading-spinner {
  margin-top: calc((0px - var(--el-loading-spinner-size)) / 4);
}

.mate-dropdown-option {
  display: flex;
  justify-content: space-between;
  line-height: 16px;
  vertical-align: center;
  height: 16px;
  padding: 9px 0 9px 0;
}

.el-select-dropdown__item.is-selected .mate-dropdown-option span, .el-select-dropdown__item.is-selected .user-info .avatar.generated {
  font-weight: normal;
}

</style>
