<template>
  <el-input
      style="z-index: 3"
      :disabled="!appStore.isOwnTrip"
      v-model="itemLabel"
      class="input-item-title"
      :placeholder="item.draggable_type === 'route' ? '' : (item.source_label ? item.source_label : placeCoordinatesString)"
      maxlength="100"
      @change="itemRenamedEvent(itemLabel)"
  />
</template>


<script setup>
import {useAppStore} from "@/stores";
const appStore = useAppStore()
</script>

<script>
import {useAppStore} from "@/stores";

export default {
  props: ['item', 'itemRenamedEvent'],
  data() {
    return {
      itemLabel: this.item.draggable_type === 'route' ? this.item.label : (this.item.label && this.item.label !== "" ? this.item.label : this.item.source_label)
    }
  },
  computed: {
    placeCoordinatesString() {
      return `${this.item.lat.toFixed(5)}, ${this.item.lon.toFixed(5)}`
    },
  },
  watch: {
    item: {
      handler() {
        this.itemLabel = this.item.draggable_type === 'route' ? this.item.label : (this.item.label && this.item.label !== "" ? this.item.label : this.item.source_label)
      },
      deep: true
    },
  }
};
</script>

<style>
.input-item-title.el-input.is-disabled .el-input__wrapper
{
  box-shadow: none;
  background:none;
}
.input-item-title.el-input.is-disabled .el-input__inner {
  color: var(--el-input-text-color);
  -webkit-text-fill-color: var(--el-input-text-color);
}

.input-item-title.el-input.is-disabled,
.input-item-title.el-input.is-disabled .el-input__inner {
  cursor: default;
}

.input-item-title .el-input__wrapper, .input-item-title .el-input__wrapper.is-focus, .input-item-title .el-input__wrapper:hover {
  box-shadow: none;
}

.input-item-title .el-input__wrapper {
  background: none;
}

.input-item-title .el-input__inner, .input-item-title .el-input__inner:hover, .input-item-title .el-input__inner:focus {
  background: none;
  cursor: text;
  height: 24px;
  line-height: 23px;
}
</style>
