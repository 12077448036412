import http from "../http-common";
import TripsDataService from "@/services/TripsDataService";

class WishlistService {
    get(filters=null) {
        let query = ""

        if (filters) {
            query = TripsDataService._transformFiltersQuery(query, filters)
        }
        return http.get(`/wishlist${query === "" ? "" : `?${query}`}`);
    }

    getFilters() {
        return http.get("/wishlist/filters");
    }

    update(item) {
        return http.put(`/wishlist/${item.id}`, item);
    }

    add(items) {
        return http.post(`/wishlist`, items);
    }

    delete(item) {
        return http.delete(`/wishlist/${item.id}`);
    }

    reorder(listData) {
        return http.put(`/wishlist`, listData);
    }
}

export default new WishlistService();