import axios from "axios";
import {useAuthStore} from "@/stores";

const customAxios = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: {
        "Content-Type": "application/json"
    }
});

customAxios.interceptors.request.use(
    async (config) => {
        const { token } = useAuthStore();
        const isLoggedIn = !!token;
        if (isLoggedIn) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
customAxios.interceptors.response.use(undefined, function (error) {
    if (error) {
        // filter only add unauthorized requests
        if (error.response.status === 401 && error.response.config.baseURL === customAxios.defaults.baseURL) {
            console.log('[http-common.js] intercepted unauthorized API response. Routing to /')
            useAuthStore().logout()
        }

        if (error.response.status === 404 && error.response.config.baseURL === customAxios.defaults.baseURL) {
            console.log('[http-common.js] not found. Routing to not found page')
            return Promise.reject(error)
        }
    }
})

export default customAxios
