<template>
  <div class="list-item-images-container">
    <div v-for="(url, index) in item.images" class="list-item-image">
      <el-image
          :key="url"
          :src="url"
          fit="cover"
          :preview-src-list="item.images"
          :initial-index="index"
          v-loading="indexRemoving === index && imageIsRemoving"
      />
      <close-circle-icon v-if="appStore.isOwnTrip" class="delete-image-icon" @click="handleRemoveImage(index)"/>
    </div>
    <el-upload
        v-if="appStore.isOwnTrip"
        class="image-upload-container"
        multiple
        drag
        :action="MediaService.uploadUrl"
        :headers="{Authorization: `Bearer ${authStore.token}`}"
        :show-file-list="false"
        :on-success="handleImageUpload"
        :on-error="handleImageUploadFailed"
        :before-upload="beforeImageUpload"
    >
      <el-image class="list-item-image upload" v-loading="imageIsLoading">
        <template #error>
          <div class="image-slot">
            <image-plus-outline-icon v-if="!imageIsLoading"/>
          </div>
        </template>
      </el-image>
    </el-upload>
  </div>
                
</template>


<script setup>
import {useAppStore, useAuthStore} from "@/stores";
import MediaService from "@/services/MediaService";
const appStore = useAppStore()
const authStore = useAuthStore()
</script>

<script>
import ContextMenu from "@/components/List/ContextMenu.vue";
import {useAppStore, useAuthStore} from "@/stores";
import MediaService from "@/services/MediaService";
import { ElMessage } from 'element-plus';
export default {
  components: {
    ContextMenu
  },
  props: ['item'],
  data() {
    return {
      imageIsLoading: false,
      indexRemoving: null,
      imageIsRemoving: false,
    }
  },
  methods: {
    showMessage(messageType, messageText) {
      ElMessage({
        message: messageText,
        type: messageType,
        showClose: true
      });
    },
    beforeImageUpload(rawFile) {
      this.imageIsLoading = true
      if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'image/webp') {
        this.showMessage('warning', 'Media format is not supported. Please use .jpg, .png or .webp files only')
        this.imageIsLoading = false
        return false
      } else if (rawFile.size / 1024 / 1024 > 10) {
        this.showMessage('warning', 'Media size must not exceed 10 MB')
        this.imageIsLoading = false
        return false
      }
      return true
    },
    handleImageUpload(response) {
      this.imageIsLoading = false
      console.log(`new image: ${response.path}`)
      if (!this.item.hasOwnProperty("images")) {
        this.item.images = []
      }
      this.item.images.push(response.path)
      this.$emit('item-images-updated-event', this.item.images, this.item.draggable_id);
    },
    handleImageUploadFailed(resp) {
      const respObj = JSON.parse(resp.message)
      this.imageIsLoading = false
      this.showMessage(respObj.type, respObj.message)
    },
    handleRemoveImage(indexToRemove) {
      // TODO: think of error handling
      this.imageIsRemoving = true
      this.indexRemoving = indexToRemove

      // TODO: use it after auto save feature implemented
      // if (this.$route.name === 'Wishlist') {
      //   const imageToRemove = this.item.images[indexToRemove]
      //   MediaService.deleteImages([imageToRemove]).then((resp) => {
      //     if (resp.status === 204) {
      //       this.item.images.splice(indexToRemove, 1);
      //       this.$emit('item-images-updated-event', this.item.images, this.item.draggable_id);
      //       this.imageIsRemoving = false
      //       this.indexRemoving = null
      //     } else {
      //       this.showMessage('error', 'Failed to remove media file')
      //       console.log(`[ERROR] failed to remove image from storage: ${imageToRemove}`)
      //       this.imageIsRemoving = false
      //       this.indexRemoving = null
      //     }
      //   })
      // } else {
        this.item.images.splice(indexToRemove, 1);
        this.$emit('item-images-updated-event', this.item.images, this.item.draggable_id);
        this.imageIsRemoving = false
        this.indexRemoving = null
      // }
    }
  },
  watch: {
    // to enrich select with newly added label, or remove if it was not saved yet
    imageIsLoading: {
      handler() {
        this.$emit('list-item-media-uploading-state-event', this.imageIsLoading);
      },
      deep: true
    }
  }
};
</script>

<style>
.list-item-image {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 80px;
  height: 80px;
}

.list-item-image .el-image {
  width: 80px;
  height: 80px;
}

.list-item-image .el-image__inner {
  border-radius: 6px;
}

.list-item-image.upload {
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.list-item-image .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  /*border: 1px dashed var(--el-text-color-secondary);*/
  color: var(--el-text-color-secondary);
  font-size: 24px;
}

.el-upload-dragger:hover .image-slot {
  color: var(--el-color-primary);
}

.image-upload-container {
  width: 80px;
  height: 80px;
}

.image-upload-container .el-upload-dragger, .image-upload-container .el-upload-dragger.is-dragover {
  padding: 0;
  height: 80px;
}

.image-upload-container .el-upload-dragger.is-dragover .image-slot {
  color: var(--el-color-primary);
}

.delete-image-icon {
  cursor: pointer;
  display: none;
  position: relative;
  z-index: 5;
  top: -90px;
  left: 65px;
  color: white;
}

.list-item-image .el-image:hover + .delete-image-icon, .delete-image-icon:hover {
  display: inline-block;
}

.list-item-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 5px;
}
</style>
