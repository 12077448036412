<template>
  <div class="list-item-context-menu" :class="[{'selected': isSelected }]">
    <dots-vertical-icon class="context-menu-icon"/>
    <dots-vertical-icon class="context-menu-icon"/>
  </div>
</template>

<script>
export default {
  name: "ContextMenu",
  props: ['isChild', 'isSelected'],
  computed: {
    _hoveredColor() {
      if (this.isChild) {
        return "#dddddd"
      }
      return "#eeeeee"
    }
  }
}
</script>

<style scoped>
.list-item-context-menu {
  width: 15px;
  height: 28px;
  line-height: 23px;
  margin: 0 0 0 5px;
  border-radius: 5px;
  display: none;
}

.list-item-context-menu:hover {
  background-color: v-bind(_hoveredColor);
}

.list-item-context-menu.selected {
  background-color: #e3f9ff;
  display: inline-block;
}

.context-menu-icon {
  margin-left: -3.5px;
  top: 3px;
  padding: 0px 2px 0px 1px;
  width: 5px;

  color: #999999;
}

.list-item-context-menu >>> .material-design-icon .material-design-icon__svg {
  height: 16px;
  width: 16px;
}

.list-item-context-menu:focus-visible {
  outline: none
}
</style>