import { createWebHistory, createRouter } from "vue-router";

import TripsDashboard from "@/pages/TripsDashboard.vue";
import TripDetails from "@/pages/TripDetails.vue";
import NotFound from "@/components/NotFound.vue"
import Home from "@/pages/Home.vue";
import {useAuthStore} from "@/stores";
import UserProfile from "@/pages/UserProfile.vue";
import Friends from "@/pages/Friends.vue";
import Wishlist from "@/pages/Wishlist.vue";

const routes = [
  {
    path: "/welcome",
    name: "Home",
    component: Home,
  },
  {
    path: "/profile",
    name: "UserProfile",
    component: UserProfile,
  },
  {
    path: "/not-found",
    name: "Not Found",
    component: NotFound,
  },
  {
    path: '/',
    name: "TripsDashboard",
    component: TripsDashboard,
    meta: {requiresAuth: true},
  },
  {
    path: '/user/:user_id',
    name: "UserTrips",
    component: TripsDashboard,
    meta: {requiresAuth: true},
  },
  {
    path: '/trips/:trip_id',
    name: "TripDetails",
    component: TripDetails,
    meta: {requiresAuth: true}
  },
  {
    path: '/trips/create',
    name: "TripCreate",
    component: TripDetails,
    meta: {requiresAuth: true}
  },
  {
    path: "/friends",
    name: "Friends",
    component: Friends,
  },
  {
    path: "/wishlist",
    name: "Wishlist",
    component: Wishlist,
  }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (useAuthStore().token) {
      next();
      return;
    }
    console.log("user not logged in")
    next("/welcome");
  } else {
    next();
  }
});

export default router;
