<template>
  <div>
    <el-select
        v-model="selectedListLocal"
        value-key="index"
        :placeholder="placeholder"
        class="filter-select"
        :class="[{ 'is-selected': selectedListLocal.length > 0 }]"
        clearable
        multiple
        size="small"
        no-data-text="no options"
        @change="handleSelectionUpdate(filterBy, selectedListLocal)"
    >
      <template #label="{ label, value }">
        <span>{{ label }}: </span>
        <span style="font-weight: bold">{{ value }}</span>
      </template>

      <el-option v-for="(item, index) in optionsList"
                 :key="index"
                 :value="item">
        <template #default>
          <slot name="dropdown-option" :item="item"></slot>
        </template>
      </el-option>

      <template #tag>
        <div style="display: flex; flex: 1; flex-wrap: nowrap; gap: 6px;">
          <el-text type="info" size="small" style="padding-left: 6px;" v-if="selectedListLocal.length > 0">{{ filterBy }}:</el-text>
          <el-tag
              v-for="(item, index) in selectedListLocal"
              :key="index"
              :round="true"
              effect="light"
              :closable="true"
              size="small"
              @close="handleFilterOptionRemoved(index)"
              style="padding-right: 5px;"
          >
            <slot name="selected-option" :item="item"></slot>
          </el-tag>
        </div>
      </template>
    </el-select>
  </div>
</template>

<script>

export default {
  name: "Sample",
  props: ['filterBy', 'selectedList', 'optionsList', 'handleSelectionUpdate'],
  data() {
    return {
      selectedListLocal: this.selectedList
    }
  },
  methods: {
    handleFilterOptionRemoved(index) {
      this.selectedListLocal.splice(index, 1);
      this.handleSelectionUpdate(this.filterBy, this.selectedListLocal)
    }
  },
  computed: {
    placeholder() {
      return `filter by ${this.filterBy}`
    }
  },
  watch: {
    selectedList(updated) {
      this.selectedListLocal.splice(0, this.selectedListLocal.length, ...updated)
    }
  }
};
</script>

<style scope>
.filter-select .el-select__wrapper {
  line-height: unset;
  border-radius: 15px;
  min-width: 170px;
  /*max-width: 350px;*/
}
.filter-select.is-selected .el-select__wrapper, .filter-select.is-selected .el-select__wrapper.is-hovering:not(.is-focused) {
  box-shadow: 0 0 0 1px var(--el-color-primary) inset;
}
</style>
