<template>
  <div>
    <div>
      <el-page-header class="trip-menu-header">
        <template #content>
    <!--      <view-headline-icon class="menu-controls-icon" />-->
    <!--      <apps-icon class="menu-controls-icon" />-->
          <el-badge is-dot :hidden="filterBadgeCounter === 0" class="filter-badge" :show-zero="false" type="primary" :offset="[1, 2]">
            <filter-variant-icon
                @click="displayFilterRow"
                class="menu-controls-icon"
                :class="[{'active': filterBadgeCounter > 0}]"
            />
          </el-badge>
        </template>
        <template #extra>
          <el-button v-if="appStore.isOwnTrip" type="primary" round :icon="Plus" @click="$router.push('/trips/create')" style="right: 0;">Add new trip</el-button>
        </template>
      </el-page-header>
    </div>
    <el-scrollbar>
      <div class="trip-menu-filters-row" v-if="isFilterRowDisplayed">
      <filter-select
        v-if="tripsListFilterOptions.tags.length > 0 || tripsListFilters.tags.length > 0"
        filterBy="tags"
        :selectedList="tripsListFilters.tags"
        :optionsList="tripsListFilterOptions.tags"
        :handleSelectionUpdate="handleFilterUpdate"
      >
        <template v-slot:dropdown-option="{ item }">
          <div>{{ item }}</div>
        </template>
        <template v-slot:selected-option="{ item }">
          <div>{{ item }}</div>
        </template>
      </filter-select>

      <filter-select
          v-if="tripsListFilterOptions.country.length > 0 || tripsListFilters.country.length > 0"
          filterBy="country"
          :selectedList="tripsListFilters.country"
          :optionsList="tripsListFilterOptions.country"
          :handleSelectionUpdate="handleFilterUpdate"
      >
        <template v-slot:dropdown-option="{ item }">
          <div>{{ item.label }}</div>
        </template>
        <template v-slot:selected-option="{ item }">
          <div>{{ item.label }}</div>
        </template>
      </filter-select>

      <filter-select
          v-if="tripsListFilterOptions.mates.length > 0 || tripsListFilterOptions.mates.length > 0"
          filterBy="mates"
          :selectedList="tripsListFilters.mates"
          :optionsList="tripsListFilterOptions.mates"
          :handleSelectionUpdate="handleFilterUpdate"
      >
        <template v-slot:dropdown-option="{ item }">
          <div>{{ item.name ? item.name : item.alias }}</div>
        </template>
        <template v-slot:selected-option="{ item }">
          <user-info :user="item" size="small"/>
        </template>
      </filter-select>
    </div>
    </el-scrollbar>
  </div>
</template>

<script setup>
import {useAppStore} from "@/stores";
const appStore = useAppStore()
import { Sort, Filter, Search, Plus } from '@element-plus/icons-vue'
</script>

<script>
import FilterSelect from "@/components/Core/Filter/FilterSelect.vue";
import UserInfo from "@/components/Core/User/UserInfo.vue";
import UserDataService from "@/services/UserDataService";
import {useAppStore, useAuthStore} from "@/stores";

export default {
  props: ['setDashboardView', 'setTableView', 'tripsListFilters', 'tripsListFilterOptions', 'isFilterRowDisplayed', 'displayFilterRow', 'filterBadgeCounter'],
  components: {
    FilterSelect,
    UserInfo
  },
  data() {
    return {
      activeFilters: {
        tags: false,
        mates: false,
        countries: false,
        photos: false
      }
    }
  },
  methods: {
    handleFilterUpdate(filterBy, filterValues) {
      this.$emit('update-filter-query-event', filterBy, filterValues);
    }
  }
};
</script>

<style>
.filter-badge, .menu-controls-icon {
  cursor: pointer;
}
.menu-controls-icon.material-design-icon .material-design-icon__svg {
  width: 22px;
  height: 22px;
  color: var(--el-text-color-secondary);
}

.menu-controls-icon.active.material-design-icon .material-design-icon__svg {
  color: var(--el-color-primary);
}

.trip-menu-header .el-page-header__back, .trip-menu-header .el-divider.el-divider--vertical {
  display: none;
}

.trip-menu-filters-row {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  gap: 6px;
  height: 35px;
  padding-top: 3px;
}
</style>