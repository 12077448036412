<template>
  <list-item-row :item="route" :hovered="hovered" :isChild="false" :draftTripsList="draftTripsList" :isLastItem="false" :isFirstItem="false">
    <template v-slot:item-label="{ itemRenamedEvent }">
      <div style="display: flex; width: 100%;">
        <list-item-title
            :item="route"
            :itemRenamedEvent="itemRenamedEvent"
            style="padding-left: 15px;"
        />
      </div>
    </template>

    <template v-slot:item-type-select>
      <list-item-type-select
          :item="route"
          :isChild="false"
          :itemTypeChangedEvent="routeTypeChangedEvent"
          style="margin-left: -20px;"
      />
    </template>

    <template v-slot:item-children-container>
      <slot name="children"></slot>
    </template>
  </list-item-row>
</template>

<script setup>
import {useAppStore} from "@/stores";
const appStore = useAppStore()
</script>

<script>
import ListItemRow from "@/components/List/ListItemRow.vue";
import ListItemTitle from "@/components/List/ListItemTitle.vue";
import ListItemTypeSelect from "@/components/List/ListItemTypeSelect.vue";

export default {
  props: ['route', 'hovered', 'draftTripsList'],
  components: {
    ListItemRow,
    ListItemTitle,
    ListItemTypeSelect
  },
  data() {
    return {
      routeLabel: this.route.label,
    }
  },
  methods: {
    routeTypeChangedEvent(route) {
      // Emit a custom event with the selected value
      console.log("Route List Item: route type updated")
      route.updated = true
      this.$emit('route-type-updated-event', route);
      // this.route.children.splice(0) // TODO: this removes via-points from the route on update
    },
  }
};
</script>
