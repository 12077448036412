import mapboxgl from "mapbox-gl";
import {along, length} from "@turf/turf";

export function insertViaPointAtIndex(route, newPoint) {
    const coordinates = [route.geometry[0], ...route.children.map(viaPoint => [viaPoint.lon, viaPoint.lat]), route.geometry[route.geometry.length - 1]]
    let minDistance = Infinity;
    let insertIndex = 0;

    // Calculate distances between the new point and each segment in the coordinates
    for (let i = 0; i < coordinates.length - 1; i++) {
        const [x1, y1] = coordinates[i];
        const [x2, y2] = coordinates[i + 1];
        const length = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

        // Calculate the signed area of the triangle formed by the point and the line
        const area = ((x2 - x1) * (newPoint[1] - y1) - (newPoint[0] - x1) * (y2 - y1));

        // Calculate the perpendicular distance
        const distanceToNewPoint = Math.abs(area / length);
        if (distanceToNewPoint < minDistance
            && newPoint[0] > Math.min(x1, x2) && newPoint[0] < Math.max(x1, x2)
            && newPoint[1] > Math.min(y1, y2) && newPoint[1] < Math.max(y1, y2)
        ) {
            minDistance = distanceToNewPoint;
            insertIndex = i + 1;
        }
    }
    return insertIndex - 1
}

export function setMapCenterAndZoom(mapInstance, features) {
    // Calculate the bounding box for places features. Might be faster, but not so pretty
    // const bounds = mapPlacesFeatureCollection.features.reduce((bounds, feature) => {
    //   return bounds.extend(feature.geometry.coordinates);
    // }, new mapboxgl.LngLatBounds(mapPlacesFeatureCollection.features[0].geometry.coordinates, mapPlacesFeatureCollection.features[0].geometry.coordinates));
    try {
        if (features.length > 0) {
            // Initialize bounding box with initial coordinates
            let minLng = Infinity;
            let minLat = Infinity;
            let maxLng = -Infinity;
            let maxLat = -Infinity;

            // Iterate through line features and update bounding box
            features.forEach((feature) => {
                let coordinates = feature.geometry.coordinates;
                // adjust coordinates for Points and Lines
                if (feature.geometry.type === 'Point') {
                    coordinates = [coordinates]
                }
                coordinates.forEach(pair => {
                    minLng = Math.min(minLng, pair[0]);
                    minLat = Math.min(minLat, pair[1]);
                    maxLng = Math.max(maxLng, pair[0]);
                    maxLat = Math.max(maxLat, pair[1]);
                });
            });

            // Create bounding box
            const bounds = new mapboxgl.LngLatBounds([minLng, minLat], [maxLng, maxLat]);

            // Fit the map to the bounding box
            mapInstance.fitBounds(bounds, {padding: 40, maxZoom: 7});
        }
    } catch (e) {
        // TODO: this handles the case with empty routes from / to country
        console.log(`failed to recenter map: ${e}`)
    }
}

export function drawFlightRoute(coordinates) {
    let routeCoordinates = []
    for (let i = 0; i < coordinates.length - 1; i++) {
        routeCoordinates = routeCoordinates.concat(drawFlightPath(coordinates[i], coordinates[i+1]))
    }
    return routeCoordinates
}

export function drawFlightPath(originCoordinates, destinationCoordinates) {
    // Calculate the distance in kilometers between route start/end point.
    let feature = {
        'type': 'Feature',
        'properties': {},
        'geometry': {
            'type': 'LineString',
            'coordinates': [originCoordinates, destinationCoordinates]
        }
    }
    const lineDistance = length(feature);
    const coordinates = [];

    // Number of steps to use in the arc and animation, more steps means
    // a smoother arc and animation, but too many steps will result in a
    // low frame rate
    const steps = 25;

    // Draw an arc between the `origin` & `destination` of the two points
    for (let i = 0; i < lineDistance; i += lineDistance / steps) {
        const segment = along(feature, i);
        coordinates.push(segment.geometry.coordinates);
    }
    // push the last point
    coordinates.push(destinationCoordinates)

    return coordinates
}