// https://pictogrammers.com/library/mdi/

import RadioboxMarkedIcon from 'vue-material-design-icons/RadioboxMarked.vue';
import ParkingIcon from 'vue-material-design-icons/Parking.vue';
import DotsVerticalIcon from 'vue-material-design-icons/DotsVertical.vue';
import CircleMediumIcon from 'vue-material-design-icons/CircleMedium.vue';
import MapMarkerOutlineIcon from 'vue-material-design-icons/MapMarkerOutline.vue';
import HomeOutlineIcon from 'vue-material-design-icons/HomeOutline.vue';
import FoodOutlineIcon from 'vue-material-design-icons/FoodOutline.vue';
import SoccerIcon from 'vue-material-design-icons/Soccer.vue';
import LeafIcon from 'vue-material-design-icons/Leaf.vue';
import PillarIcon from 'vue-material-design-icons/Pillar.vue';
import BinocularsIcon from 'vue-material-design-icons/Binoculars.vue';
import GasStationOutlineIcon from 'vue-material-design-icons/GasStationOutline.vue';
import AirportIcon from 'vue-material-design-icons/Airport.vue';
import AnchorIcon from 'vue-material-design-icons/Anchor.vue';
import StadiumIcon from 'vue-material-design-icons/Stadium.vue';
import HeartOutlineIcon from 'vue-material-design-icons/HeartOutline.vue';
import TentIcon from 'vue-material-design-icons/Tent.vue';
import WaterfallIcon from 'vue-material-design-icons/Waterfall.vue';
import ImageFilterHdrIcon from 'vue-material-design-icons/ImageFilterHdr.vue';
import CityVariantOutlineIcon from 'vue-material-design-icons/CityVariantOutline.vue';
import UmbrellaBeachOutlineIcon from 'vue-material-design-icons/UmbrellaBeachOutline.vue';

import UfoOutlineIcon from 'vue-material-design-icons/UfoOutline.vue';
import CarHatchbackIcon from 'vue-material-design-icons/CarHatchback.vue';
import AirplaneIcon from 'vue-material-design-icons/Airplane.vue';
import FerryIcon from 'vue-material-design-icons/Ferry.vue';
import TrainIcon from 'vue-material-design-icons/Train.vue';
import BusIcon from 'vue-material-design-icons/Bus.vue';
import HikingIcon from 'vue-material-design-icons/Hiking.vue';
import HailIcon from 'vue-material-design-icons/Hail.vue';
import TransitConnectionVariantIcon from 'vue-material-design-icons/TransitConnectionVariant.vue';
import SourceBranchIcon from 'vue-material-design-icons/SourceBranch.vue';

import AccountGroupOutlineIcon from 'vue-material-design-icons/AccountGroupOutline.vue';
import CompassOutlineIcon from 'vue-material-design-icons/CompassOutline.vue';

import CalendarMonthOutlineIcon from 'vue-material-design-icons/CalendarMonthOutline.vue';
import LabelMultipleOutlineIcon from 'vue-material-design-icons/LabelMultipleOutline.vue';
import MapMarkerPlusOutlineIcon from 'vue-material-design-icons/MapMarkerPlusOutline.vue';

import TrashCanOutlineIcon from 'vue-material-design-icons/TrashCanOutline.vue';
import ArrowExpandIcon from 'vue-material-design-icons/ArrowExpand.vue';
import ViewHeadlineIcon from 'vue-material-design-icons/ViewHeadline.vue';
import AppsIcon from 'vue-material-design-icons/Apps.vue';
import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';
import ImagePlusOutlineIcon from 'vue-material-design-icons/ImagePlusOutline.vue';
import BookmarkOutlineIcon from 'vue-material-design-icons/BookmarkOutline.vue';
import MapClockOutlineIcon from 'vue-material-design-icons/MapClockOutline.vue';
import MapCheckOutlineIcon from 'vue-material-design-icons/MapCheckOutline.vue';
import FilterVariantIcon from 'vue-material-design-icons/FilterVariant.vue';
import BookmarkCheckOutlineIcon from 'vue-material-design-icons/BookmarkCheckOutline.vue';
import CheckBoldIcon from 'vue-material-design-icons/CheckBold.vue';
import BedOutlineIcon from 'vue-material-design-icons/BedOutline.vue';
import ContentCopyIcon from 'vue-material-design-icons/ContentCopy.vue';
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
import ChevronUpIcon from 'vue-material-design-icons/ChevronUp.vue';
import RefreshIcon from 'vue-material-design-icons/Refresh.vue';
import EyeOffOutlineIcon from 'vue-material-design-icons/EyeOffOutline.vue';
import EyeOutlineIcon from 'vue-material-design-icons/EyeOutline.vue';
import CameraOutlineIcon from 'vue-material-design-icons/CameraOutline.vue';
import MessageAlertOutlineIcon from 'vue-material-design-icons/MessageAlertOutline.vue';
import MagnifyScanIcon from 'vue-material-design-icons/MagnifyScan.vue';
import PawIcon from 'vue-material-design-icons/Paw.vue';
import KayakingIcon from 'vue-material-design-icons/Kayaking.vue';
import CastleIcon from 'vue-material-design-icons/Castle.vue';
import LighthouseIcon from 'vue-material-design-icons/Lighthouse.vue';
import FishIcon from 'vue-material-design-icons/Fish.vue';
import FlagCheckeredIcon from 'vue-material-design-icons/FlagCheckered.vue';
import PlusIcon from 'vue-material-design-icons/Plus.vue';
import CancelIcon from 'vue-material-design-icons/Cancel.vue';
import LinkVariantIcon from 'vue-material-design-icons/LinkVariant.vue';
import TuneVariantIcon from 'vue-material-design-icons/TuneVariant.vue';

export function registerGlobalIcons(app) {
    app.component('plus-icon', PlusIcon)
    app.component('cancel-icon', CancelIcon)
    app.component('eye-off-outline-icon', EyeOffOutlineIcon)
    app.component('eye-outline-icon', EyeOutlineIcon)
    app.component('refresh-icon', RefreshIcon)
    app.component('chevron-up-icon', ChevronUpIcon)
    app.component('chevron-down-icon', ChevronDownIcon)
    app.component('content-copy-icon', ContentCopyIcon)
    app.component('check-bold-icon', CheckBoldIcon)
    app.component('bed-outline-icon', BedOutlineIcon)
    app.component('filter-variant-icon', FilterVariantIcon)
    app.component('map-clock-outline-icon', MapClockOutlineIcon)
    app.component('map-check-outline-icon', MapCheckOutlineIcon)
    app.component('bookmark-check-outline-icon', BookmarkCheckOutlineIcon)
    app.component('bookmark-outline-icon', BookmarkOutlineIcon)
    app.component('close-circle-icon', CloseCircleIcon)
    app.component('image-plus-outline-icon', ImagePlusOutlineIcon)
    app.component('view-headline-icon', ViewHeadlineIcon)
    app.component('apps-icon', AppsIcon)
    app.component('trash-can-outline-icon', TrashCanOutlineIcon)
    app.component('arrow-expand-icon', ArrowExpandIcon)
    app.component('calendar-month-outline-icon', CalendarMonthOutlineIcon)
    app.component('label-multiple-outline-icon', LabelMultipleOutlineIcon)
    app.component('map-marker-plus-outline-icon', MapMarkerPlusOutlineIcon)
    app.component('radiobox-marked-icon', RadioboxMarkedIcon)
    app.component('parking-icon', ParkingIcon)
    app.component('dots-vertical-icon', DotsVerticalIcon)
    app.component('circle-medium-icon', CircleMediumIcon)
    app.component('map-marker-outline-icon', MapMarkerOutlineIcon)
    app.component('home-outline-icon', HomeOutlineIcon)
    app.component('food-outline-icon', FoodOutlineIcon)
    app.component('soccer-icon', SoccerIcon)
    app.component('leaf-icon', LeafIcon)
    app.component('pillar-icon', PillarIcon)
    app.component('binoculars-icon', BinocularsIcon)
    app.component('gas-station-outline-icon', GasStationOutlineIcon)
    app.component('airport-icon', AirportIcon)
    app.component('anchor-icon', AnchorIcon)
    app.component('stadium-icon', StadiumIcon)
    app.component('heart-outline-icon', HeartOutlineIcon)
    app.component('tent-icon', TentIcon)
    app.component('waterfall-icon', WaterfallIcon)
    app.component('image-filter-hdr-icon', ImageFilterHdrIcon)
    app.component('city-variant-outline-icon', CityVariantOutlineIcon)
    app.component('umbrella-beach-outline-icon', UmbrellaBeachOutlineIcon)
    app.component('ufo-outline-icon', UfoOutlineIcon)
    app.component('car-hatchback-icon', CarHatchbackIcon)
    app.component('airplane-icon', AirplaneIcon)
    app.component('ferry-icon', FerryIcon)
    app.component('train-icon', TrainIcon)
    app.component('bus-icon', BusIcon)
    app.component('hiking-icon', HikingIcon)
    app.component('hail-icon', HailIcon)
    app.component('transit-connection-variant-icon', TransitConnectionVariantIcon)
    app.component('account-group-outline-icon', AccountGroupOutlineIcon)
    app.component('compass-outline-icon', CompassOutlineIcon)
    app.component('camera-outline-icon', CameraOutlineIcon)
    app.component('message-alert-outline-icon', MessageAlertOutlineIcon)
    app.component('magnify-scan-icon', MagnifyScanIcon)
    app.component('paw-icon', PawIcon)
    app.component('kayaking-icon', KayakingIcon)
    app.component('castle-icon', CastleIcon)
    app.component('lighthouse-icon', LighthouseIcon)
    app.component('fish-icon', FishIcon)
    app.component('flag-checkered-icon', FlagCheckeredIcon)
    app.component('link-variant-icon', LinkVariantIcon)
    app.component('tune-variant-icon', TuneVariantIcon)
    app.component('source-branch-icon', SourceBranchIcon)
}