export const countriesLayer = {
    id: 'country_boundaries',
    source: {
        type: 'vector',
        generateId: true,
        url: 'mapbox://mapbox.country-boundaries-v1',
    },
    'source-layer': 'country_boundaries',
    type: 'fill',
    paint: {
        'fill-color': '#96defc',
        'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.5,
            0.2
        ]
    },
}
export const popUpOptionsLayer = {
    id: 'options',
        type: 'circle',
        source: {
        type: 'geojson',
            generateId: true,
            data: {
            'type': 'FeatureCollection',
                'features': []
        }
    },
    paint: {
        'circle-color': '#ea982c',
            'circle-radius': 10,
            'circle-opacity': 0.5,
            "circle-stroke-width": [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                1
            ],
            'circle-stroke-color': '#9f6518',
    }
}
// clusters layer example: https://docs.mapbox.com/mapbox-gl-js/example/cluster/
export const circlesLayerDeprecated = {
    id: 'places',
    type: 'circle',
    source: {
        type: 'geojson',
            generateId: true,
            data: {
            'type': 'FeatureCollection',
                'features': []
        }
    },
    paint: {
        'circle-color': '#00bbff',
        'circle-radius': 6,
        'circle-opacity': 0.5,
        "circle-stroke-width": [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            3,
            1
        ],
        'circle-stroke-color': '#00bbff',
    }
}

export const routesLayer = {
    "id": "routes",
    "type": "line",
    "source": {
        "type": "geojson",
            "generateId": true,
            "data": {
            'type': 'FeatureCollection',
                'features': []
        }
    },
    'layout': {
        'line-join': 'round', // Optional: adjust line join to 'round' for smoother corners
        'line-cap': 'round' // Set line cap to 'round' to make ends of dashes appear rounded
    },
    "paint": {
        "line-width": 4,
        "line-color": [
            'case',
            // ['boolean', ['get', 'updated'], false], "#8c8c8c",
            ['==', ['get', 'type'], "direct"], "#8c8c8c",
            ['==', ['get', 'type'], "flight"], "#2cafea",
            ['==', ['get', 'type'], "driving"], "#005bff",
            ['==', ['get', 'type'], "walking"], "#006c10",
            ['==', ['get', 'type'], "ferry"], "#722cea",
            ['==', ['get', 'type'], "train"], "#fcd521",
            ['==', ['get', 'type'], "bus"], "#ea982c",
            ['==', ['get', 'type'], "hitchhiking"], "#d72cea",
            "#ff0000"
        ],
        "line-opacity": [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0.7
        ],
        // "line-dasharray": [
        //     'case',
        //     ['boolean', ['get', 'dashed'], true],
        //     ["literal", [1, 2]],
        //     ["literal", []]
        // ]
    }
}

// https://docs.mapbox.com/style-spec/reference/layers/#symbol
export const circlesLayer = {
    id: 'icons',
    type: 'symbol',
    source: {
        type: 'geojson',
        generateId: true,
        data: {
            'type': 'FeatureCollection',
            'features': []
        }
    },
    layout: {
        'icon-image': ['concat', ['get', 'icon']],
        'icon-size': 1,
        'icon-allow-overlap': true
    },
    paint: {
        'icon-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0.5
        ],

    }
}
export const viaPointsLayer = {
    id: 'viapoints',
    type: 'symbol',
    source: {
        type: 'geojson',
        generateId: true,
        data: {
            'type': 'FeatureCollection',
            'features': []
        }
    },
    layout: {
        'icon-image': ['concat', ['get', 'icon']],
        'icon-size': 1,
        'icon-allow-overlap': true
    },
    paint: {
        'icon-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0.5
        ],

    }
}