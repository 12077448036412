<template>
  <el-table
      ref="dashboardTable"
      empty-text=" "
      :data="tripsListSections"
      stripe
      @row-click="clickRow"
      highlight-current-row
      size="small"
      row-key="id"
      :span-method="objectSpanMethod"
      :row-class-name="tableRowClassName"
      :expand-row-keys="['completed']"
      class="trips-table"
      :indent=0
  >
<!--    <el-table-column type="index" :index="indexMethod" label="#" width="40"/>-->
    <el-table-column prop="title" label="Trip title">
      <template #default="scope">
        <span>
          <map-clock-outline-icon v-if="scope.row.id === 'draft'" class="draft-trips-icon"/>
          <map-check-outline-icon v-if="scope.row.id === 'completed'" class="draft-trips-icon"/>
          <span class="trip-list-title">{{ scope.row.title }}</span>
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="tags" label="Tags" width="180">
      <template #default="scope">
        <tags-collapsable :itemsList="scope.row.tags"/>
      </template>
    </el-table-column>
    <el-table-column label="Dates" width="140">
      <template #default="scope">
        {{ formatDate(scope.row.start_date, scope.row.end_date) }}
      </template>
    </el-table-column>
    <el-table-column label="Mates" width="65">
      <template #default="scope">
        <mates-avatars-list :matesList="scope.row.mates"/>
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="" width="125">
      <template #default="scope">
        <div style="text-align: right;" v-if="scope.row.id !== 'draft' && scope.row.id !== 'completed'">
          <camera-outline-icon class="table-row-icon" v-if="scope.row.has_media"/>
          <arrow-expand-icon @click.prevent="$router.push(`/trips/${scope.row.id}`)" class="table-row-icon" />

<!--          <el-tooltip-->
<!--              v-if="appStore.isOwnTrip && scope.row.user_id !== useAuthStore().userId"-->
<!--              effect="light"-->
<!--              content="You were mentioned as a trip mate in this trip.<br><b>Approve</b> to make it visible for others,<br>or <b>Decline</b> to remove from your trips history"-->
<!--              placement="right"-->
<!--              raw-content-->
<!--          >-->
            <el-badge
                v-if="appStore.isOwnTrip && scope.row.user_id !== useAuthStore().userId"
                is-dot
                type="primary"
                :offset="[0, 5]"
            >
              <table-row-menu
                  :item="scope.row"
                  :delete="deleteTrip"
                  :isSharedTrip="true"
                  @shared-trip-accepted-event="handleShareTripEvent"
                  @shared-trip-rejected-event="handleShareTripEvent"
              />
            </el-badge>
<!--          </el-tooltip>-->
          <table-row-menu
              v-else
              :item="scope.row"
              :delete="deleteTrip"
          />

          <!--          <trash-can-outline-icon @click.prevent="deleteTrip(scope.row.id)" class="table-row-icon" v-if="scope.row.id !== 'draft' && scope.row.id !== 'completed'"/>-->
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script setup>
import {useAppStore, useAuthStore} from "@/stores";
const appStore = useAppStore()
</script>

<script>
import MatesAvatarsList from "@/components/Core/MatesAvatarsList.vue";
import TagsCollapsable from "@/components/Dashboard/TagsCollapsable.vue";
import TableRowMenu from "@/components/Dashboard/TableRowMenu.vue";

export default {
  props: ["tripsList", "deleteTrip", "previewTrip"],
  components: {
    MatesAvatarsList,
    TagsCollapsable,
    TableRowMenu
  },
  data() {
    return {
      tripsListSections: []
    }
  },
  methods: {
    clickRow(trip, column) {
      // clear current row if deselected the trip
      if (column.label !== "" && trip.id !== 0) {
        this.previewTrip(trip)
        if (!trip.focused) {
          this.$refs.dashboardTable.setCurrentRow()
        }
      }
    },
    handleShareTripEvent() {
      console.log('emit shared trip action')
      this.$emit('update-trips-list-event');
    },
    formatDate(startDateString, endDateString) {
      if (!startDateString) {
        return ""
      }
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);

      const options = { day: 'numeric', month: 'short' };
      return `${startDate.toLocaleDateString('en-US', startDate.getFullYear() == endDate.getFullYear() ? options : { ...options, year: 'numeric' })} - ${endDate.toLocaleDateString('en-US', { ...options, year: 'numeric' })}`
    },
    objectSpanMethod(row, column, rowIndex, columnIndex) {
      if (rowIndex === 0) {
        return {
          rowspan: 1,
          colspan: 3,
        }
      }
    },
    indexMethod(index) {
      return index
    },
    tableRowClassName(data) {
      if (data.row.id === "draft" || data.row.id === "completed") {
        return 'header-row'
      }
      if (data.row.focused) {
        return 'selected-row'
      }
      return ''
    }
  },
  mounted() {
    // to preserve selected trip if user switches between card & table views
    this.tripsList.forEach(trip => {
      if(trip.focused) {
        this.$refs.dashboardTable.setCurrentRow(trip);
      }
    })
  },
  watch: {
    tripsList: {
      handler() {
        console.log('trips list updated')
        this.tripsListSections.splice(0, this.tripsListSections.length, ...[])
        const draftTripsSection = {
          "id": "draft",
          "title": "Draft trips",
          "items": [],
          "children": this.tripsList.filter(trip => !trip.completed)
        }
        const completedTripsSection = {
          "id": "completed",
          "title": "Completed trips",
          "items": [],
          "children": this.tripsList.filter(trip => trip.completed)
        }

        if (draftTripsSection.children.length > 0) {
          this.tripsListSections.push(draftTripsSection)
        }

        if (completedTripsSection.children.length > 0) {
          this.tripsListSections.push(completedTripsSection)
        }
      },
      deep: true
    }
  }
};
</script>

<style>
.el-table {
  cursor: pointer;
}
.table-row-icon {
  padding: 0px 6px 0px 6px;
  top: 4px;
}
.table-row-icon.material-design-icon .material-design-icon__svg {
  width: 18px;
  height: 18px;
}

.el-table .header-row {
  --el-table-tr-bg-color: var(--el-fill-color-light);
  font-weight: bold;
}

.el-table .selected-row {
  --el-table-tr-bg-color: #e3f9ff;
  color: var(--el-color-primary);
}

.trips-table.el-table--striped .el-table__body tr.el-table__row--striped.selected-row td.el-table__cell,
.trips-table.el-table--striped .el-table__body tr.el-table__row--striped.header-row td.el-table__cell
{
  background: unset;
}

.draft-trips-icon {
  top: 3px;
}

.draft-trips-icon.material-design-icon .material-design-icon__svg {
  width: 16px;
  height: 16px;
  z-index: 3;
}

.trips-table .el-table__placeholder {
  display: none;
}

.trip-list-title {
  margin-left: 10px;
}
</style>