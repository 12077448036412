<template>
  <div style="width: 100%; display: flex; align-items: center; justify-content: center;">
    <div style="width: 900px;">
    <el-container>
      <el-aside style="width: 200px;">
        <slot name="content">
          <div style="width: 100%; justify-content: center; display: flex;">
            <div>
              <el-upload
                  class="avatar-uploader"
                  :action="MediaService.uploadAvatarUrl"
                  :headers="{Authorization: `Bearer ${authStore.token}`}"
                  :show-file-list="false"
                  :on-success="handleImageUpload"
                  :before-upload="beforeImageUpload"
                  :on-error="handleImageUploadFailed"
              >
                <el-image v-if="userAvatar" :src="userAvatar" class="avatar" fit="cover" v-loading="imageIsLoading"/>
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
              </el-upload>
              <div style="font-weight: bold; text-align: center; padding-top: 5px;">{{ userName }}</div>
            </div>
    <!--        <div>set home location</div>-->
          </div>
          <el-divider />
          <div class="profile-section-button">
            <account-group-outline-icon class="icon"/>Manage trip mates
          </div>
<!--          <div class="profile-section-button">-->
<!--            <label-multiple-outline-icon class="icon"/>Manage your tags-->
<!--          </div>-->
        </slot>
      </el-aside>

      <el-main width="70%" style="padding: 0 0 20px 20px">
        <div>
  <!--        add new (create / select from users)-->
  <!--        mates:-->
  <!--          avatar update-->
  <!--          name update = alias-->
  <!--          associated name-->
  <!--          email / link-->
  <!--        actions:-->
  <!--          create-->
  <!--            associated with been-->
  <!--            not associated-->
  <!--          delete (from all trips too)-->
  <!--          update-->
  <!--            link not associated to account-->
  <!--            link not associated to email (future registration)-->
  <!--            upload / modify avatar-->
  <!--            change alias name-->
  <!--            unlink-->
          <mates-list-view />
        </div>
      </el-main>
    </el-container>
    </div>
  </div>
</template>

<script setup>
import {useAuthStore} from "@/stores";
import MediaService from "@/services/MediaService";
const authStore = useAuthStore()
</script>

<script>

import MatesListView from "@/components/Profile/MatesListView.vue";
import UserDataService from "@/services/UserDataService";
import {ElMessage} from "element-plus";

export default {
  props: {
  },
  components: {
    MatesListView
  },
  data() {
    return {
      userName: null,
      userAvatar: null,
      imageIsLoading: false,
    }
  },
  methods: {
    showMessage(messageType, messageText) {
      ElMessage({
        message: messageText,
        type: messageType,
        showClose: true
      });
    },
    beforeImageUpload(rawFile) {
      this.imageIsLoading = true
      if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'image/webp') {
        this.showMessage('warning', 'Media format is not supported. Please use .jpg, .png or .webp files only')
        this.imageIsLoading = false
        return false
      } else if (rawFile.size / 1024 / 1024 > 10) {
        this.showMessage('warning', 'Media size must not exceed 10 MB')
        this.imageIsLoading = false
        return false
      }
      return true
    },
    handleImageUpload(response) {
      this.imageIsLoading = true
      const userData = {
        name: this.userName,
        avatar: response.path
      }
      UserDataService.updateUserData(userData).then((response) => {
        this.userAvatar = response.data.avatar
        this.imageIsLoading = false
      })
    },
    handleImageUploadFailed() {
      const respObj = JSON.parse(resp.message)
      this.imageIsLoading = false
      this.showMessage(respObj.type, respObj.message)
    }
  },
  watch: {

  },
  mounted() {
    UserDataService.getCurrentData().then((response) => {
      this.userName = response.data.name
      this.userAvatar = response.data.avatar
    });
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  }
};
</script>

<style scoped>
.avatar {
  width: 96px;
  height: 96px;
}
.avatar-uploader {
  width: 96px;
  padding: 0 52px 0 52px;
}
.icon {
  padding-right: 10px;
}
.profile-section-button {
  padding: 3px 0 3px 0;
  color: var(--el-text-color-regular)
}

.profile-section-button >>> .material-design-icon .material-design-icon__svg {
  height: 18px;
  width: 18px;
  color: var(--el-text-color-regular);
}
</style>