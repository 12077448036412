<template>
  <div class="trips-dashboard">
    <trip-card v-for="trip in tripsList"
       :trip="trip"
       :class="{ 'disabled': $store.state.edit.editMode, 'shadowed': trip.shadowed, 'focused': trip.focused }"
       @click="previewTrip(trip)"
       @deleteTrip="(trip_id) => {deleteTrip(trip_id)}"/>
  </div>
</template>

<script>
import TripCard from "@/components/TripCard";

export default {
  props: ["tripsList", "deleteTrip", "previewTrip"],
  components: {
    TripCard
  },
};
</script>

<style scoped>
.trips-dashboard {
  display: flex;
  flex-wrap: wrap;
}
.dashboard-menu-container {
  display: flex;
}
</style>