import http from "../http-common";

class PlacesDataService {
    create(data) {
        return http.post("/places", data);
    }

    update(data) {
        return http.put("/places", data);
    }

    // reverse geocoding
    find(lat, lon, types) {
        return http.get(`/places/find?lat=${lat}&lon=${lon}&types=${types.join(",")}`);
    }

    get_country(lat, lon) {
        return http.get(`/places/country?lat=${lat}&lon=${lon}`);
    }

    search(search, types) {
        return http.get(`/places/search?query=${search}&types=${types.join(",")}`);
    }

    details(place) {
        return http.post(`/places/details`, place);
    }
}

export default new PlacesDataService();