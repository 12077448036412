import {circlesLayer, countriesLayer, popUpOptionsLayer, routesLayer, viaPointsLayer} from "@/map/layers";
import {getPlaceIcon} from "@/map/placeTypes";

export function updateCountriesLayer(mapInstance, placesCollection, routesCollection) {
    const defaultCountriesList = ["in", "iso_3166_1"]
    // collect country codes to the list
    const placesCountries = new Set(placesCollection.filter(place => place.alpha_2 !== null).map((place) => place.alpha_2.toUpperCase()))
    const routesChildrenCountries = new Set(routesCollection.flatMap(route => route.children).filter(place => place.alpha_2 !== null).map(child => child.alpha_2.toUpperCase()));

    let tripCountries = new Set([...placesCountries, ...routesChildrenCountries])
    let countriesListIso = defaultCountriesList.concat(Array.from(tripCountries))
    mapInstance.setFilter(countriesLayer.id, countriesListIso);
}
export function updateLayer(mapInstance, layer, collection, updateLayer=true) {
    console.log(`----------> update map layer:`, layer)
    let featureCollection = {
        'type': 'FeatureCollection',
        'features': []
    }

    if (layer === circlesLayer.id) {
        collection.forEach(place => {
            // const exists = featureCollection.features.some(feature => feature.properties.source_id === place.source_id);
            // console.log(`---- adding to map layer: ${place.label} (${place.source_label}): ${place.source_id} = ${exists}`)
            // if (!exists) {
                featureCollection.features.push(placeToFeature(place))
            // }
        })
    } else if (layer === popUpOptionsLayer.id) {
        // filter countries from options suggestions layer
        collection.filter(place => place.type !=="country").forEach(place => featureCollection.features.push(
            placeToFeature(place)
        ))
    } else if (layer === viaPointsLayer.id) {
        collection.forEach(place => featureCollection.features.push(
            viapointToFeature(place)
        ))
    } else if (layer === routesLayer.id) {
        collection.forEach(route => featureCollection.features.push(
            routeToFeature(route)
        ))
    }

    if (updateLayer) {
        mapInstance.getSource(layer).setData(featureCollection)
    }
    return featureCollection.features
}

function placeToFeature(place) {
    return {
        "type": "Feature",
        "properties": {
            "trip_id": place.trip_id,
            "id": place.id,
            "source_id": place.source_id,
            "position": place.draggable_id,
            "draggable_id": place.draggable_id,
            "icon": `bt-${getPlaceIcon(place, false).replace("-icon", "")}-wh`
        },
        "geometry": {
            "type": "Point",
            "coordinates": [place.lon, place.lat]
        }
    }
}
function viapointToFeature(point) {
    return {
        "type": "Feature",
        "properties": {
            "trip_id": point.trip_id,
            "draggable_id": point.draggable_id,
            "route_origin_draggable_id": point.route_origin_draggable_id,
            "route_destination_draggable_id": point.route_destination_draggable_id,
            "route_type": point.route_type,
            "draggable_parent_id": point.draggable_parent_id,
            "icon": `bt-${getPlaceIcon(point, true).replace("-icon", "")}-wh`
        },
        "geometry": {
            "type": "Point",
            "coordinates": [point.lon, point.lat]
        }
    }
}

function routeToFeature(route) {
    return {
        'type': 'Feature',
        'properties': {
            "trip_id": route.trip_id,
            "draggable_id": route.draggable_id,
            "origin_draggable_id": route.origin_draggable_id,
            "destination_draggable_id": route.destination_draggable_id,
            "type": route.type,
            "updated": route.updated,
            "viaPoints": route.children,
            "dashed": route.dashed
        },
        'geometry': {
            'type': 'LineString',
            'coordinates': route.geometry
        }
    }
}