<template>
  <div class="date-picker-container">
    <el-date-picker
        :modelValue="datesInterval"
        @update:modelValue="setTripDates($event)"
        type="daterange"
        :range-separator="datesInterval && datesInterval.filter(item => item !== null).length > 0 ? '-' : ''"
        start-placeholder="select trip dates"
        end-placeholder=""
        prefix-icon=none
        value-format="YYYY-MM-DD"
        class="input-datepicker"
        format="MMM D, YYYY"
        :clear-icon="Close"
        :readonly="!appStore.isOwnTrip"
    />
  </div>
</template>

<script setup>
import {useAppStore} from "@/stores";
const appStore = useAppStore()
</script>

<script scoped>
import {Close} from "@element-plus/icons-vue";

export default {
  name: "RangeDatePicker",
  computed: {
    Close() {
      return Close
    }
  },
  props: ['datesInterval', 'setTripDates'],
  data() {
    return {
      shortcuts: [{
        text: 'Last week',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })(),
      }, {
        text: 'Last month',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        })(),
      }, {
        text: 'Last 3 months',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        })(),
      }]
    }
  }
};
</script>

<style>
.input-datepicker.el-input__wrapper,
.input-datepicker.el-date-editor.el-input__wrapper,
.input-datepicker.el-range-editor.is-active:hover,
.input-datepicker.el-date-editor.el-input__wrapper:hover
{
  box-shadow: none;
}

.date-picker-container, .el-date-editor.el-input__wrapper.el-range-editor {
  width: 250px;
  padding: 0;
}

.el-icon.el-input__icon.el-range__icon {
  width: 0px;
}

.input-datepicker.el-date-editor .el-range-input, .input-datepicker.el-date-editor .el-range-separator {
  text-align: left;
  justify-content: left;
}
</style>