<template>
  <div class="info-container">
    <span v-if="appStore.isOwnTrip" class="info-message">You have no trips yet</span>
    <span v-else class="info-message">{{ displayUserName }} has no trips yet</span>
    <el-button
        v-if="appStore.isOwnTrip"
        class="info-button"
        type="primary"
        round
        :icon="Plus"
        @click="$router.push('/trips/create')"
    >Add you first trip</el-button>
  </div>
</template>

<script setup>
import { Plus } from '@element-plus/icons-vue'
import {useAppStore} from "@/stores";
const appStore = useAppStore()
</script>


<script>

export default {
  props: ['user'],
  computed: {
    displayUserName() {
      if (this.user) {
        return this.user.name ? this.user.name.split(" ")[0] : ""
      }
    }
  }
};
</script>
<style>
.info-container {
  width: 100%;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.info-message {
  color: #999999;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 20px;
  display: inline-block;
}
</style>