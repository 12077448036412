class DatesRangeTransformer {
    formatDate(date_string, withYear=false) {
        const date = new Date(date_string + 'T00:00:00Z');
        return `${date.getUTCDate()} ${date.toLocaleDateString('default', {month: 'short'})}
         ${ withYear ? date.getFullYear() : ''}`
    }
    formatRange(start_date_string, end_date_string) {
        return `${this.formatDate(start_date_string)} - ${this.formatDate(end_date_string, true)}`
    }
}

export default new DatesRangeTransformer();