import http from "../http-common";

class TripsDataService {
    getAll(types=null, filters=null) {
        let query = ""
        query += types ? `types=${types.join(",")}` : ''

        if (filters) {
            query = this._transformFiltersQuery(query, filters)
        }
        return http.get(`/trips${query === "" ? "" : `?${query}`}`);
    }
    get(id) {
        return http.get(`/trips/${id}`);
    }

    create(tripData) {
        return http.post("/trips", tripData);
    }

    update(id, tripData) {
        return http.put(`/trips/${id}`, tripData);
    }

    delete(id) {
        return http.delete(`/trips/${id}`);
    }

    accept(id) {
        return http.post(`/trips/${id}/accept`);
    }
    reject(id) {
        return http.post(`/trips/${id}/reject`);
    }

    _transformFiltersQuery(query, filters) {
        Object.keys(filters).forEach(key => {
            if (filters[key].length > 0) {
                let queryValues = ""
                if (key === "country") {
                    queryValues = filters[key].map(item => item.alpha_2)
                }
                if (key === "tags") {
                    queryValues = filters[key]
                }
                if (key === "mates") {
                    queryValues = filters[key].map(item => item.id)
                }
                query += `&${key}=${queryValues.join(",")}`
            }
        })
        return query
    }
}

export default new TripsDataService();