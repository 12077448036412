<template>
  <el-select
      v-model="geocoderResult"
      filterable
      remote
      clearable
      reserve-keyword
      :automatic-dropdown=false
      placement="bottom-start"
      placeholder="search for a place to add"
      no-data-text="no places found"
      :remote-method="requestGeocoderPlaces"
      class="geocoder"
      @change="selectGeocoderOption"
  >
    <el-option
        v-for="item in geocoderOptions"
        :label="item.source_label"
        :value="item"
        class="geocoder-option"
    >
      <geocoder-item :item="item" :isSelected="false" />
    </el-option>
  </el-select>
</template>

<script>
import PlacesDataService from "@/services/PlacesDataService";
import GeocoderItem from "@/components/GeocoderItem.vue";

export default {
  props: ['map', 'addPlaceToList'],
  components: {
    GeocoderItem,
  },
  data() {
    return {
      geocoderResult: null,
      geocoderOptions: null
    };
  },
  methods: {
    requestGeocoderPlaces(query) {
      if (query && query !== "" && query.replaceAll(" ", "") !== "") {
        PlacesDataService.search(query, this.$route.name === 'Wishlist' ? ["place"] : ["place", "country"]).then((response) => {
          this.geocoderOptions = response.data
        })
      }
    },
    selectGeocoderOption() {
      const selectedOption = this.geocoderResult
      this.geocoderResult = null
      this.geocoderOptions = null

      if (selectedOption) {
        console.log(selectedOption)
        PlacesDataService.details(selectedOption).then((response) => {
          this.addPlaceToList(response.data)
        })
      }
    }
  }
};
</script>

<style>
.geocoder {
  width: 100px;
  height: 30px;
  background-color: white;
  border-radius: 5px;
  z-index: 3;
}

.geocoder-option.el-select-dropdown__item {
  height: 100%;
}
.geocoder .el-select__wrapper.is-focused, .geocoder .el-select__wrapper, .geocoder .el-select__wrapper.is-hovering:not(.is-focused) {
  box-shadow: none;
  padding-left: 0;
}

.el-select.geocoder .el-select__selection.is-near {
  margin: 0;
}

.geocoder.el-select {
  width: 280px;
}
</style>
