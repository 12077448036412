<template>
  <div class="header">
    <div class="header-items">
      <div class="header-logo">been-journal.co</div>

      <div class="header-controls">
        <router-link v-if="authStore.token" to="/"><compass-outline-icon class="header-icon" /></router-link>
        <router-link v-if="authStore.token" to="/wishlist"><bookmark-outline-icon class="header-icon" /></router-link>
        <router-link v-if="authStore.token" to="/friends"><account-group-outline-icon class="header-icon" /></router-link>
      </div>

      <div class="header-user-menu">
        <el-dropdown v-if="authStore.token">
          <span class="el-dropdown-link" trigger="click">
            <UserInfo :user="user" size="big"/>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="User" @click="router.push({ path: '/profile' });">User profile</el-dropdown-item>
              <el-dropdown-item :icon="Back" @click="logOut">Log out</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <div id="google-sign-in-button" :class="{ 'hidden': authStore.token }"></div>
      </div>
    </div>
    <div style="margin-left: auto;">
      <el-tooltip
          effect="light"
          content="Report feedback"
          placement="left"
      >
        <a href="https://forms.gle/szt6fKb9AVLsYHoE6" target="_blank">
          <message-alert-outline-icon class="header-icon"/>
        </a>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup>
  import UserInfo from "@/components/Core/User/UserInfo.vue";
  import { HomeFilled, Setting, Back, User } from '@element-plus/icons-vue'
  import { loadScript } from "vue-plugin-load-script";
  import UserDataService from "@/services/UserDataService";
  import {useAuthStore} from '@/stores';
  import { ref, onMounted, defineProps } from 'vue';
  import router from "@/router/routes";
  const authStore = useAuthStore()

  const props = defineProps({
    handleAuthLoading: {
      type: Function
    }}
  )

  authStore.$subscribe(()=> {
    if (!authStore.token) {
      renderGoogleButton()
    } else {
      getUserData()
    }
  }, { detached: true })

  // Define your reactive variables
  const user = ref(null);
  const client_id = ref(process.env.VUE_APP_GOOGLE_CLIENT_ID);

  // Define your methods
  const logOut = () => {
    authStore.logout();
  };

  const handleGoogleCallback = (response) => {
    props.handleAuthLoading(true)
    authStore.login(response.credential).then(() => {
      props.handleAuthLoading(false)
      router.push({ path: '/' });
    });
  };

  const getUserData = () => {
    UserDataService.getCurrentData().then((response) => {
      user.value = response.data;
    });
  };

  const renderGoogleButton = () => {
    console.log('HeaderMain.vue', 'render google button');
    google.accounts.id.initialize({
      client_id: client_id.value,
      callback: handleGoogleCallback,
    });
    google.accounts.id.renderButton(
        document.getElementById('google-sign-in-button'),
        { theme: 'outline', size: 'large' } // TODO: customize button
    );
    // google.accounts.id.prompt(); // also display the One Tap dialog
  };

  // Lifecycle hook equivalent to created
  onMounted(() => {
    authStore.refreshToken()
    // Load your script
    loadScript('https://accounts.google.com/gsi/client').then(() => {
      if (!authStore.token) {
        renderGoogleButton();
      } else {
        getUserData();
      }
    });
  });
</script>

<style>
.header {
  background-color: #ffffff;
  color: black;
  border-bottom: 1px solid;
  border-color: #cccccc;

  height: 60px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-items {
  height: 60px;
  width: 900px;
  top:0;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 0 0 auto;
}

.header-icon.material-design-icon {
  height: 24px;
  width: 24px;
  padding: 0 5px 0 5px;
}
.header-icon.material-design-icon .material-design-icon__svg {
  width: 24px;
  height: 24px;
  color: black;
}
.header-logo {
  font-size: 24px;
  font-weight: bold;
  margin-right: auto;
}
.header-controls {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.header-user-menu {
  margin-left: auto;
}

#google-sign-in-button.hidden {
  display: none;
}
#google-sign-in-button {
  display: inline-flex;
}
</style>
