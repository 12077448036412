<template>
  <div class="geocoder-option-container" :class="{ 'selected': isSelected, 'in_wishlist': isInWishlist}">
    <div class="geocoder-option-label">
      <span>{{ displayOptionLabel(item) }}</span>
      <bookmark-check-outline-icon v-if="item.in_wishlist" style="padding-left: 5px;"/>
    </div>
    <div class="geocoder-option-description" v-if="item.source_context && item.source_context.length > 0">{{ item.source_context }}</div>
    <div style="height: 14px; line-height: 14px;">
      <span class="geocoder-option-source" :class="item.source">{{ item.source }}</span>
      <span class="geocoder-option-visits" v-if="item.visits.length > 0">visited: {{ item.visits.length }}</span>
    </div>
  </div>
</template>

<script>
import BookmarkCheckOutlineIcon from "vue-material-design-icons/BookmarkCheckOutline.vue";

export default {
  components: {
    BookmarkCheckOutlineIcon
  },
  props: ['item', 'isSelected', 'isInWishlist'],

  data() {
    return {
    };
  },
  methods: {
    displayOptionLabel(placeOption) {
      if (placeOption.label) {
        return placeOption.label
      }
      if (placeOption.source_label) {
        return placeOption.source_label
      }
      return `${placeOption.lat.toFixed(5)}, ${placeOption.lon.toFixed(5)}`
    },
  }
};
</script>

<style scoped>
.geocoder-option-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 5px;
  color: #333333;
}
.geocoder-option-label {
  min-height: 18px;
  line-height: 18px;
  font-weight: bold;
}
.geocoder-option-description {
  line-height: 18px;
  min-height: 18px;
  font-size: 12px;
  font-weight: normal;
}
.geocoder-option-source {
  height: 14px;
  line-height: 14px;
  font-size: 10px;
  font-weight: normal;
  background-color: var(--el-color-primary);
  border-radius: 7px;
  color: white;
  display: inline-block;
  padding: 0 5px 0 5px;
}

.geocoder-option-source.coordinates {
  background-color: #cccccc;
}

.geocoder-option-source.user {
  background-color: #ffc16e;
}

.geocoder-option-source.country {
  background-color: #b98ffa;
}
.geocoder-option-source.google {
  background-color: #10454d;
}

.geocoder-option-visits {
  height: 14px;
  line-height: 14px;
  font-size: 10px;
  font-weight: normal;
  color: var(--el-text-color-secondary);
  display: inline-block;
  padding: 0 5px 0 5px;
}

.geocoder-option-container.selected {
  font-weight: 700;
  color: #2cafea;
}

.geocoder-option-container.in_wishlist {
  font-weight: 700;
  color: var(--el-text-color-secondary);
}
</style>
