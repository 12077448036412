<template>
  <div
      class="list-item"
  >
    <div style="width: 24px;">
      <list-item-menu
          :hovered="hovered"
          :isChild="isChild"
          :isSelected="isSelected"
          :item="item"
          :delete="itemRemovedEvent"
          :duplicate="placeDuplicateEvent"
          :draftTripsList="draftTripsList"
          :addToWishlist="addToWishlist"
          @item-row-select-event="handleItemRowSelected"
      />
    </div>
    <div class="list-item-container" :class="[{ 'hovered': hovered && isDragOver === null, 'selected': isSelected, 'drag-over': isDragOver === item.draggable_id }]">
      <div :class="[{'timeline-place': !isChild && $route.name !== 'Wishlist'}]">
        <div class="item-data-container">
          <div style="display: flex;" draggable="false">
            <slot name="item-type-select" ></slot>
            <slot name="item-label" :itemRenamedEvent="itemRenamedEvent"></slot>

            <div
                v-if="_isParentPlace && $route.name !== 'Wishlist'"
                class="item-date-container"
            >
              <item-date-picker
                  :date="item.date"
                  :tripDatesInterval="tripDatesInterval"
                  @item-date-updated-event="handleItemDateUpdated"
              />
            </div>

            <div style="text-align: right;">
              <camera-outline-icon class="table-row-icon" v-if="(item.images && item.images.length > 0) && $route.name === 'Wishlist'"/>
            </div>

            <div v-if="_isExpandable">
              <chevron-down-icon class="collapse-icon" @click="handleExpand" v-if="expandState.length === 0"/>
              <chevron-up-icon class="collapse-icon" @click="handleExpand" v-else/>
            </div>
          </div>
          <div
              style="padding-left: 55px;"
              v-if="_isExpandable"
          >
            <el-collapse v-model="expandState">
              <el-collapse-item name="expand">
                <list-item-media
                    :item="item"
                    @item-images-updated-event="handleImageUpdate"
                    @list-item-media-uploading-state-event="handleUploadingState"
                    v-if="(item.images && item.images.length > 0) || appStore.isOwnTrip"
                />
                <list-item-description
                    :item="item"
                    :itemDescriptionUpdateEvent="itemDescriptionUpdateEvent"
                    v-if="(item.description && item.description !== '') || appStore.isOwnTrip"
                />
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div class="item-children-container" :class="[{'route': item.draggable_type === 'route'}]" v-if="!isChild">
          <slot name="item-children-container"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useAppStore} from "@/stores";
const appStore = useAppStore()
</script>

<script>
import ListItemMenu from "@/components/List/ListItem/ListItemMenu.vue";
import ListItemMedia from "@/components/List/ListItemMedia.vue";
import ListItemDescription from "@/components/List/ListItemDescription.vue";
import ItemDatePicker from "@/components/Core/Form/ItemDatePicker.vue";
import {useAppStore} from "@/stores";

export default {
  components: {
    ListItemMenu,
    ListItemMedia,
    ListItemDescription,
    ItemDatePicker
  },
  props: {
    hovered: {type: Boolean},
    isChild: {type: Boolean},
    item: {type: Object},
    delete: {type: Function},
    duplicate: {type: Function},
    draftTripsList: {type: Array},
    isLastItem: {type: Boolean},
    isFirstItem: {type: Boolean},
    isDragOver: {type: Boolean},
    tripDatesInterval: {type: Array, required: false}
  },
  data() {
    return {
      isSelected: false,
      expandState: this.$route.name !== 'Wishlist' && (this.item.images.length > 0 || (this.item.description && this.item.description !== '')) ? ['expand'] : []
    }
  },
  methods: {
    handleItemDateUpdated(newDate) {
      this.$emit('item-date-updated-event', this.item.draggable_id, newDate);
    },
    handleItemRowSelected() {
      this.isSelected = !this.isSelected
    },
    handleExpand() {
      if (this.expandState.length === 0) {
        this.expandState = ['expand']
      } else {
        this.expandState.splice(0, this.expandState.length)
      }
    },
    handleUploadingState(isInProgress) {
      this.$emit('list-item-media-uploading-state-event', isInProgress);
    },
    handleImageUpdate(){
      this.$emit('item-images-updated-event', this.item.images, this.item.draggable_id);
    },
    itemRenamedEvent(newLabel) {
      this.$emit('item-renamed-event', newLabel, this.item.draggable_id);
    },
    itemDescriptionUpdateEvent(newDesc) {
      this.$emit('item-description-updated-event', newDesc, this.item.draggable_id);
    },
    itemRemovedEvent(item) {
      this.$emit('place-removed-event', item);
    },
    addToWishlist() {
      this.$emit('item-added-to-wishlist', [this.item]);
    },
    placeDuplicateEvent(item) {
      this.$emit('place-duplicate-event', item);
    },
  },
  computed: {
    _isExpandable() {
      return (this.item.images && this.item.images.length > 0) || (this.item.description && this.item.description !== '') || useAppStore().isOwnTrip
    },
    _dateContainerPadding() {
      return this._isExpandable ? '0' : '34px'
    },
    _isParentPlace() {
      return this.item.draggable_type === 'place' && !this.isChild
    },
    _hoveredColor() {
      if (this.isChild) {
        return "#dddddd"
      }
      return "#eeeeee"
    },
    _styleMarginTop() {
      return this.isFirstItem ? '19px' : '-2px' // TODO: fix after children container fixed
    },
    _styleHeight() {
      if (this.isLastItem && this.isFirstItem) {
        return '0'
      }
      return this.isLastItem ? '19px' : `calc(100%${this._styleMarginTop !== '0' ? ` - ${this._styleMarginTop}` : '' })`
    },
  },
};
</script>

<style>
.list-item {
  width: 100%;
  display: flex;
}

.dragClass .list-item {
  background-color: #b98ffa;
}
.list-item-container {
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  min-height: 28px;
  width: calc(100% - 20px);
  border-radius: 5px;
  display: inline-block;
  vertical-align: top;
}

/*.list-item:hover .list-item-container,*/
.list-item-container.hovered {
  background-color: v-bind(_hoveredColor);
}

.list-item-container.selected {
  background-color: #e3f9ff;
}

.list-item-container.drag-over {
  background-color: #eeeeee;
}

.list-item:hover .draggable-handle {
  display: inline-block;
}

.item-data-container {
  padding: 0;
  margin-left: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  width: 100%;
  position: relative;
}

.collapse-icon.material-design-icon .material-design-icon__svg {
  width: 20px;
  height: 20px;
  top: 1px;
  left: 7px;
  color: var(--el-text-color-secondary);
  display: none;
}
.collapse-icon:hover {
  cursor: pointer;
}

.collapse-icon {
  padding: 0 10px 0 10px;
  display: inline-block;

}

.item-date-container {
  width: 64px;
  text-align: right;
  padding-right: v-bind(_dateContainerPadding);
}

.item-data-container:hover .collapse-icon.material-design-icon .material-design-icon__svg,
.item-data-container:hover .item-date-picker-container.date-present .material-design-icon .material-design-icon__svg,
.item-data-container:hover .item-date-picker-container:not(.shared) .material-design-icon .material-design-icon__svg
{
  display: block;
}

.timeline-place::before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 4px;
  background-color: var(--el-color-primary);
  margin-left: 40px;
  margin-top: v-bind(_styleMarginTop);
  height: v-bind(_styleHeight);
  z-index: 2;
}
.timeline-place {
  position: relative;
}

.item-children-container {
  min-height: 1px;
  margin-left: 65px;
  bottom: -1px;
  border-radius: 6px;
  width: calc(100% - 65px - 2px);
}

.item-children-container.route {
  margin-left: 1.5px;
}

.list-item-container.drag-over .item-children-container {
  min-height: 28px;
  border: 2px dashed var(--el-color-primary);
}

.list-item-container .el-collapse {
  --el-collapse-header-height: 24px;
  --el-collapse-border-color: None;
  --el-collapse-header-bg-color: None;
  --el-collapse-header-text-color: var(--el-text-color-primary);
  --el-collapse-header-font-size: 13px;
  --el-collapse-content-bg-color: None;
  --el-collapse-content-font-size: 13px;
  border: None;
  width: 100%;
}

.list-item-container  .el-collapse-item__header {
  display: none;
}

.list-item-container .el-collapse-item__content {
  padding: 0;
}

</style>
