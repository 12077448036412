<template>
  <el-table
      ref="usersTable"
      empty-text=" "
      :data="users"
      stripe
      highlight-current-row
      size="small"
      height="560"
      @row-click="clickRow"
  >
    <el-table-column prop="avatar" label="" width="70">
      <template #default="scope">
        <user-avatar :user="scope.row" size="big" />
      </template>
    </el-table-column>
    <el-table-column prop="name" label="Name"/>
    <el-table-column prop="trips_number" label="Trips" />
    <el-table-column prop="countries_number" label="Countries"/>
    <el-table-column fixed="right" label="" width="65">
      <template #default="scope">
        <arrow-expand-icon @click.prevent="$router.push(`/user/${scope.row.id}`)" class="table-row-icon" />
      </template>
    </el-table-column>
  </el-table>
</template>


<script>
import UserDataService from "@/services/UserDataService";
import {useAppStore} from "@/stores";
import UserAvatar from "@/components/Core/User/UserAvatar.vue";

export default {
  name: "UsersList",
  props: ['getUserTrips'],
  components: {
    UserAvatar
  },
  data() {
    return {
      users: [],
      focusedUser: null
    }
  },
  methods: {
    requestUsersList() {
      UserDataService.getUsers().then((response) => {
        this.users = response.data;
      });
    },
    clickRow(user, column) {
      // clear current row if deselected the trip
      if (column.label !== "") {
        if (this.focusedUser === user.id) {
          this.getUserTrips(-1)
          this.focusedUser = null
          this.$refs.usersTable.setCurrentRow()
        } else {
          this.getUserTrips(user.id)
          this.focusedUser = user.id
          this.$refs.usersTable.setCurrentRow(user)
        }
      }
    },
  },
  mounted() {
    this.requestUsersList()
  },
}
</script>