<template>
  <list-item-row :item="place" :hovered="hovered" :isChild="isChild" :draftTripsList="draftTripsList" :isLastItem="isLastItem" :isFirstItem="isFirstItem" :tripDatesInterval="tripDatesInterval">
    <template v-slot:item-label="{ itemRenamedEvent }">
      <div class="place-item-title-container">
        <div v-if="!isChild" :class="[generateFlagClassName(place.alpha_2), 'flag-icon']"></div>
        <bookmark-check-outline-icon v-if="place.in_wishlist && $route.name !== 'Wishlist'" class="wishlist-row-icon"/>
        <list-item-title :item="place" :itemRenamedEvent="itemRenamedEvent"/>
        <div
            v-if="$route.name === 'Wishlist' && place.source_trip_title && place.source_trip_title.length > 0"
            class="input-place-source"
        >
          <div style="display: inline-block; white-space: nowrap;">
            <el-link type="info" @click="$router.push(`/trips/${place.source_trip_id}`)">from: @{{place.source_trip_user_name }} ({{ place.source_trip_title }})</el-link>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:item-type-select>
      <list-item-type-select :item="place" :isChild="isChild" :itemTypeChangedEvent="placeTypeChangedEvent"/>
    </template>

    <template v-slot:item-children-container>
      <slot name="children"></slot>
    </template>
  </list-item-row>
</template>


<script setup>
import {useAppStore} from "@/stores";
const appStore = useAppStore()
</script>

<script>
import {useAppStore} from "@/stores";
import ListItemRow from "@/components/List/ListItemRow.vue";
import ListItemTitle from "@/components/List/ListItemTitle.vue";
import ListItemTypeSelect from "@/components/List/ListItemTypeSelect.vue";

export default {
  components: {
    ListItemRow,
    ListItemTitle,
    ListItemTypeSelect
  },
  props: {
    place: {type: Object},
    hovered: {type: Boolean},
    isChild: {type: Boolean},
    isLastItem: {type: Boolean},
    isFirstItem: {type: Boolean},
    draftTripsList: {type: Array},
    tripDatesInterval: {type: Array, required: false}
  },
  data() {
    return {
      placeLabel: this.place.label && this.place.label !== "" ? this.place.label : this.place.source_label,
    }
  },
  methods: {
    placeTypeChangedEvent(place) {
      this.$emit('place-type-updated-event', place, this.isChild);
    },
    generateFlagClassName(alpha_2_code) {
      return "flag-icon-" + alpha_2_code
    },
  },
  watch: {
    'place.source_label': {
      handler() {
        this.placeLabel = this.place.label && this.place.label !== "" ? this.place.label : this.place.source_label
      }
    },
    'place.label': {
      handler() {
        this.placeLabel = this.place.label && this.place.label !== "" ? this.place.label : this.place.source_label
      }
    }
  }
};
</script>

<style>
.place-item-title-container {
  display: flex;
  width: 100%;
}

.place-item-title-container .flag-icon {
  flex: 0 0 auto;
  border-radius: 2px;
  color: white;
  white-space: nowrap;
  width: 20px;
  height: 14px;
  margin: 7px 10px 0px 10px;
}

.input-place-source .el-link {
  font-size: 10px;
}

.input-place-source {
  display: flex;
  justify-content: flex-end;
  padding-left: 10px;
}

.wishlist-row-icon {
  padding: 0;
  top: 1px;
}
.wishlist-row-icon.material-design-icon .material-design-icon__svg {
  width: 18px;
  height: 18px;
  color: var(--el-input-text-color)
}
</style>
