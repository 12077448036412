<template>
  <div style="display: flex; flex-direction: row;">
    <user-avatar v-if="matesList" v-for="(mate, index) in matesList.slice(0, avatarsNumberToDisplay)" :user="mate" size="small" border="true" :style="{ 'z-index': 5-index, 'margin-left': '-3px' }"/>
    <div v-if="matesList && matesList.length > maxAvatarsNumber" style="margin-left: 3px; line-height: 20px;"> +{{ matesList.length - avatarsNumberToDisplay }}</div>
  </div>
</template>

<script>
import UserAvatar from "@/components/Core/User/UserAvatar.vue";

export default {
  name: "Sample",
  components: {
    UserAvatar
  },
  props: ['matesList'],
  data() {
    return {
      maxAvatarsNumber: 4
    }
  },
  computed: {
    avatarsNumberToDisplay() {
      return this.matesList.length > this.maxAvatarsNumber ? this.maxAvatarsNumber - 1 : this.maxAvatarsNumber
    }
  }
};
</script>

<style scope>
</style>
