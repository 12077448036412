import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'flag-icon-css/css/flag-icon.css'
import 'vue-material-design-icons/styles.css';
import router from './router/routes';
import { createPinia } from 'pinia';
import { registerGlobalIcons } from './global-icons'
import { ElMessage } from 'element-plus';
//Vue.config.productionTip = false

const app = createApp(App)
    .use(createPinia())
    .use(router)
    .use(ElementPlus)

registerGlobalIcons(app)

app.config.errorHandler = (err, vm, info) => {
    console.error('Vue error handler:', err);
    console.error('Component that triggered the error:', vm);
    console.error('Additional info:', info);

    // Display the error message using ElMessage
    ElMessage.error('An unexpected error occurred. Please try again later.');
};

// Optional: Handle errors outside of Vue (e.g., in async functions)
window.addEventListener('error', (event) => {
    console.error('Global error handler:', event.message);
    // ElMessage.error('An unexpected error occurred. Please try again later.');
});

window.addEventListener('unhandledrejection', (event) => {
    console.error('Unhandled promise rejection:', event.reason);
    // ElMessage.error('An unexpected error occurred. Please try again later.');
});

app.mount('#app');
export default app;