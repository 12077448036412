import http from "../http-common";

class MediaService {
    uploadUrl = `${process.env.VUE_APP_BACKEND_URL}/media/image`
    uploadAvatarUrl = `${process.env.VUE_APP_BACKEND_URL}/media/avatar`
    deleteImages(imagesList) {
        return http.post("/media/delete", imagesList);
    }

    clean() {
        return http.get("/media/clean");
    }
}

export default new MediaService();