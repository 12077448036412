import http from "../http-common";

class RoutesService {
    getDrivingRoute(access_token, coordinates, routeType) {
        // routeType: [driving, walking]
        const formattedCoordinates = coordinates.map(pair => pair.join('%2C')); // ,
        const query = formattedCoordinates.join('%3B'); // ;
        // https://docs.mapbox.com/api/navigation/directions/
        return http.get(`https://api.mapbox.com/directions/v5/mapbox/${routeType}/${query}?alternatives=false&geometries=geojson&overview=simplified&steps=false&access_token=${access_token}`);
    }

    getTrainRoute(points) {
        return http.post("/directions", points);
    }
}

export default new RoutesService();